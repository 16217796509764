import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import InfoMessage from '../infomessage/infomessage'
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
    snackbar: {
        margin: 5,
        backgroundColor: '#F3F3F3',
        color: 'black'
    },
    newComment: {
        marginTop: 10,
    },
    input: {
        display: 'none',
    },
});

class SubscriptionModule extends Component {
    state = {
        unAuthMessage: false,
        uploadedMessage: false,
        dateFormat: 'dd-mm-yyyy'
    };

    closeUnAuthMessage = () => {
        this.setState({ unAuthMessage: false });
    }

    openUnAuthMessage = () => {
        this.setState({ unAuthMessage: true });
    }

    closeUploadedMessage = () => {
        this.setState({ uploadedMessage: false });
    }

    openUploadedMessage = () => {
        this.setState({
            uploadedMessage: true
        });
        this.props.close();
    }

    dateForamtting = (date) => {
        if (date === '')
            return '';

        if (this.state.dateFormat === 'dd-mm-yyyy') {
            let splittedDate = date.split(/[-/.]/);
            date = splittedDate[1] + '-' + splittedDate[0] + '-' + splittedDate[2];
        }
        else if (this.state.dateFormat === 'yyyy-mm-dd') {
            let splittedDate = date.split(/[-/.]/);
            date = splittedDate[1] + '-' + splittedDate[2] + '-' + splittedDate[0];
        }

        let newDate = new Date(date);
        if (isNaN(newDate.getTime()))
            return '';

        let dd = newDate.getDate();
        let mm = newDate.getMonth() + 1;
        let yyyy = newDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        let result = yyyy + '-' + mm + '-' + dd;
        return result;
    }

    importFile = (file) => {
        //let file = this.state.file;
        if (file === null)
            return;
        let fileReader = new FileReader();
        fileReader.readAsText(file[0]);

        fileReader.onload = (event) => {
            let csvData = event.target.result;
            let lines = csvData.split("\n");
            const firstLine = lines.shift().split(/[,;]/);
            let data1 = [];
            for (let key in lines) {
                let line = lines[key].split(/[,;]/);
                let item = {};

                for (let key1 in line) {
                    if (firstLine[key1] === "")
                        continue;
                    let newKey = firstLine[key1].trim();
                    let newvalue = line[key1];

                    if (newKey === 'Start Date' || newKey === 'End Date' || newKey === 'Invoice Date' || newKey === 'Paid Date') {
                        newvalue = this.dateForamtting(newvalue);
                    }

                    if ((/true/i).test(newvalue))
                        newvalue = true;
                    else if ((/false/i).test(newvalue))
                        newvalue = false;
                    item[newKey] = newvalue;
                }
                data1.push(item);
            }

            const data = { 'data': data1 };
            let url = '';
            if (this.props.invoice)
                url = `${process.env.REACT_APP_BACKEND_URL}/subscriptioninvoice`;
            else
                url = `${process.env.REACT_APP_BACKEND_URL}/subscriptionpaid`;

            const headers = { Authorization: this.props.loginstate.token };
            axios.post(url,
                data,
                { headers }
            ).then(response => {
                if (+response.status === 200) {
                    this.openUploadedMessage();
                }
            })
                .catch(error => {
                    if (error.response && error.response.status === 401)
                        this.openUnAuthMessage();
                });

        }
    }

    onchange = (event) => {
        this.importFile(event.target.files);
    }

    handleDateFormat = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.uploadedMessage && <InfoMessage message='Subscription Uploaded.' open={this.state.uploadedMessage} handleClose={this.closeUploadedMessage} />}
                {this.state.unAuthMessage && <InfoMessage message='Not Authorised!' open={this.state.unAuthMessage} handleClose={this.closeUnAuthMessage} />}
                    <Dialog
                        open={this.props.open}
                        onClose={this.props.close}
                    >
                        <DialogTitle id="form-dialog-title">{'Import Subscribtion'}</DialogTitle>

                        <DialogContent>
                            <div>
                                <DialogContentText>
                                    Select the date format in the CSV file to be loaded.
                    </DialogContentText>
                                <div style={{ paddingTop: 10 }}>
                                    <form autoComplete="off" >
                                        <FormControl variant="outlined" style={{ minWidth: 150, height: 35 }}>
                                            <InputLabel
                                                htmlFor="outlined-age-simple"
                                            >
                                                Date Format
                     </InputLabel>
                                            <Select
                                                value={this.state.dateFormat}
                                                onChange={this.handleDateFormat}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={90}
                                                        name="dateFormat"
                                                        id="dateFormat"
                                                    />
                                                }
                                                style={{ paddingTop: 5, minWidth: 150, maxHeight: 35 }}
                                            >
                                                <MenuItem value={'dd-mm-yyyy'} >dd-mm-yyyy</MenuItem>
                                                <MenuItem value={'mm-dd-yyyy'} >mm-dd-yyyy</MenuItem>
                                                <MenuItem value={'yyyy-mm-dd'} >yyyy-mm-dd</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </form>
                                </div>

                                
                            </div>



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.close} color="primary">
                                Cancel
            </Button>
                        <input
                            accept=".csv"
                            className={classes.input}
                            id="name"
                            type="file"
                            id="contained-button-file"
                            onChange={this.onchange}
                        />
                        <label htmlFor="contained-button-file">
                        <Button component="span" color="primary">
                            Upload CSV
        </Button>
                            </label>
                        </DialogActions>
                    </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SubscriptionModule);