import React from 'react';
import { Modal, Button } from 'react-bootstrap'

const ConfirmationPopup = (props)=> {
    return (
        <div className="static-modal" draggable={false}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{props.heading}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        {props.message}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className='text-center'>
                        <Button bsStyle="primary" onClick={props.confirmAction}>Ok</Button>
                        <Button bsStyle="primary" onClick={props.cancelAction}>Cancel</Button>
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
        );
}

export default ConfirmationPopup;