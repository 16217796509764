import React, { Component } from 'react';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseSharp';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CustomerIcon from '@material-ui/icons/Person';
import VesselIcon from '@material-ui/icons/DirectionsBoat';
import SoftwareIcon from '@material-ui/icons/Memory';
import HardwareIcon from '@material-ui/icons/Computer';
import AddIcon from '@material-ui/icons/Add';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import './list_with_search.css'
import InfoMessage from '../infomessage/infomessage'

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    menuSelect: {
        padding: 0,
        width: 130,
        height:45
    },
    addControl: {
        width: 30,
    },
    SearchListBox: {
        paddingTop:5
    },
    toolbarroot: {
        paddingRight: theme.spacing.unit,
    },
    spacer: {
        flex: '1 1 100%',
    },
    title: {
        flex: '0 0 auto',
    },
    searchList: {
        //maxHeight: window.innerHeight -330,
        overflowY: 'auto',
    },
    searchListItem: {
        maxHeight: 80,
        minHeight:80
    },
    searchListItemText: {
        minHeight:70,
        maxHeight: 70,
        overflow: 'hidden'
    },
    iconButton: {
        padding: 10,
    },
});

class SearchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchedValue: null,
            softwareShown: true,
            hardwareShown: true,
            vesselShown: true,
            customerShown: true,
            sortBy: 'Smart',
            addNewDropDown: null,
            listBySearch: false,
            unAuthMessage: false
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let SearchedList = this.state.SearchedList;
        this.setState({ SearchedList });
    }

    closeUnAuthMessage = () => {
        this.setState({ unAuthMessage: false });
    }

    openUnAuthMessage = () => {
        this.setState({ unAuthMessage: true });
    }

    handleOpenNewDropDown = event => {
        this.setState({ addNewDropDown: event.currentTarget });
    };

    handleCloseNewDropDown = () => {
        this.setState({ addNewDropDown: null });
    };

    addNewSoftware = () => {
        this.handleCloseNewDropDown();
        this.props.addNewSoftware();
    }

    addNewHardware = () => {
        this.handleCloseNewDropDown();
        this.props.addNewHardware();
    }

    addNewVessel = () => {
        this.handleCloseNewDropDown();
        this.props.addNewVessel();
    }

    addNewCustomer = () => {
        this.handleCloseNewDropDown();
        this.props.addNewCustomer();
    }

    handleChangeListOption = name => event => {
        const value = event.target.checked;
        this.setState({ [name]: value });

        if (this.state.listBySearch) {
            this.listSearch(event, name, value);
            this.props.fetchItineraryList(null, name, value);
        }
        else {
            this.props.fetchItineraryList(null, name, value);
        }
    };


    handleSortBy = event => {
        this.setState({ [event.target.name]: event.target.value });
        if (this.state.listBySearch) {
            this.listSearch(event);
        }
        else {
            this.props.fetchItineraryList(event.target.value);
        }
    };

    rmaListToString = (rmas) => {
        let result = '';
        for (let it in rmas) {
            result += (rmas[it].serialNumber) + ' ';
        }
        return result;
    }

    handleCancelSearch = () => {
        this.setState(state => ({
            searchedValue: null,
            listBySearch: false
        }));
        this.props.onSelection(null);
        this.props.fetchItineraryList();
    };

    updateSearchedValue = (event) => {
        this.setState({
            searchedValue: event.target.value
        });

    }

    handleEnterOnSearch = (event) => {
        if (event.key === 'Enter')
            this.listSearch(event);
    }

    evaluateSearch = (list1, symbol, list2) => {
        if (!list1 || list1.length === 0 || !list2 || list2.length === 0)
            return list1;

        if (symbol === '+') {
            list1 = list1.filter(
                (item) => {
                    let flag = false;
                    for (let it in list2) {
                        if (item.type === list2[it].type && item.item_id === list2[it].item_id) {
                            flag = true;
                            break;
                        }
                    }
                    return flag;
                }
            );
            return (list1);
        }
        else if (symbol === '-') {
            list1 = list1.filter(
                (item) => {
                    let flag = true;
                    for (let it in list2) {
                        if (item.type === list2[it].type && item.item_id === list2[it].item_id) {
                            flag = false;
                            break;
                        }
                    }
                    return flag;
                }
            );
            return (list1);
        }
        else if (symbol === ';') {
            list1 = list1.filter(
                (item) => {
                    let flag = true;
                    for (let it in list2) {
                        if (item.type === list2[it].type && item.item_id === list2[it].item_id)
                            flag = false;
                    }
                    return flag;
                }
            );
            list1 = list1.concat(list2);
            return (list1);
        }
    }


    dateForamtting = (date) => {
        if (date === '')
            return '';

        let newDate = new Date(date);
        if (isNaN(newDate.getTime()))
            return '';

        let dd = newDate.getDate();
        let mm = newDate.getMonth() + 1;
        let yyyy = newDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        let result = yyyy + '-' + mm + '-' + dd;
        return result;
    }


    getListMaxDate(list) {
        let maxDate = '0';
        if (!list)
            return maxDate;

        for (let it in list) {
            if (list[it].updateDate && list[it].updateDate > maxDate)
                maxDate = list[it].updateDate;
        }
        return maxDate;
    }

    getNewList = (searchedString, viaClickEvent, filterName, FilterValue, listLength) => {
        const headers = { Authorization: this.props.loginstate.token };
        let sortBy = this.state.sortBy;
        let lastItem;
        let lastItemId = 0;
        let cachedListCount = 0;
        let maxDate = 0;
        if (this.state.SearchedList) {
            let tempList = this.state.SearchedList.slice();
            if (!viaClickEvent) {
                maxDate = this.getListMaxDate(tempList)
                lastItem = tempList.pop();
                lastItemId = lastItem && lastItem.item_id;
                //while (lastItem && lastItem.type == 'Vessel')
                //    lastItem = tempList.pop();
                cachedListCount = this.state.SearchedList.length;
            }
        }

        let maxItem = 20;
        if (listLength) {
            maxItem = listLength;
        }

        let softwareShown = (filterName && filterName === 'softwareShown') ? FilterValue : this.state.softwareShown;
        let hardwareShown = (filterName && filterName === 'hardwareShown') ? FilterValue : this.state.hardwareShown;
        let vesselShown = (filterName && filterName === 'vesselShown') ? FilterValue : this.state.vesselShown;
        let customerShown = (filterName && filterName === 'customerShown') ? FilterValue : this.state.customerShown;

        const url = `${process.env.REACT_APP_BACKEND_URL}/itineraryList` + '?find=' + searchedString + '&maxlistlength=' + maxItem + '&sort=' + sortBy + '&lastid=' + lastItemId + '&lastitemtype=' + (lastItem ? lastItem.type : '') + '&cachedlistcount=' + cachedListCount + '&cachedmaxdate=' + maxDate + '&software=' + softwareShown + '&hardware=' + hardwareShown + '&vessel=' + vesselShown + '&customer=' + customerShown;
        axios.get(url,
            { headers }
        ).then((response) => {
            let newList = response.data;

            let firstItem = newList.shift();
            newList.unshift(firstItem);
            if (viaClickEvent) {
                this.props.onSelection(firstItem);
            }

            this.setState((prevState) => {
                let SearchedList = prevState.SearchedList;
                if (viaClickEvent) {
                    if (!SearchedList)
                        SearchedList = [];
                    SearchedList = newList;
                }
                else {
                    if (SearchedList) {
                        if (!SearchedList || SearchedList.length === +0)
                            SearchedList = newList;
                        else {
                            let list1 = newList;
                            let list2 = SearchedList;
                            list1 = list1.filter(
                                (item) => {
                                    let flag = true;
                                    for (let it in list2) {
                                        if (item.type === list2[it].type && item.item_id === list2[it].item_id)
                                            flag = false;
                                    }
                                    return flag;
                                }
                            );
                            list2 = list2.concat(list1);
                            SearchedList = list2;
                        }
                    }
                    else {
                        SearchedList = [];
                        SearchedList = newList;
                    }
                }

                return {
                    SearchedList,
                    listBySearch: true
                }
            });
        })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401)
                        this.openUnAuthMessage();
                    else if (error.response.status === 409) {
                        let tempList = this.state.SearchedList.slice();
                        let listLength = tempList.length;
                        this.getNewList(this.state.searchedValue, true, null, null, listLength);
                    }
                }
            });
    }

    listSearch = (event, filterName, FilterValue) => {
        if (event) {
            this.setState({ listBySearch: true,SearchedList: [] }); 
            this.props.onSelection(null);
        }
        let searchedString = this.state.searchedValue;
        let viaClickEvent = event ? true : false;
        this.getNewList(searchedString, viaClickEvent, filterName, FilterValue);
    }

    getStringToCompare = (item) => {
        if (!item || !item.type)
            return '';

        if (item.type === 'Software')
            return item.licenseId;
        else if (item.type === 'Hardware')
            return item.hardwareSerialNumber;
        else if (item.type === 'Vessel')
            return item.vesselName;
        else if (item.type === 'Customer')
            return item.name;
        return '';
    }


    handleListItemClick = (event, item) => {
        event.preventDefault();
        if ((this.props.selectedItem && this.props.selectedItem.type === item.type && +this.props.selectedItem.item_id === +item.item_id)) {
            this.props.onSelection(null);
            return;
        }
        this.props.onSelection(item);
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedItem !== nextProps.selectedItem) {
            this.setState({
                selectedItem: nextProps.selectedItem,
            });
        }
    }

    scrollList = (event) => {
        if ((event.target.scrollHeight - event.target.scrollTop) === event.target.clientHeight) {
            if (this.state.listBySearch) {
                this.listSearch();
            }
            else
                this.props.lazyLoadingList(this.state.sortBy);
        }
    }

    render() {
        const { classes } = this.props;
        let listItems = this.props.list;

        if (this.state.listBySearch) {
            listItems = this.state.SearchedList;
        }

        return (
            <div className={classes.SearchListBox}>
                {this.state.unAuthMessage && <InfoMessage message='Not Authorised!' open={this.state.unAuthMessage} handleClose={this.closeUnAuthMessage} />}
                <Toolbar>
                    <FormControl >
                        <InputLabel htmlFor="input-with-icon-adornment">Search</InputLabel>
                        <Input
                            id="input-with-icon-adornment"
                            value={this.state.searchedValue ? this.state.searchedValue : ""}
                            onChange={this.updateSearchedValue}
                            onKeyPress={this.handleEnterOnSearch}
                            endAdornment={
                                <InputAdornment position="end">
                                    {this.state.searchedValue &&
                                        <IconButton
                                            aria-label="Cancel Search"
                                            onClick={this.handleCancelSearch}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    }

                                    {!this.props.selectedItem &&
                                        <IconButton className={classes.iconButton} aria-label="Search" onClick={this.listSearch}>
                                            <SearchIcon />
                                        </IconButton>
                                    }
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {!this.props.selectedItem &&
                        <div>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel
                                    htmlFor="outlined-age-simple"
                                >
                                    Sort By
                     </InputLabel>
                                <Select
                                    value={this.state.sortBy}
                                    onChange={this.handleSortBy}
                                    input={
                                        <OutlinedInput
                                            labelWidth={55}
                                            name="sortBy"
                                            id="sortBy"
                                        />
                                    }
                                    className={classes.menuSelect}
                                >
                                    <MenuItem value={'Smart'} >Smart</MenuItem>
                                    <MenuItem value={'DescDate'} >Newest First</MenuItem>
                                    <MenuItem value={'AscDate'} >Oldest First</MenuItem>
                                </Select>
                            </FormControl>

                            <Tooltip title="Add New" aria-label="Add New" className={classes.addControl}>
                                <IconButton
                                    aria-owns={this.state.addNewDropDown ? 'simple-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleOpenNewDropDown}
                                >
                                    <AddIcon color='action' />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.addNewDropDown}
                                open={Boolean(this.state.addNewDropDown)}
                                onClose={this.handleCloseNewDropDown}
                            >
                                <MenuItem onClick={this.addNewSoftware}>Software</MenuItem>
                                <MenuItem onClick={this.addNewHardware}>Hardware</MenuItem>
                                <MenuItem onClick={this.addNewVessel}>Vessel</MenuItem>
                                <MenuItem onClick={this.addNewCustomer}>Customer</MenuItem>
                            </Menu>
                        </div>
                    }
                </Toolbar>

                {this.props.selectedItem &&
                    <Toolbar>
                        <div>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel
                                    htmlFor="outlined-age-simple"
                                >
                                    Sort By
                     </InputLabel>
                                <Select
                                    value={this.state.sortBy}
                                    onChange={this.handleSortBy}
                                    input={
                                        <OutlinedInput
                                            labelWidth={55}
                                            name="sortBy"
                                            id="sortBy"
                                        />
                                    }
                                    className={classes.menuSelect}
                                >
                                    <MenuItem value={'Smart'} >Smart</MenuItem>
                                    <MenuItem value={'DescDate'} >Newest First</MenuItem>
                                    <MenuItem value={'AscDate'} >Oldest First</MenuItem>
                                </Select>
                            </FormControl>

                            <Tooltip title="Add New" aria-label="Add New" className={classes.addControl}>
                                <IconButton
                                    aria-owns={this.state.addNewDropDown ? 'simple-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleOpenNewDropDown}
                                >
                                    <AddIcon color='action' />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.addNewDropDown}
                                open={Boolean(this.state.addNewDropDown)}
                                onClose={this.handleCloseNewDropDown}
                            >
                                <MenuItem onClick={this.addNewSoftware}>Software</MenuItem>
                                <MenuItem onClick={this.addNewHardware}>Hardware</MenuItem>
                                <MenuItem onClick={this.addNewVessel}>Vessel</MenuItem>
                                <MenuItem onClick={this.addNewCustomer}>Customer</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                }
                <Hidden xsDown>
                <FormGroup row style={this.props.selectedItem ? { padding: '0em 1em' } : { padding: '0em 1em', width: '25%' }}>
                    <FormControlLabel
                        label="Software"
                        control={
                            <Switch
                                checked={this.state.softwareShown}
                                onChange={this.handleChangeListOption('softwareShown')}
                                value="softwareShown"
                                color='primary'
                                style={{ color: '#0071BC' }}
                            />
                        }
                        style={{ width: '50%' }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.hardwareShown}
                                onChange={this.handleChangeListOption('hardwareShown')}
                                value="hardwareShown"
                                color='primary'
                                style={{ color: '#0071BC' }}
                            />
                        }
                        label="Hardware"
                        style={{ width: '25%' }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.vesselShown}
                                onChange={this.handleChangeListOption('vesselShown')}
                                value="vesselShown"
                                color='primary'
                                style={{ color: '#0071BC' }}
                            />
                        }
                        label="Vessel"
                        style={{ width: '50%' }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.customerShown}
                                onChange={this.handleChangeListOption('customerShown')}
                                value="customerShown"
                                color='primary'
                                style={{ color: '#0071BC' }}
                            />
                        }
                        label="Customer"
                        style={{ width: '25%' }}
                    />
                    </FormGroup>
                </Hidden>

                {listItems &&
                    <div className={classes.searchList} onScroll={this.scrollList} style={this.props.selectedItem ? { height: window.innerHeight - 330 } : { height: window.innerHeight - 230 }}>
                        <List component="nav">
                            {this.state.listBySearch && this.state.SearchedList && this.state.SearchedList.length === 0 && 'No results found'}
                            {listItems && listItems.map(item => (item &&
                                <ListItem
                                    className={classes.searchListItem}
                                    button key={item.type + "_" + item.item_id}
                                    selected={this.props.selectedItem && this.props.selectedItem.type === item.type && +this.props.selectedItem.item_id === +item.item_id}
                                    onClick={event => this.handleListItemClick(event, item)}>
                                    {(item.type === 'Software' &&
                                        <ListItemIcon>
                                            <SoftwareIcon />
                                        </ListItemIcon>)
                                    }
                                    {(item.type === 'Hardware' &&
                                        <ListItemIcon>
                                            <HardwareIcon />
                                        </ListItemIcon>)
                                    }
                                    {(item.type === 'Vessel' &&
                                        <ListItemIcon>
                                            <VesselIcon />
                                        </ListItemIcon>)
                                    }
                                    {(item.type === 'Customer' &&
                                        <ListItemIcon>
                                            <CustomerIcon />
                                        </ListItemIcon>)
                                    }
                                    {
                                        (item.type === 'Software' &&
                                            this.props.selectedItem &&
                                            <ListItemText inset primary={(item.licenseId ? item.licenseId : 'No license number ') + ' ' + (item.description ? item.description : '')} className={classes.searchListItemText}
                                                secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                    (((item.tecdisType ? ('Type ' + item.tecdisType + ', ') : '') +
                                                        (item.licenseId ? ('License Id ' + item.licenseId + ', ') : '') +
                                                        (item.description ? ('Notes ' + item.description + '.') : '') +
                                                        (item.version ? ('Version ' + item.version + ', ') : '') +
                                                        (item.orderNo ? ('Order number ' + item.orderNo + ', ') : '') +
                                                        (item.orderType ? ('Order type ' + item.orderType + ', ') : '') +
                                                        (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                        (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                        (item.purchaseDate ? ('Delivery Date ' + this.dateForamtting(item.purchaseDate) + ', ') : '') +
                                                        (item.installDate ? ('Installation Date ' + this.dateForamtting(item.installDate) + ', ') : '')).substring(0, 120) +
                                                        ((((item.licenseId ? ('License Id ' + item.licenseId + ', ') : '') +
                                                            (item.tecdisType ? ('Type ' + item.tecdisType + ', ') : '') +
                                                            (item.description ? ('Notes ' + item.description + '.') : '') +
                                                            (item.orderNo ? ('Order number ' + item.orderNo + ', ') : '') +
                                                            (item.purchaseDate ? ('Delivery Date ' + this.dateForamtting(item.purchaseDate) + ', ') : '') +
                                                            (item.installDate ? ('Installation Date ' + this.dateForamtting(item.installDate) + ', ') : '') +
                                                            (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                            (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                            (item.version ? ('Version ' + item.version + ', ') : '') +
                                                            (item.orderType ? ('Order type ' + item.orderType + ', ') : '')).substring(0, 120)).length >= 119 ? '...' : ''))}
                                                </span>}
                                            />)
                                    }
                                    {
                                        (item.type === 'Software' &&
                                            !this.props.selectedItem &&
                                            <ListItemText inset primary={(item.licenseId ? item.licenseId : 'No license number ') + ' ' + (item.description ? item.description : '')} className={classes.searchListItemText}
                                                secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                    ((item.tecdisType ? ('Type ' + item.tecdisType + ', ') : '') +
                                                        (item.licenseId ? ('License Id ' + item.licenseId + ', ') : '') +
                                                        (item.description ? ('Notes ' + item.description + '.') : '') +
                                                        (item.version ? ('Version ' + item.version + ', ') : '') +
                                                        (item.orderNo ? ('Order number ' + item.orderNo + ', ') : '') +
                                                        (item.orderType ? ('Order type ' + item.orderType + ', ') : '') +
                                                        (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                        (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                        (item.purchaseDate ? ('Delivery Date ' + this.dateForamtting(item.purchaseDate) + ', ') : '') +
                                                        (item.installDate ? ('Installation Date ' + this.dateForamtting(item.installDate) + ', ') : ''))}
                                                </span>}
                                            />)
                                    }
                                    {
                                        (item.type === 'Hardware' &&
                                            this.props.selectedItem &&
                                            <ListItemText inset primary={(item.tecdisType ? (item.tecdisType + ' ') : ' ') + (item.hardwareSerialNumber ? item.hardwareSerialNumber : 'No serial number')} className={classes.searchListItemText}
                                                secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                    (((item.hardwareSerialNumber ? ('Telko Serial Number ' + item.hardwareSerialNumber + ', ') : '') +
                                                        (item.tecdisType ? ('Product ' + item.tecdisType + ', ') : '') +
                                                        (item.oemSerialNo ? ('OEM ' + item.oemSerialNo + ', ') : '') +
                                                        (item.osType ? ('OS ' + item.osType + ', ') : '') +
                                                        (item.osId ? ('OS ID ' + item.osId + ', ') : '') +
                                                        (item.diskType ? ('Disk Size ' + item.diskType + ', ') : '') +
                                                        (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                        (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                        (item.supplier ? ('Supplier ' + item.supplier + ', ') : '') +
                                                        (item.productionDate ? ('Date of production ' + this.dateForamtting(item.productionDate) + ', ') : '') +
                                                        (item.installDate ? ('Date of installation ' + this.dateForamtting(item.installDate) + ', ') : '') +
                                                        (item.conning ? ('Conning ' + item.conning + ', ') : '') +
                                                        ('Conning ' + (item.conning ? 'True' : 'False')) + ', ' +
                                                        (item.notes ? ('Notes ' + item.notes + '.') : '') +
                                                        (((this.rmaListToString(item.rma)).length > 0) ? ('RMAs ' + this.rmaListToString(item.rma) + ', ') : '')).substring(0, 120) +
                                                        ((((item.hardwareSerialNumber ? ('Telko Serial Number ' + item.hardwareSerialNumber + ', ') : '') +
                                                            (item.tecdisType ? ('Product ' + item.tecdisType + ', ') : '') +
                                                            (item.conning ? ('Conning ' + item.conning + ', ') : '') +
                                                            ('Conning ' + (item.conning ? 'True' : 'False')) + ', ' +
                                                            (item.productionDate ? ('Date of production ' + this.dateForamtting(item.productionDate) + ', ') : '') +
                                                            (item.installDate ? ('Date of installation ' + this.dateForamtting(item.installDate) + ', ') : '') +
                                                            (item.osType ? ('OS ' + item.osType + ', ') : '') +
                                                            (item.osId ? ('OS id ' + item.osId + ', ') : '') +
                                                            (item.diskType ? ('Disk Size ' + item.diskType + ', ') : '') +
                                                            (item.oemSerialNo ? ('OEM ' + item.oemSerialNo + ', ') : '') +
                                                            (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                            (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                            (item.supplier ? ('Supplier ' + item.supplier + ', ') : '') +
                                                            (((this.rmaListToString(item.rma)).length > 0) ? ('RMAs ' + this.rmaListToString(item.rma) + ', ') : '') +
                                                            (item.notes ? ('Notes ' + item.notes + '.') : '')).substring(0, 120)).length >= 119 ? '...' : ''))}
                                                </span>}
                                            />)
                                    }
                                    {
                                        (item.type === 'Hardware' &&
                                            !this.props.selectedItem &&
                                            <ListItemText inset primary={(item.tecdisType ? (item.tecdisType + ' ') : ' ') + (item.hardwareSerialNumber ? item.hardwareSerialNumber : 'No serial number')} className={classes.searchListItemText}
                                                secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                    ((item.hardwareSerialNumber ? ('Telko Serial Number ' + item.hardwareSerialNumber + ', ') : '') +
                                                        (item.tecdisType ? ('Product ' + item.tecdisType + ', ') : '') +
                                                        (item.oemSerialNo ? ('OEM ' + item.oemSerialNo + ', ') : '') +
                                                        (item.osType ? ('OS ' + item.osType + ', ') : '') +
                                                        (item.osId ? ('OS ID ' + item.osId + ', ') : '') +
                                                        (item.diskType ? ('Disk Size ' + item.diskType + ', ') : '') +
                                                        (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                        (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                        (item.supplier ? ('Supplier ' + item.supplier + ', ') : '') +
                                                        (item.productionDate ? ('Date of production ' + this.dateForamtting(item.productionDate) + ', ') : '') +
                                                        (item.installDate ? ('Date of installation ' + this.dateForamtting(item.installDate) + ', ') : '') +
                                                        (item.conning ? ('Conning ' + item.conning + ', ') : '') +
                                                        ('Conning ' + (item.conning ? 'True' : 'False')) + ', ' +
                                                        (item.notes ? ('Notes ' + item.notes + '.') : '') +
                                                        (((this.rmaListToString(item.rma)).length > 0) ? ('RMAs ' + this.rmaListToString(item.rma) + ', ') : ''))}
                                                </span>}
                                            />)
                                    }
                                    {
                                        (item.type === 'Vessel' &&
                                            this.props.selectedItem &&
                                            <ListItemText inset primary={item.vesselName ? item.vesselName : 'No vessel name'} className={classes.searchListItemText}
                                                secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                    (((item.vesselName ? ('Name ' + item.vesselName + ', ') : '') +
                                                        (item.vesselSerialNumber ? ('IMO number ' + item.vesselSerialNumber + ', ') : '') +
                                                        (item.buildNo ? ('Yard number ' + item.buildNo + ', ') : '') +
                                                        (item.mmsiNo ? ('MMSI ' + item.mmsiNo + ', ') : '') +
                                                        (item.vesselType ? ('Type ' + item.vesselType + ', ') : '') +
                                                        (item.callSign ? ('Call sign ' + item.callSign + ', ') : '') +
                                                        (item.port ? ('Port ' + item.port + ', ') : '') +
                                                        (item.flag ? ('Flag ' + item.flag + ', ') : '') +
                                                        (item.yard ? ('Yard ' + item.yard + ', ') : '') +
                                                        (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                        (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                        (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                        (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : '')).substring(0, 120) +
                                                        ((((item.vesselSerialNumber ? ('IMO number ' + item.vesselSerialNumber + ', ') : '') +
                                                            (item.vesselName ? ('Name ' + item.vesselName + ', ') : '') +
                                                            (item.vesselType ? ('Type ' + item.vesselType + ', ') : '') +
                                                            (item.callSign ? ('Call sign ' + item.callSign + ', ') : '') +
                                                            (item.port ? ('Port ' + item.port + ', ') : '') +
                                                            (item.flag ? ('Flag ' + item.flag + ', ') : '') +
                                                            (item.yard ? ('Yard ' + item.yard + ', ') : '') +
                                                            (item.buildNo ? ('Yard number ' + item.buildNo + ', ') : '') +
                                                            (item.mmsiNo ? ('MMSI ' + item.mmsiNo + ', ') : '') +
                                                            (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                            (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                            (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                            (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : '')).substring(0, 120)).length >= 119 ? '...' : ''))}
                                                </span>}
                                            />)
                                    }
                                    {
                                        (item.type === 'Vessel' &&
                                            !this.props.selectedItem &&
                                            <ListItemText inset primary={item.vesselName ? item.vesselName : 'No vessel name'} className={classes.searchListItemText}
                                                secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                    ((item.vesselName ? ('Name ' + item.vesselName + ', ') : '') +
                                                        (item.vesselSerialNumber ? ('IMO number ' + item.vesselSerialNumber + ', ') : '') +
                                                        (item.buildNo ? ('Yard number ' + item.buildNo + ', ') : '') +
                                                        (item.mmsiNo ? ('MMSI ' + item.mmsiNo + ', ') : '') +
                                                        (item.vesselType ? ('Type ' + item.vesselType + ', ') : '') +
                                                        (item.callSign ? ('Call sign ' + item.callSign + ', ') : '') +
                                                        (item.port ? ('Port ' + item.port + ', ') : '') +
                                                        (item.flag ? ('Flag ' + item.flag + ', ') : '') +
                                                        (item.yard ? ('Yard ' + item.yard + ', ') : '') +
                                                        (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                        (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                        (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                        (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : ''))}
                                                </span>}
                                            />)
                                    }
                                    {
                                        (item.type === 'Customer' &&
                                            this.props.selectedItem &&
                                            <ListItemText inset primary={item.name ? item.name : 'No customer name'} className={classes.searchListItemText}
                                                secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                    (((item.name ? ('Name ' + item.name + ', ') : '') +
                                                        (item.address ? ('Address ' + item.address + ', ') : '') +
                                                        (item.city ? ('City ' + item.city + ', ') : '') +
                                                        (item.country ? ('Country ' + item.country + ', ') : '') +
                                                        (item.contactName ? ('Contact first name ' + item.contactName + ', ') : '') +
                                                        (item.contactLastName ? ('Contact last name ' + item.contactLastName + ', ') : '') +
                                                        (item.contactPhone ? ('Contact phone ' + item.contactPhone + ', ') : '') +
                                                        (item.contactEmail ? ('Contact email ' + item.contactEmail + ', ') : '') +
                                                        (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                        (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                        (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                        (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : '')).substring(0, 120) +
                                                        ((((item.name ? ('Name ' + item.name + ', ') : '') +
                                                            (item.address ? ('Address ' + item.address + ', ') : '') +
                                                            (item.city ? ('City ' + item.city + ', ') : '') +
                                                            (item.country ? ('Country ' + item.country + ', ') : '') +
                                                            (item.contactName ? ('Contact first name ' + item.contactName + ', ') : '') +
                                                            (item.contactLastName ? ('Contact last name ' + item.contactLastName + ', ') : '') +
                                                            (item.contactPhone ? ('Contact phone ' + item.contactPhone + ', ') : '') +
                                                            (item.contactEmail ? ('Contact email ' + item.contactEmail + ', ') : '') +
                                                            (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                            (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                            (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                            (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : '')).substring(0, 120)).length >= 119 ? '...' : ''))}
                                                </span>}
                                            />)
                                    }
                                    {
                                        (item.type === 'Customer' &&
                                            !this.props.selectedItem &&
                                            <ListItemText inset primary={item.name ? item.name : 'No customer name'} className={classes.searchListItemText}
                                                secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                    ((item.name ? ('Name ' + item.name + ', ') : '') +
                                                        (item.address ? ('Address ' + item.address + ', ') : '') +
                                                        (item.city ? ('City ' + item.city + ', ') : '') +
                                                        (item.country ? ('Country ' + item.country + ', ') : '') +
                                                        (item.contactName ? ('Contact first name ' + item.contactName + ', ') : '') +
                                                        (item.contactLastName ? ('Contact last name ' + item.contactLastName + ', ') : '') +
                                                        (item.contactPhone ? ('Contact phone ' + item.contactPhone + ', ') : '') +
                                                        (item.contactEmail ? ('Contact email ' + item.contactEmail + ', ') : '') +
                                                        (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                        (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                        (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                        (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : ''))}
                                                </span>}
                                            />)
                                    }
                                </ListItem>
                            )
                            )}
                        </List>
                    </div>
                }
            </div>);
    }
}

export default withStyles(styles, { withTheme: true })(SearchList);
