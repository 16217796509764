import React, { Component } from 'react';
import DragDropList from '../dragdroplist/dragdroplist'

export default class UpdateUserRole extends Component {

    rolesChanged = data => {
	const userRoles  = data.map( item => ({ text : item.content }) );

	console.log(JSON.stringify(data));
	console.log(JSON.stringify(userRoles));
	this.props.onChange(userRoles);
    }

    render() {
	let dragDropFromList = [];
        let dragDropToList = [];

        for (let key in this.props.adminRoleList) {
	    let found = false;
	    for (const keyUser in this.props.userRoleList) {
                if (this.props.userRoleList[keyUser].resource === this.props.adminRoleList[key].resource ||
		    this.props.adminRoleList[key].url === '') {
		    found = true;
		    dragDropToList.push({ text: this.props.userRoleList[keyUser].resource });
		}
	    }

	    if (!found)
		dragDropFromList[key] = { text: this.props.adminRoleList[key].resource };
        }

        return (
           <div className="user-role-container">
             <div>
                <div style={{ "textAlign": "flex-end" }}></div>

                {this.props.adminRoleList &&
		   <DragDropList
		     fromTableHeader='Available Roles'
		     toTableHeader='Assigned Roles'
		     fromListElements={dragDropFromList}
		     toListElements={dragDropToList}
		     setImage={true}
		     onChange={this.rolesChanged} />}
                {this.props.showSubmit && <button type="button" onClick={this.updateDatabase}>Save!</button>}
              </div>
            </div>
            );
        }
    }
