import React, { Component } from 'react';
import { Route, Switch }    from "react-router-dom";
import AuthenticatedRoute from '../components/authenticated-route/authenticated-route';
import DashBoard          from '../pages/dashboard/dashboard';
import Login              from '../pages/loginpage/loginpage';
import NewUser            from '../pages/newuserpage/newuserpage';
import AdminMainPage      from '../pages/adminmainpage/adminmainpage'
import UserInfoPage       from '../pages/userinfopage/userinfopage'
import EditUserData       from '../pages/edituserinfopage/edituserinfopage'
import RolePermissionPage from '../pages/rolepermissionpage/rolepermissionpage'
import NotFound from '../pages/notfound/notfound'

export default class RouterPage extends Component {
    render() {
	const loginstate = this.props.loginstate;
            
        //<Route exact path="/signup" render={(props) => <NewUser loginstate={loginstate} showSubmit={true} />} />
    return (
        <Switch>
            <Route path="/login" exact render={(props) => <Login loginstate={loginstate} />} />

            <AuthenticatedRoute path="/" exact component={DashBoard} loginstate={loginstate} />
            <AuthenticatedRoute path="/signup" exact component={NewUser} loginstate={loginstate} showSubmit={true}/>
            <AuthenticatedRoute path="/adminmainpage" exact component={AdminMainPage} loginstate={loginstate} />
            <AuthenticatedRoute exact strict path="/userinfo/:userName" component={UserInfoPage} loginstate={loginstate} />
            <AuthenticatedRoute path="/userprofile/:username" exact component={EditUserData} loginstate={loginstate} />
            <AuthenticatedRoute path="/roleinfo/:roleName" exact
                component={RolePermissionPage}
                loginstate={loginstate} />
            <AuthenticatedRoute path="/edituser/:userName" exact
                component={EditUserData}
                loginstate={loginstate} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
    }
}
