import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CustomerIcon from '@material-ui/icons/Person';
import Chip from '@material-ui/core/Chip';
import VesselIcon from '@material-ui/icons/DirectionsBoat';
import SoftwareIcon from '@material-ui/icons/Memory';
import HardwareIcon from '@material-ui/icons/Computer';
import SubscriptionIcon from '@material-ui/icons/Subscriptions';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import InfoMessage from '../infomessage/infomessage'
import ConfirmMessage from './confirm_message'
import './information_card.css'
import ForwardIcon from '@material-ui/icons/Forward'
import PasteIcon from './Paste.svg'
import CommentIcon from '@material-ui/icons/ChatBubble'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CommentModule from '../comment_module/comment_module'
import Tooltip from '@material-ui/core/Tooltip';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';

const defaultTheme = createMuiTheme({ typography: { useNextVariants: true } });

const styles = theme => ({
    informationCard: {
        //height: '26.2em',
    },
    InfoCardContent: {
        //height: '21em',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    InfoCardContentEditable: {
        //height: '18.5em',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    InfoCardHeader: {
        //height: '3em',
    },
    InfoCardAction: {
        //height: '2.5em',
    },
    selectedCard: {
        backgroundColor: '#fff',
        outline: '#2e3847',
        outlineWidth: 'medium',
        outlineStyle: 'solid'
    },
    clickableHeader: {
        cursor: 'pointer'
    },
    LabelValue: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '0em'
    },
    DisabledInputValue: {
        color: 'rgba(0, 0, 0, 0.87)',
        height: '.1em'
    },
    EnabledInputValue: {
        color: '#0071BC',
        height: '.1em'
    },
    DateContainer: {
        //margin: 5,
        //width: '11em',
        width:'100%',
        display: 'inline-flex',
        //height: 40,
    },
    DateText: {
        //width: '11em',
        //margin: 5,
        //height: 40
    },
    RmaDateContainer: {
        //margin: 5,
        display: 'inline-flex',
        width:'100%',
        //width: '17em',
        //height: 40,
        //height: '4.5em',
    },
    RmaDateText: {
        //width: '17em',
        //margin: 5,
        //height: 40
    },
    Date: {
        margin: 5,
        color: '#0071BC',
        padding: 6,
        paddingLeft: 10,
    },
    activeStatus: {
        //width: '11em',
        //margin: 5,
        height: 40
    },
    invoicedStatus: {
        //width: '11em',
        //margin: 5,
        height: 40
    },
    invoicedNumber: {
        //width: '11em',
        //margin: 5,
        //height: 45
    },
    rmaSerialNumber: {
        //width: '8em',
        //margin: 5,
        //height: 45
    },
    referenceId: {
        //width: '8em',
        //margin: 5,
        //height: 45
    },
    category: {
        //width: '8em',
        //margin: 5
    },
    orderNumber: {
        //width: '11em',
        //margin: 5,
        //height: 45
    },
    rmaStatus: {
        //width: '8em',
        //margin: 5,
        height: 38
    },
    notes: {
        //width: '30em',
        //margin: 5,
        height: '4.5em'
    },
    rmaToolTip: {
        width: '1.2em',
        height:'1.2em'
    },
    hardwareSerialNumber: {
        //width: '14em',
        //margin: 5,
        //height: 50
    },
    tecdisType: {
        //width: '14em',
        //margin: 5,
        height: 38,
        backgroundColor:'transparent'
    },
    oemSerialNo: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    osType: {
        //width: '10.25em',
        //margin: 5,
        //height: 45
    },
    osId: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    diskType: {
        //width: '10.25em',
        //margin: 5,
        //height: 45
    },
    dealer: {
        //width: '14em',
        //margin: 5,
        //height: 45
    },
    rmadealer: {
        //width: '11em',
        //margin: 5,
        //height: 45
    },
    supplier: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    conning: {
        //width: 100,
        //margin: 5,
        //height: 40
    },
    greyAvtar: {
        backgroundColor:'#e4e4e4'
    },
    redAvtar: {
        backgroundColor: '#ff3324',
        color: '#fff'
    },
    greenAvtar: {
        backgroundColor: '#28a745',
        color: '#fff'
    },
    licenseId: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    softwaretecdisType: {
        //width: '14em',
        //margin: 5,
        height: 38
    },
    softwareDealer: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    description: {
        //width: '30em',
        //margin: 5,
        //height: '4.5em'
    },
    versionNumber: {
        //width: 150,
        //margin: 5,
        //height: 45
    },
    orderNo: {
        //width: '14em',
        //margin: 5,
        //height: 45
    },
    orderType: {
        //width: 100,
        //margin: 5,
        //height: 45
    },
    vesselName: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    vesselSerialNumber: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    buildNo: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    mmsiNo: {
        //width: '14em',
        //margin: 5,
        //height: 45
    },
    port: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    flag: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    vesselType: {
        //width: '14em',
        //margin: 5,
        //height: 45
    },
    callSign: {
        //width: '14em',
        //margin: 5,
        //height: 45
    },
    yard: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    invoiceEmail: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    invoiceAddress: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    licenseEmail: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    licenseAddress: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    name: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    address: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    city: {
        //width: '10.25em',
        //margin: 5,
        //height: 45
    },
    state: {
        //width: '10.25em',
        //margin: 5,
        //height: 45
    },
    postalCode: {
        //width: '10.25em',
        //margin: 5,
        //height: 45
    },
    country: {
        //width: '10.25em',
        //margin: 5,
        //height: 45
    },
    contactName: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    contactLastName: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    contactPhone: {
        //width: '21em',
        //margin: 5,
        //height: 45
    },
    contactEmail: {
        //width: '21em',
        //margin: 5,
        //height: 45
    }
});

class SubscriptionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.list,
            editable: props.newItem,
        };
    }

    handleChange = name => event => {
        if (event.target.value.length) {
            this.setState({
                [name]: event.target.value.trimStart()
            });
        }
        else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    handleDateChange = name => event => {
        if (!event || !Date.parse(event)) {
            this.setState({
                [name]: '',
            });
            return;
        }

        let providedDate = new Date(event);

        let dd = providedDate.getDate();
        let mm = providedDate.getMonth() + 1;
        let yyyy = providedDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        let result = yyyy + '-' + mm + '-' + dd;
        this.setState({
            [name]: result,
        });

    }

    handleEdit = () => {
        this.setState((prevState) => ({
            editable: !prevState.editable,
            ...this.props.list
        }));
    }

    handleNewItemSave = () => {
        const item = this.state;
        const softwareId = this.props.softwareId;
        const data = { 'data': { 'item': item, 'softwareId': softwareId } };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/addsubscription`;
        axios.post(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.closeNewItem();
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });

    }

    handleSave = () => {
        const data = { data: this.state };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/subscriptioninfo/` + this.props.list.item_id;
        axios.put(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    

    render() {
        let list = this.props.list;
        const { classes } = this.props;
        let actionItem1 = (list &&
            <div>
                <Tooltip title="Edit" aria-label="Edit">
                    <IconButton onClick={this.handleEdit}>
                        <EditIcon color={this.state.editable ? 'primary' : 'action'} />
                    </IconButton>
                </Tooltip>
            </div>);

        return (
            <div>
                <Card className='SubscriptionCard'>
                    <CardHeader
                        className={classes.InfoCardHeader}
                        avatar={<SubscriptionIcon />}
                        title={list ? <div>{list.startDate + ' - ' + list.endDate}</div> : 'No subscription registered.'}
                        subheader={<div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Subscription</div>}
                        action={actionItem1}
                        style={{ height: ((window.innerHeight - 55) / 100) * 5.5 }}
                    />
                    {(list || this.props.newItem) &&
                        <CardContent>
                            <div className={this.state.editable ? classes.InfoCardContentEditable : classes.InfoCardContent}
                            style={this.state.editable ? { height: ((window.innerHeight - 55) / 100) * 35.4, paddingTop: 5 } : { height: ((window.innerHeight - 55) / 100) * 38, paddingTop: 5 }}
                            >
                            <Grid container spacing={8}>
                                <Grid item xs={9} md={6} lg={4}>
                            {!this.state.editable &&
                                    <TextField
                                        className={classes.DateText}
                                fullWidth
                                        InputProps={{
                                            classes: {
                                                input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                            }
                                        }}
                                        disabled={true}
                                        id="startDate"
                                        label={<div className={classes.LabelValue}>Start Date</div>}
                                        value={this.state.startDate ? this.state.startDate : ' '}
                                        margin="none"
                                        variant="outlined"
                                    />
                                }
                                {this.state.editable &&
                                    <div className={classes.DateContainer}>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}>
                                            <DatePicker
                                                InputProps={{
                                                    classes: {
                                                        input: classes.Date
                                                    }
                                                }}
                                                fullWidth
                                                keyboard
                                                disabled={!this.state.editable}
                                                id="startDate"
                                                label={<div className={classes.LabelValue}>Start Date</div>}
                                                value={this.state.startDate ? new Date(this.state.startDate) : null}
                                                onChange={this.handleDateChange('startDate')}
                                                margin="none"
                                                variant="outlined"
                                                format='yyyy-MM-dd'
                                                allowKeyboardControl={true}
                                                invalidDateMessage=''
                                                clearable={true}
                                                autoFocus={true}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    }
                                    </Grid>
                                <Grid item xs={9} md={6} lg={4}>
                                    {!this.state.editable &&
                                    <TextField
                                        className={classes.DateText}
                                fullWidth
                                        InputProps={{
                                            classes: {
                                                input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                            }
                                        }}
                                        disabled={true}
                                        id="endDate"
                                        label={<div className={classes.LabelValue}>End Date</div>}
                                        value={this.state.endDate ? this.state.endDate : ' '}
                                        margin="none"
                                        variant="outlined"
                                    />
                                }
                                {this.state.editable &&
                                    <div className={classes.DateContainer}>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}>
                                            <DatePicker
                                                InputProps={{
                                                    classes: {
                                                        input: classes.Date
                                                    }
                                                }}
                                                fullWidth
                                                keyboard
                                                disabled={!this.state.editable}
                                                id="endDate"
                                                label={<div className={classes.LabelValue}>End Date</div>}
                                                value={this.state.endDate ? new Date(this.state.endDate) : null}
                                                onChange={this.handleDateChange('endDate')}
                                                margin="none"
                                                variant="outlined"
                                                format='yyyy-MM-dd'
                                                allowKeyboardControl={true}
                                                invalidDateMessage=''
                                                clearable={true}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                }
                            </Grid>
                                <Grid item xs={9} md={6} lg={4}>
                                <TextField
                                    className={classes.activeStatus}
                                fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="activeStatus"
                                    select
                                    label={<div className={classes.LabelValue}>Activated</div>}
                                    value={this.state.activeStatus ? this.state.activeStatus : false}
                                    onChange={this.handleChange('activeStatus')}
                                    margin="none"
                                    variant="outlined"
                                >
                                    <MenuItem key='1' value={true}>
                                        Yes
                                    </MenuItem>
                                    <MenuItem key='2' value={false}>
                                        No
                                    </MenuItem>
                                </TextField>
                            </Grid>
                                <Grid item xs={9} md={6} lg={4}>
                                <TextField
                                    className={classes.invoicedStatus}
                                fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="invoicedStatus"
                                    select
                                    label={<div className={classes.LabelValue}>Invoice Generated</div>}
                                    value={this.state.invoicedStatus ? this.state.invoicedStatus : false}
                                    onChange={this.handleChange('invoicedStatus')}
                                    margin="none"
                                    variant="outlined"
                                >
                                    <MenuItem key='1' value={true}>
                                        Yes
                                    </MenuItem>
                                    <MenuItem key='2' value={false}>
                                        No
                                    </MenuItem>
                                </TextField>
                            </Grid>
                                <Grid item xs={9} md={6} lg={4}>
                                <TextField
                                    className={classes.invoicedNumber}
                                fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="invoicedNumber"
                                    label={<div className={classes.LabelValue}>Invoice Number</div>}
                                    value={this.state.invoicedNumber ? this.state.invoicedNumber : ' '}
                                    onChange={this.handleChange('invoicedNumber')}
                                    margin="none"
                                    variant="outlined"
                                />
                            </Grid>
                                <Grid item xs={9} md={6} lg={4}>
                                {!this.state.editable &&
                                    <TextField
                                        className={classes.DateText}
                                fullWidth
                                        InputProps={{
                                            classes: {
                                                input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                            }
                                        }}
                                        disabled={true}
                                        id="invoiceDate"
                                        label={<div className={classes.LabelValue}>Invoice Date</div>}
                                        value={this.state.invoiceDate ? this.state.invoiceDate : ' '}
                                        margin="none"
                                        variant="outlined"
                                    />
                                }
                                {this.state.editable &&
                                    <div className={classes.DateContainer}>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}>
                                            <DatePicker
                                                InputProps={{
                                                    classes: {
                                                        input: classes.Date
                                                    }
                                                }}
                                                fullWidth
                                                keyboard
                                                disabled={!this.state.editable}
                                                id="invoiceDate"
                                                label={<div className={classes.LabelValue}>Invoice Date</div>}
                                                value={this.state.invoiceDate ? new Date(this.state.invoiceDate) : null}
                                                onChange={this.handleDateChange('invoiceDate')}
                                                margin="none"
                                                variant="outlined"
                                                format='yyyy-MM-dd'
                                                allowKeyboardControl={true}
                                                invalidDateMessage=''
                                                clearable={true}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                }
                            </Grid>
                                <Grid item xs={9} md={6} lg={4}>
                                {!this.state.editable &&
                                    <TextField
                                        className={classes.DateText}
                                fullWidth
                                        InputProps={{
                                            classes: {
                                                input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                            }
                                        }}
                                        disabled={true}
                                        id="paidDate"
                                        label={<div className={classes.LabelValue}>Paid Date</div>}
                                        value={this.state.paidDate ? this.state.paidDate : ' '}
                                        margin="none"
                                        variant="outlined"
                                    />
                                }
                                {this.state.editable &&
                                    <div className={classes.DateContainer}>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}>
                                            <DatePicker
                                                InputProps={{
                                                    classes: {
                                                        input: classes.Date
                                                    }
                                                }}
                                                fullWidth
                                                keyboard
                                                disabled={!this.state.editable}
                                                id="paidDate"
                                                label={<div className={classes.LabelValue}>Paid Date</div>}
                                                value={this.state.paidDate ? new Date(this.state.paidDate) : null}
                                                onChange={this.handleDateChange('paidDate')}
                                                margin="none"
                                                variant="outlined"
                                                format='yyyy-MM-dd'
                                                allowKeyboardControl={true}
                                                invalidDateMessage=''
                                                clearable={true}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                }
                            </Grid>
                            </Grid>
                            </div>
                        </CardContent>
                    }
                    {this.state.editable &&
                        <CardActions className={classes.InfoCardAction}
                            style={{ height: ((window.innerHeight - 55) / 100) * 3.5, paddingLeft: '41%' }}
                        >
                            <Tooltip title="Save" aria-label="Save" >
                                <IconButton onClick={this.props.newItem ? this.handleNewItemSave : this.handleSave}>
                                    <SaveIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" aria-label="Cancel">
                                <IconButton onClick={this.props.newItem ? this.props.closeNewItem : this.handleEdit}>
                                    <CancelIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    }
                </Card>
            </div>);
    }
}

class RmaCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.list,
            confirmDeleteMessage: false,
            editable: props.newItem,
            commentShown: false
        };
    }

    handleOpenComments = event => {
        this.setState({ commentShown: true });
    };

    handleChange = name => event => {
        if (event.target.value.length) {
            this.setState({
                [name]: event.target.value.trimStart()
            });
        }
        else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    handleDateChange = name => event => {
        if (!event || !Date.parse(event)) {
            this.setState({
                [name]: '',
            });
            return;
        }

        let providedDate = new Date(event);

        let dd = providedDate.getDate();
        let mm = providedDate.getMonth() + 1;
        let yyyy = providedDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        let result = yyyy + '-' + mm + '-' + dd;
        this.setState({
            [name]: result,
        });

    }

    handleEdit = () => {
        this.setState((prevState) => ({
            editable: !prevState.editable,
            ...this.props.list
        }));
    }

    handleNewItemSave = () => {
        const item = this.state;
        const hardwareId = this.props.hardwareId;
        const data = { 'data': { 'item': item, 'hardwareId': hardwareId } };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/addrma`;
        axios.post(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.closeNewItem();
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });

    }

    handleSave = () => {
        const data = { data: this.state };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/rmainfo/` + this.props.list.item_id;
        axios.put(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleDelete = () => {
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/rmainfo/` + this.props.list.item_id;
        axios.delete(url,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState({
                    confirmDeleteMessage: false,
                    confirmCutMessage: false
                });
                this.props.openDeleteMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    closeConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: false });
    }

    openConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: true });
    }

    render() {
        let list = this.props.list;
        const { classes } = this.props;

        let actionItem1 = (list && list.status &&
            <div>
                <Tooltip title="Edit" aria-label="Edit">
                    <IconButton onClick={this.handleEdit}>
                        <EditIcon color={this.state.editable ? 'primary' : 'action'} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete" aria-label="Delete">
                    <IconButton onClick={this.openConfirmDeleteMessage}>
                        <DeleteIcon color='action' />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Comment" aria-label="Comment">
                    <IconButton
                        onClick={this.handleOpenComments}
                        color="primary">
                        <Badge badgeContent={list.comments ? list.comments.length : 0} color="secondary">
                            <CommentIcon style={{ color: '#0071BC' }}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>);

        return (
            <div>
                <ConfirmMessage message='Deleting Item' fullMessage='Press Continue to confirm deletion or press Cancel for aborting.' open={this.state.confirmDeleteMessage} handleClose={this.closeConfirmDeleteMessage} handleConfirmed={this.handleDelete} />

                <Dialog
                    open={this.state.commentShown}
                    onClose={() => { this.setState({ commentShown: false }) }}
                >
                    <DialogContent>
                        <CommentModule type='Rma' list={list ? list.comments : null} itemId={list ? list.item_id : null} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={() => { this.setState({ commentShown: false }) }} openSaveMessage={this.props.openSaveMessage} />
                    </DialogContent>
                </Dialog>

                <Card className='RmaCard'>
                    <CardHeader
                        className={classes.InfoCardHeader}
                        avatar={<Avatar>RMA</Avatar>}
                        title={list ? <div>{list.rmaSerialNumber + ' - ' + list.referenceId}</div> : 'No rma registered.'}
                        subheader={<div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>RMA</div>}
                        action={actionItem1}
                        style={{ height: ((window.innerHeight - 55) / 100) * 5.5 }}
                    />
                    {(list || this.props.newItem) &&
                        <CardContent className={!this.state.status && !this.props.newItem ? 'deletedItem' : ''}>
                            <div className={this.state.editable ? classes.InfoCardContentEditable : classes.InfoCardContent}
                                style={this.state.editable ? { height: ((window.innerHeight - 55) / 100) * 35.4, paddingTop: 5 } : { height: ((window.innerHeight - 55) / 100) * 38, paddingTop: 5 }}
                            >
                                <Grid container spacing={8}>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.rmaSerialNumber}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="rmaSerialNumber"
                                            label={<div className={classes.LabelValue}>RMA Number</div>}
                                            value={this.state.rmaSerialNumber ? this.state.rmaSerialNumber : ' '}
                                            onChange={this.handleChange('rmaSerialNumber')}
                                            margin="none"
                                            variant="outlined"
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.referenceId}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="referenceId"
                                            label={<div className={classes.LabelValue}>Supplier RMA number</div>}
                                            value={this.state.referenceId ? this.state.referenceId : ' '}
                                            onChange={this.handleChange('referenceId')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.category}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="category"
                                            label={<div className={classes.LabelValue}>Category</div>}
                                            value={this.state.category ? this.state.category : ' '}
                                            onChange={this.handleChange('category')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.rmaStatus}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="rmaStatus"
                                            select
                                            label={<div className={classes.LabelValue}>Warranty</div>}
                                            value={this.state.rmaStatus ? this.state.rmaStatus : false}
                                            onChange={this.handleChange('rmaStatus')}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            <MenuItem key='1' value={true}>
                                                Yes
                                    </MenuItem>
                                            <MenuItem key='2' value={false}>
                                                No
                                    </MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.notes}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="notes"
                                            label={<div className={classes.LabelValue}>Description</div>}
                                            multiline
                                            rowsMax="2"
                                            value={this.state.notes ? this.state.notes : ' '}
                                            onChange={this.handleChange('notes')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={4}>
                                        <div className="RmaDistributorFieldContainer" disabled={!this.state.editable}>
                                            <label className='DistributorFieldLabel' disabled={!this.state.editable} >Distributor</label>
                                            <input
                                                className='DistributorField'
                                                type="text"
                                                list="data"
                                                id="distributor"
                                                value={this.state.distributor ? this.state.distributor : ' '}
                                                disabled={!this.state.editable}
                                                onChange={this.handleChange('distributor')} />

                                            <datalist id="data">
                                                <option value='Furuno Norge' />
                                                <option value='Telko International' />
                                            </datalist>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={4}>
                                        <TextField
                                            className={classes.rmadealer}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="dealer"
                                            label={<div className={classes.LabelValue}>Dealer</div>}
                                            value={this.state.dealer ? this.state.dealer : ' '}
                                            onChange={this.handleChange('dealer')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={4}>
                                        <TextField
                                            className={classes.orderNumber}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="orderNumber"
                                            label={<div className={classes.LabelValue}>Order Number</div>}
                                            value={this.state.orderNumber ? this.state.orderNumber : ' '}
                                            onChange={this.handleChange('orderNumber')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        {!this.state.editable &&
                                            <TextField
                                                className={classes.RmaDateText}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                    }
                                                }}
                                                disabled={true}
                                                id="openDate"
                                                label={<div className={classes.LabelValue}>Open Date</div>}
                                                value={this.state.openDate ? this.state.openDate : ' '}
                                                margin="none"
                                                variant="outlined"
                                            />
                                        }
                                        {this.state.editable &&
                                            <div className={classes.RmaDateContainer}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.Date
                                                            }
                                                        }}
                                                fullWidth
                                                keyboard
                                                        disabled={!this.state.editable}
                                                        id="openDate"
                                                        label={<div className={classes.LabelValue}>Open Date</div>}
                                                        value={this.state.openDate ? new Date(this.state.openDate) : null}
                                                        onChange={this.handleDateChange('openDate')}
                                                        margin="none"
                                                        variant="outlined"
                                                        format='yyyy-MM-dd'
                                                        allowKeyboardControl={true}
                                                        invalidDateMessage=''
                                                        clearable={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        {!this.state.editable &&
                                            <TextField
                                                className={classes.RmaDateText}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                    }
                                                }}
                                                disabled={true}
                                                id="vedorSentDate"
                                                label={<div className={classes.LabelValue}>Sent to Supplier</div>}
                                                value={this.state.vedorSentDate ? this.state.vedorSentDate : ' '}
                                                margin="none"
                                                variant="outlined"
                                            />
                                        }
                                        {this.state.editable &&
                                            <div className={classes.RmaDateContainer}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.Date
                                                            }
                                                        }}
                                                fullWidth
                                                keyboard
                                                        disabled={!this.state.editable}
                                                        id="vedorSentDate"
                                                        label={<div className={classes.LabelValue}>Sent to Supplier</div>}
                                                        value={this.state.vedorSentDate ? new Date(this.state.vedorSentDate) : null}
                                                        onChange={this.handleDateChange('vedorSentDate')}
                                                        margin="none"
                                                        variant="outlined"
                                                        format='yyyy-MM-dd'
                                                        allowKeyboardControl={true}
                                                        invalidDateMessage=''
                                                        clearable={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        {!this.state.editable &&
                                            <TextField
                                                className={classes.RmaDateText}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                    }
                                                }}
                                                disabled={true}
                                                id="closeDate"
                                                label={<div className={classes.LabelValue}>Close Date</div>}
                                                value={this.state.closeDate ? this.state.closeDate : ' '}
                                                margin="none"
                                                variant="outlined"
                                            />
                                        }
                                        {this.state.editable &&
                                            <div className={classes.RmaDateContainer}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.Date
                                                            }
                                                        }}
                                                fullWidth
                                                keyboard
                                                        disabled={!this.state.editable}
                                                        id="closeDate"
                                                        label={<div className={classes.LabelValue}>Close Date</div>}
                                                        value={this.state.closeDate ? new Date(this.state.closeDate) : null}
                                                        onChange={this.handleDateChange('closeDate')}
                                                        margin="none"
                                                        variant="outlined"
                                                        format='yyyy-MM-dd'
                                                        allowKeyboardControl={true}
                                                        invalidDateMessage=''
                                                        clearable={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        {!this.state.editable &&
                                            <TextField
                                                className={classes.RmaDateText}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                    }
                                                }}
                                                disabled={true}
                                                id="vendorRecieveDate"
                                                label={<div className={classes.LabelValue}>Recieved from Supplier</div>}
                                                value={this.state.vendorRecieveDate ? this.state.vendorRecieveDate : ' '}
                                                margin="none"
                                                variant="outlined"
                                            />
                                        }
                                        {this.state.editable &&
                                            <div className={classes.RmaDateContainer}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.Date
                                                            }
                                                        }}
                                                fullWidth
                                                keyboard
                                                        disabled={!this.state.editable}
                                                        id="vendorRecieveDate"
                                                        label={<div className={classes.LabelValue}>Recieved from Supplier</div>}
                                                        value={this.state.vendorRecieveDate ? new Date(this.state.vendorRecieveDate) : null}
                                                        onChange={this.handleDateChange('vendorRecieveDate')}
                                                        margin="none"
                                                        variant="outlined"
                                                        format='yyyy-MM-dd'
                                                        allowKeyboardControl={true}
                                                        invalidDateMessage=''
                                                        clearable={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    }
                    {this.state.editable &&
                        <CardActions className={classes.InfoCardAction}
                            style={{ height: ((window.innerHeight - 55) / 100) * 3.5, paddingLeft: '38%' }}
                        >
                            <Tooltip title="Save" aria-label="Save" >
                                <IconButton onClick={this.props.newItem ? this.handleNewItemSave : this.handleSave}>
                                    <SaveIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" aria-label="Cancel">
                                <IconButton onClick={this.props.newItem ? this.props.closeNewItem : this.handleEdit}>
                                    <CancelIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    }

                </Card>
            </div>);
    }
}

class HardwareCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.list,
            confirmDeleteMessage: false,
            confirmCutMessage: false,
            editable: props.newItem,
            rmaDropDown: null,
            fetchedRma: null,
            newRma: false,
            commentShown: false,
        };
    }

    handleOpenComments = event => {
        this.setState({ commentShown: true });
    };

    handleOpenRmaDropDown = event => {
        this.setState({ rmaDropDown: event.currentTarget });
    };

    handleCloseRmaDropDown = () => {
        this.setState({ rmaDropDown: null });
    };

    handleChange = name => event => {
        if (event.target.value.length) {
            this.setState({
                [name]: event.target.value.trimStart()
            });
        }
        else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    handleDateChange = name => event => {
        if (!event || !Date.parse(event)) {
            this.setState({
                [name]: '',
            });
            return;
        }

        let providedDate = new Date(event);

        let dd = providedDate.getDate();
        let mm = providedDate.getMonth() + 1;
        let yyyy = providedDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        let result = yyyy + '-' + mm + '-' + dd;
        this.setState({
            [name]: result,
        });

    }

    handleEdit = () => {
        this.setState((prevState) => (
            {
                editable: !prevState.editable,
                ...this.props.list
            }));
    }

    fetchRMAInfo = (item_id) => {
        this.handleCloseRmaDropDown();
        const url = `${process.env.REACT_APP_BACKEND_URL}/rmainfo/` + item_id;
        const headers = { Authorization: this.props.loginstate.token };
        axios.get(url,
            { headers }
        ).then(response => {
            this.setState({ fetchedRma: response.data });
            })
            .catch(error => {
                this.setState({ fetchedRma: null });
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleNewItemSave = () => {
        const item = this.state;
        const list = this.props.relatedList;
        if (this.props.listType === 'Vessel' || this.props.listType === 'Customer') {
            list.softwares = [];
        }
        const data = { 'data': { 'item': item, 'list': list } };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/addhardware`;
        axios.post(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.closeNewItem();
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });

    }

    handleSave = () => {
        const data = { data: this.state };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/hardwareinfo/` + this.props.list.item_id;
        axios.put(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleDelete = () => {
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/hardwareinfo/` + this.props.list.item_id;
        axios.delete(url,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState({
                    confirmDeleteMessage: false,
                    confirmCutMessage: false,
                });
                this.props.openDeleteMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleCut = () => {
        this.setState({
            confirmCutMessage: false
        });
        let list = this.props.list;
        if (!list)
            return;
        list.customers = [];
        list.vessels = [];
        this.props.copyDetails('hardwareDetails', list);
    }

    handlePaste = () => {
        let data = this.props.copiedList['hardwareDetails'];
        this.setState((prevState) => {
            for (let key in data)
                prevState[key] = data[key];
            return (
                {
                    prevState
                }
            );
        });
        this.props.copyDetails('hardwareDetails', null);
    }

    handleHeaderClick = (event) => {
        event.preventDefault();
        if (this.props.onSelection)
            this.props.onSelection(this.props.list);
    };

    closeConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: false });
    }

    openConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: true });
    }

    closeconfirmCutMessage = () => {
        this.setState({ confirmCutMessage: false });
    }

    openconfirmCutMessage = () => {
        this.setState({ confirmCutMessage: true });
    }

    handleAddNewItem = () => {
        this.props.addNewItem();
    }

    addNewRma = () => {
        this.setState({ newRma: true });
    }

    render() {
        const { classes } = this.props;
        let list = this.props.list;

        let addButton = (this.props &&
            (this.props.listType !== 'Hardware' || (this.props.listType !== 'Software' && !list)) &&
            <Tooltip title="Add New" aria-label="Add New">
            <IconButton onClick={this.handleAddNewItem}>
                    <AddIcon color='action' />
                </IconButton>
            </Tooltip>
        );
        let actionItem1 = (list && list.status &&
            <div>
                <Tooltip title="RMA" aria-label="RMA" >
                    <IconButton aria-owns={this.state.rmaDropDown ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleOpenRmaDropDown}>
                        <Badge badgeContent={list.rmas ? list.rmas.length : 0} color="secondary" >
                        <Avatar className={classes.rmaToolTip}>R</Avatar>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.rmaDropDown}
                    open={Boolean(this.state.rmaDropDown)}
                    onClose={this.handleCloseRmaDropDown}
                >
                    {list.rmas && list.rmas.map(rma => (
                        <MenuItem key={rma.item_id} onClick={() => { this.fetchRMAInfo(rma.item_id) }}>
                            {(rma.rmaSerialNumber ? rma.rmaSerialNumber : '') + ' - ' + (rma.referenceId ? rma.referenceId : '') + (rma.category ? rma.category : '')}
                        </MenuItem>
                    ))}
                    <MenuItem onClick={this.addNewRma}>Add New</MenuItem>
                </Menu>
                <Tooltip title="Edit" aria-label="Edit">
                    <IconButton onClick={this.handleEdit}>
                        <EditIcon color={this.state.editable ? 'primary' : 'action'} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete" aria-label="Delete">
                    <IconButton onClick={this.openConfirmDeleteMessage}>
                        <DeleteIcon color='action' />
                    </IconButton>
                </Tooltip>
                {addButton}
                <Tooltip title="Move" aria-label="Move">
                    <IconButton onClick={this.openconfirmCutMessage}>
                        <ForwardIcon color='action' />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Comment" aria-label="Comment">
                    <IconButton
                        onClick={this.handleOpenComments}
                        color="primary">
                        <Badge badgeContent={list.comments ? list.comments.length : 0} color="secondary">
                            <CommentIcon style={{ color: '#0071BC' }}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>);

        let actionItem2 = (this.props.newItem &&
            <IconButton onClick={this.handlePaste}>
                <img src={PasteIcon} alt="Paste" />
            </IconButton>);

        let actionItem3 = (!list &&
            <Tooltip title="Add New" aria-label="Add New">
            <IconButton onClick={this.handleAddNewItem}>
                    <AddIcon color='action' />
                </IconButton>
            </Tooltip>
        );

        return (
            <div>
                <ConfirmMessage message='Deleting Item' fullMessage='Press Continue to confirm deletion or press Cancel for aborting.' open={this.state.confirmDeleteMessage} handleClose={this.closeConfirmDeleteMessage} handleConfirmed={this.handleDelete} />
                <ConfirmMessage message='Cutting Item' fullMessage='Press Continue to confirm cut from here. You can paste these details in other similar item.' open={this.state.confirmCutMessage} handleClose={this.closeconfirmCutMessage} handleConfirmed={this.handleCut} />

                <Dialog
                    open={this.state.fetchedRma != null}
                    onClose={() => { this.setState({ fetchedRma: null }) }}
                    aria-labelledby="form-dialog-title"
                    maxWidth='xs, sm, md, lg, xl'
                    style={{
                        width: '58em',
                        position: 'fixed',
                        top: '-20%',
                        left: '20%'
                    }}
                >
                    <DialogContent>
                        <RmaCard list={this.state.fetchedRma} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} classes={classes} />
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.newRma}
                    onClose={() => { this.setState({ newRma: false }) }}
                    aria-labelledby="form-dialog-title"
                    maxWidth='xs, sm, md, lg, xl'
                    style={{
                        width: '58em',
                        position: 'fixed',
                        top: '-20%',
                        left: '20%'
                    }}
                >
                    <DialogContent>
                        <RmaCard newItem={true} hardwareId={list ? list.item_id : null} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} classes={classes} closeNewItem={() => { this.setState({ newRma: false }) }} />
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.commentShown}
                    onClose={() => { this.setState({ commentShown: false }) }}
                >
                    <DialogContent>
                        <CommentModule type='Hardware' list={list ? list.comments : null} itemId={list ? list.item_id : null} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={() => { this.setState({ commentShown: false }) }} openSaveMessage={this.props.openSaveMessage} />
                    </DialogContent>
                </Dialog>

                <Card className={this.props.listType === 'Hardware' ? classes.selectedCard : ''}>
                    <CardHeader
                        className={classes.InfoCardHeader}
                        avatar={
                            <HardwareIcon />
                        }
                        title={list ? <div className={classes.clickableHeader} onClick={this.handleHeaderClick}>{((list.tecdisType ? (list.tecdisType + ' ') : '') + (list.hardwareSerialNumber ? list.hardwareSerialNumber : ' No serial number '))}{(!this.state.status && !this.props.newItem) ? ' (Deleted)' : ''}</div> : 'No hardware registered.'}
                        subheader={<div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Hardware</div>}
                        action={actionItem1 ||
                            actionItem2 ||
                            actionItem3
                        }
                        style={{ height: ((window.innerHeight - 55) / 100) * 5.5 }}
                    />
                    {(list || this.props.newItem) &&
                        <CardContent className={!this.state.status && !this.props.newItem ? 'deletedItem' : ''}>
                            <div className={this.state.editable ? classes.InfoCardContentEditable : classes.InfoCardContent}
                                style={this.state.editable ? { height: ((window.innerHeight - 55) / 100) * 35.4, paddingTop: 5 } : { height: ((window.innerHeight - 55) / 100) * 38, paddingTop: 5 }}
                            >
                                <Grid container spacing={8}>
                                    <Grid item xs={9} md={6} lg={4}>
                                        <TextField
                                            className={classes.hardwareSerialNumber}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="hardwareSerialNumber"
                                            label={<div className={classes.LabelValue}>Telko Serial Number</div>}
                                            value={this.state.hardwareSerialNumber ? this.state.hardwareSerialNumber : ' '}
                                            onChange={this.handleChange('hardwareSerialNumber')}
                                            margin="none"
                                            variant="outlined"
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={4}>
                                        <TextField
                                            className={classes.tecdisType}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="tecdisType"
                                            select
                                            label={<div className={classes.LabelValue}>Product</div>}
                                            value={this.state.tecdisType ? this.state.tecdisType : 'None'}
                                            onChange={this.handleChange('tecdisType')}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            <MenuItem key='1' value={'None'}>None</MenuItem>
                                            <MenuItem key='2' value={'TECDIS'}>TECDIS</MenuItem>
                                            <MenuItem key='3' value={'TELchart ECS'}>TELchart ECS</MenuItem>
                                            <MenuItem key='4' value={'TELchart V3'}>TELchart V3</MenuItem>
                                            <MenuItem key='5' value={'TELchart Older'}>TELchart Older</MenuItem>
                                            <MenuItem key='6' value={'TelScope'}>TelScope</MenuItem>
                                            <MenuItem key='7' value={'TelCare'}>TelCare</MenuItem>
                                            <MenuItem key='8' value={'TELchart V'}>TELchart V</MenuItem>
                                            <MenuItem key='9' value={'TELchart V3 Lite'}>TELchart V3 Lite</MenuItem>
                                            <MenuItem key='10' value={'TELchart ECS Lite'}>TELchart ECS Lite</MenuItem>
                                            <MenuItem key='11' value={'TELchart Dos Lite'}>TELchart Dos Lite</MenuItem>
                                            <MenuItem key='12' value={'TELchart Windows Raster Lite'}>TELchart Windows Raster Lite</MenuItem>
                                            <MenuItem key='13' value={'TELchart Windows Vektor Lite'}>TELchart Windows Vektor Lite</MenuItem>
                                            <MenuItem key='14' value={'TELchart Dos Sjark'}>TELchart Dos Sjark</MenuItem>
                                            <MenuItem key='15' value={'TELchart Sjark V'}>TELchart Sjark V</MenuItem>
                                            <MenuItem key='16' value={'TELchart Sjark V'}>TELchart Sjark V</MenuItem>
                                            <MenuItem key='17' value={'COASTAL'}>COASTAL</MenuItem>
                                            <MenuItem key='18' value={'Conning'}>Conning</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={4}>
                                        {!this.state.editable &&
                                            <TextField
                                                className={classes.DateText}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                    }
                                                }}
                                                disabled={true}
                                                id="productionDate"
                                                label={<div className={classes.LabelValue}>Production Date</div>}
                                                value={this.state.productionDate ? this.state.productionDate : ' '}
                                                margin="none"
                                                variant="outlined"
                                            />
                                        }
                                        {this.state.editable &&
                                            <div className={classes.DateContainer}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.Date
                                                            }
                                                        }}
                                                fullWidth
                                                keyboard
                                                        disabled={!this.state.editable}
                                                        id="productionDate"
                                                        label={<div className={classes.LabelValue}>Production Date</div>}
                                                        value={this.state.productionDate ? new Date(this.state.productionDate) : null}
                                                        onChange={this.handleDateChange('productionDate')}
                                                        margin="none"
                                                        variant="outlined"
                                                        format='yyyy-MM-dd'
                                                        allowKeyboardControl={true}
                                                        invalidDateMessage=''
                                                        clearable={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={4}>
                                        <div className="DistributorFieldContainer" disabled={!this.state.editable}>
                                            <label className='DistributorFieldLabel' disabled={!this.state.editable} >Distributor</label>
                                            <div className='DistributorFieldDiv'>
                                                <input
                                                    className='DistributorField'
                                                    type="text"
                                                    list="data"
                                                    id="distributor"
                                                    value={this.state.distributor ? this.state.distributor : ' '}
                                                    disabled={!this.state.editable}
                                                    onChange={this.handleChange('distributor')} />

                                                <datalist id="data">
                                                    <option value='Furuno Norge' />
                                                    <option value='Telko International' />
                                                </datalist>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={4}>
                                        <TextField
                                            className={classes.dealer}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="dealer"
                                            label={<div className={classes.LabelValue}>Dealer</div>}
                                            value={this.state.dealer ? this.state.dealer : ' '}
                                            onChange={this.handleChange('dealer')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={4}>
                                        {!this.state.editable &&
                                            <TextField
                                                className={classes.DateText}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                    }
                                                }}
                                                disabled={true}
                                                id="installDate"
                                                label={<div className={classes.LabelValue}>Installation Date</div>}
                                                value={this.state.installDate ? this.state.installDate : ' '}
                                                margin="none"
                                                variant="outlined"
                                            />
                                        }
                                        {this.state.editable &&
                                            <div className={classes.DateContainer}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.Date
                                                            }
                                                        }}
                                                fullWidth
                                                keyboard
                                                        disabled={!this.state.editable}
                                                        id="installDate"
                                                        label={<div className={classes.LabelValue}>Installation Date</div>}
                                                        value={this.state.installDate ? new Date(this.state.installDate) : null}
                                                        onChange={this.handleDateChange('installDate')}
                                                        margin="none"
                                                        variant="outlined"
                                                        format='yyyy-MM-dd'
                                                        allowKeyboardControl={true}
                                                        invalidDateMessage=''
                                                        clearable={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={9} md={6}>
                                        <TextField
                                            className={classes.supplier}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="supplier"
                                            label={<div className={classes.LabelValue}>Supplier</div>}
                                            value={this.state.supplier ? this.state.supplier : ' '}
                                            onChange={this.handleChange('supplier')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={9} md={6}>
                                        <TextField
                                            className={classes.oemSerialNo}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="oemSerialNo"
                                            label={<div className={classes.LabelValue}>OEM Serial Number</div>}
                                            value={this.state.oemSerialNo ? this.state.oemSerialNo : ' '}
                                            onChange={this.handleChange('oemSerialNo')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.diskType}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="diskType"
                                            label={<div className={classes.LabelValue}>Disk Size</div>}
                                            value={this.state.diskType ? this.state.diskType : ' '}
                                            onChange={this.handleChange('diskType')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.osType}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="osType"
                                            label={<div className={classes.LabelValue}>OS</div>}
                                            value={this.state.osType ? this.state.osType : ' '}
                                            onChange={this.handleChange('osType')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={12} lg={6}>
                                        <TextField
                                            className={classes.osId}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="osId"
                                            label={<div className={classes.LabelValue}>OS ID</div>}
                                            value={this.state.osId ? this.state.osId : ' '}
                                            onChange={this.handleChange('osId')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            className={classes.notes}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="notes"
                                            multiline
                                            rowsMax="2"
                                            label={<div className={classes.LabelValue}>Notes</div>}
                                            value={this.state.notes ? this.state.notes : ' '}
                                            onChange={this.handleChange('notes')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    }
                    {this.state.editable &&
                        <CardActions className={classes.InfoCardAction}
                            style={{ height: ((window.innerHeight - 55) / 100) * 3.5, paddingLeft: '41%' }}
                        >
                            <Tooltip title="Save" aria-label="Save" >
                                <IconButton onClick={this.props.newItem ? this.handleNewItemSave : this.handleSave}>
                                    <SaveIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" aria-label="Cancel">
                                <IconButton onClick={this.props.newItem ? this.props.closeNewItem : this.handleEdit}>
                                    <CancelIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    }
                </Card>
            </div>);
    }
}

class SoftwareCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.list,
            confirmDeleteMessage: false,
            confirmCutMessage: false,
            editable: props.newItem,
            commentShown: false,
            subscriptionDropDown: null,
            selectedSubscription: null,
            newSubscription: false,
            confirmAddMessage: false
        };
    }

    handleOpenComments = event => {
        this.setState({ commentShown: true });
    };

    handleChange = name => event => {
        if (event.target.value.length) {
            this.setState({
                [name]: event.target.value.trimStart()
            });
        }
        else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    handleDateChange = name => event => {
        if (!event || !Date.parse(event)) {
            this.setState({
                [name]: '',
            });
            return;
        }

        let providedDate = new Date(event);

        let dd = providedDate.getDate();
        let mm = providedDate.getMonth() + 1;
        let yyyy = providedDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        let result = yyyy + '-' + mm + '-' + dd;
        this.setState({
            [name]: result,
        });

    }

    handleEdit = () => {
        this.setState((prevState) => ({
            editable: !prevState.editable,
            ...this.props.list
        }));
    }

    handleNewItemSave = () => {
        const item = this.state;
        const list = this.props.relatedList;
        

        if (this.props.listType === 'Vessel' || this.props.listType === 'Customer') {
            list.hardwares = [];
        }

        const data = { 'data': { 'item': item, 'list': list } };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/addsoftware`;
        axios.post(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.closeNewItem();
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });

    }

    handleSave = () => {
        const data = { data: this.state };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/softwareinfo/` + this.props.list.item_id;
        axios.put(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleDelete = () => {
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/softwareinfo/` + this.props.list.item_id;
        axios.delete(url,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState({
                    confirmDeleteMessage: false,
                    confirmCutMessage: false
                });
                this.props.openDeleteMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleCut = () => {
        //if (this.props.list && this.props.list.status)
        //    this.handleDelete();
        this.setState({
            confirmCutMessage: false
        });

        let list = this.props.list;
        if (!list)
            return;
        list.customers = [];
        list.vessels = [];
        list.hardwares = [];
        this.props.copyDetails('softwareDetails', list);
    }

    handlePaste = () => {
        let data = this.props.copiedList['softwareDetails'];
        this.setState((prevState) => {
            for (let key in data)
                prevState[key] = data[key];
            return (
                {
                    prevState
                }
            );
        });
        this.props.copyDetails('softwareDetails', null);
    }

    handleHeaderClick = (event) => {
        event.preventDefault();
        if (this.props.onSelection)
            this.props.onSelection(this.props.list);
    };

    closeConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: false });
    }

    openConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: true });
    }

    closeconfirmCutMessage = () => {
        this.setState({ confirmCutMessage: false });
    }

    openconfirmCutMessage = () => {
        this.setState({ confirmCutMessage: true });
    }

    SortDate = (a, b) => {
        let nameA = a.endDate.toUpperCase();
        let nameB = b.endDate.toUpperCase();
        if (nameA < nameB) {
            return 1;
        }
        if (nameA > nameB) {
            return -1;
        }
        return 0;
    }

    todayDate = () => {
        let today = new Date();
        let dd = today.getUTCDate();
        let mm = today.getUTCMonth() + 1; //January is 0!
        let yyyy = today.getUTCFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    subscriptionStatusColor = (subscription) => {
        if (!subscription)
            return false;
        if (!subscription.activeStatus ||
            !subscription.invoicedStatus ||
            subscription.paidDate.length === 0 ||
            subscription.endDate.length === 0 ||
            (subscription.endDate.toUpperCase() < this.todayDate().toUpperCase()))
            return false;
        return true;
    }

    subscriptionStatusNewColor = (subscription) => {
        if (!subscription)
            return 'grey';

        if (this.subscriptionStatusColor(subscription))
            return 'green';

        if ((subscription.endDate.toUpperCase() < this.todayDate().toUpperCase()))
            return 'red'

        return 'grey';
    }

    subscriptionStatusString = (subscription) => {
        if (!subscription)
            return '';

        if (this.subscriptionStatusColor(subscription))
            return 'Paid';

        if ((subscription.endDate.toUpperCase() < this.todayDate().toUpperCase()))
            return 'Expired'

        if (!subscription.activeStatus)
            return 'Inactive';

        if (!subscription.invoicedStatus)
            return 'Active';

        if (subscription.paidDate.length === 0)
            return 'Invoiced';
    }

    openSubscriptionDropDown = event => {
        this.setState({ subscriptionDropDown: event.currentTarget });
    };

    closeSubscriptionDropDown = () => {
        this.setState({ subscriptionDropDown: null });
    };

    passSubscriptionInfo = (item) => {
        this.closeSubscriptionDropDown();
        this.setState({ selectedSubscription: item });
    }

    addNewSubscription = () => {
        this.setState({ newSubscription: true });
    }

    closeConfirmAddMessage = () => {
        this.setState({ confirmAddMessage: false });
    }

    openConfirmAddMessage = () => {
        this.setState({ confirmAddMessage: true });
    }

    handleAddNewItem = () => {
        if (this.props.multipleHardware && (this.props.listType === 'Vessel' || this.props.listType === 'Customer')) {
            this.openConfirmAddMessage();
            return;
        }

        this.props.addNewItem();
    }

    handleConfirmAddNewItem = () => {
        this.closeConfirmAddMessage();
        this.props.addNewItem();
    }

    render() {
        const { classes } = this.props;
        let list = this.props.list;

        let addButton = (this.props && this.props.listType !== 'Software' &&
            <Tooltip title="Add New" aria-label="Add New">
            <IconButton onClick={this.handleAddNewItem}>
                    <AddIcon color='action' />
                </IconButton>
            </Tooltip>
        );

        let actionItem1 = (list && list.status &&
            <div>
                <Tooltip title="Edit" aria-label="Edit">
                    <IconButton onClick={this.handleEdit}>
                        <EditIcon color={this.state.editable ? 'primary' : 'action'} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete" aria-label="Delete">
                    <IconButton onClick={this.openConfirmDeleteMessage}>
                        <DeleteIcon color='action' />
                    </IconButton>
                </Tooltip>
                {addButton}
                <Tooltip title="Move" aria-label="Move">
                    <IconButton onClick={this.openconfirmCutMessage}>
                        <ForwardIcon color='action' />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Comment" aria-label="Comment">
                    <IconButton
                        onClick={this.handleOpenComments}
                        color="primary">
                        <Badge badgeContent={list.comments ? list.comments.length : 0} color="secondary">
                            <CommentIcon style={{ color: '#0071BC' }}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>);

        let actionItem2 = (this.props.newItem &&
            <IconButton onClick={this.handlePaste}>
                <img src={PasteIcon} alt="Paste" />
            </IconButton>);

        let actionItem3 = (!list &&
            <Tooltip title="Add New" aria-label="Add New">
                <IconButton onClick={this.handleAddNewItem}>
                    <AddIcon color='action' />
                </IconButton>
            </Tooltip>
        );

        let subscriptionList = list && list.subscriptions && list.subscriptions.sort(this.SortDate);
        let latestSubscription = subscriptionList && subscriptionList[0];
        //let subscriptionStatusColor = this.subscriptionStatusColor(latestSubscription);
        let subscriptionStatusColor = this.subscriptionStatusNewColor(latestSubscription);
        let subscriptionStatusString = this.subscriptionStatusString(latestSubscription);
        let subscriptionString = (latestSubscription ? (latestSubscription.startDate + ' to ' + (latestSubscription.endDate ? latestSubscription.endDate : '---')) : 'No Subscription') + (latestSubscription ? (' (' + subscriptionStatusString + ')') : '');
        let subscriptionTitleString = latestSubscription ? (' (' + subscriptionStatusString + ')') : '';
        return (
            <div>
                <ConfirmMessage message='Deleting Item' fullMessage='Press Continue to confirm deletion or press Cancel for aborting.' open={this.state.confirmDeleteMessage} handleClose={this.closeConfirmDeleteMessage} handleConfirmed={this.handleDelete} />
                <ConfirmMessage message='Cutting Item' fullMessage='Press Continue to confirm cut from here. You can paste these details in other similar item.' open={this.state.confirmCutMessage} handleClose={this.closeconfirmCutMessage} handleConfirmed={this.handleCut} />
                <ConfirmMessage message='Adding New Item' fullMessage={<div><p>Hardware not selected, so the software entry will attached to vessel only.</p><p>Select a hardware entry before adding new software to attach to hardware as well. </p></div>} open={this.state.confirmAddMessage} handleClose={this.closeConfirmAddMessage} handleConfirmed={this.handleConfirmAddNewItem} />

                {this.state.selectedSubscription &&
                    <Dialog
                        open={this.state.selectedSubscription != null}
                        onClose={() => { this.setState({ selectedSubscription: null }) }}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '50em',
                            position: 'fixed',
                            top: '10%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                            <SubscriptionCard list={this.state.selectedSubscription} openUnAuthMessage={this.props.openUnAuthMessage} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} classes={classes} />
                        </DialogContent>
                    </Dialog>
                }

                {this.state.newSubscription &&
                    <Dialog
                        open={this.state.newSubscription}
                        onClose={() => { this.setState({ newSubscription: false }) }}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '50em',
                            position: 'fixed',
                            top: '10%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                            <SubscriptionCard newItem={true} softwareId={list ? list.item_id : null} openUnAuthMessage={this.props.openUnAuthMessage} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} classes={classes} closeNewItem={() => { this.setState({ newSubscription: false }) }} />
                        </DialogContent>
                    </Dialog>
                }

                <Dialog
                    open={this.state.commentShown}
                    onClose={() => { this.setState({ commentShown: false }) }}
                >
                    <DialogContent>
                        <CommentModule type='Software' list={list ? list.comments : null} itemId={list ? list.item_id : null} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={() => { this.setState({ commentShown: false }) }} openSaveMessage={this.props.openSaveMessage} />
                    </DialogContent>
                </Dialog>

                <Card className={this.props.listType === 'Software' ? classes.selectedCard : ''}>
                    <CardHeader
                        className={classes.InfoCardHeader}
                        avatar={
                            <SoftwareIcon />
                        }
                        title={list ? <div className={classes.clickableHeader} onClick={this.handleHeaderClick}>{list.licenseId ? list.licenseId : 'No license number '}{(!this.state.status && !this.props.newItem) ? ' (Deleted)' : ''}{subscriptionTitleString}</div> : 'No software registered.'}
                        subheader={<div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Software</div>}
                        action={actionItem1 ||
                            actionItem2 ||
                            actionItem3
                        }
                        style={{ height: ((window.innerHeight - 55) / 100) * 5.5 }}
                    />
                    {(list || this.props.newItem) &&
                        <CardContent className={!this.state.status && !this.props.newItem ? 'deletedItem' : ''}>
                            <div className={this.state.editable ? classes.InfoCardContentEditable : classes.InfoCardContent}
                            style={this.state.editable ? { height: ((window.innerHeight - 55) / 100) * 35.4, paddingTop: 5 } : { height: ((window.innerHeight - 55) / 100) * 38, paddingTop: 5 }}
                            >
                            {!this.props.newItem &&
                                    <div>
                                <MuiThemeProvider
                                    theme={
                                        {
                                                ...defaultTheme,
                                                palette: {
                                                    ...defaultTheme.palette,
                                                    primary: {
                                                        main: '#43bf5f',
                                                        contrastText: '#fff',
                                                    },
                                                    secondary: {
                                                        main: '#ff655a',
                                                        contrastText: '#fff',
                                                    },
                                                },
                                            }
                                    }
                                >
                                        <Chip
                                        avatar={<Avatar className={subscriptionStatusColor === 'green' ? classes.greenAvtar : (subscriptionStatusColor === 'red' ? classes.redAvtar : classes.greyAvtar)}>S</Avatar>}
                                            label={subscriptionString}
                                            aria-owns={this.state.subscriptionDropDown ? 'subscription-menu' : undefined}
                                            aria-haspopup="true"
                                            onClick={this.openSubscriptionDropDown}
                                            color={subscriptionStatusColor === 'green' ? 'primary' : (subscriptionStatusColor === 'red' ? 'secondary' : 'default')}
                                            style={subscriptionStatusColor === 'grey' ? { marginBottom: '1.1em', backgroundColor: '#efefef', color: 'rgba(0, 0, 0, 0.87)' } : { marginBottom: '1.1em'}}
                                    />
                                    </MuiThemeProvider>
                                    </div>
                                }
                                <Menu
                                    id="subscription-menu"
                                    anchorEl={this.state.subscriptionDropDown}
                                    open={Boolean(this.state.subscriptionDropDown)}
                                    onClose={this.closeSubscriptionDropDown}
                                >
                                    {subscriptionList && subscriptionList.map(subscription => (
                                        <MenuItem key={subscription.item_id} onClick={() => { this.passSubscriptionInfo(subscription) }}>
                                            {subscription.startDate + ' to ' + (subscription.endDate ? subscription.endDate : '---')}
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={this.addNewSubscription}>Add New</MenuItem>
                                </Menu>
                                <Grid container spacing={8}>
                                    <Grid item xs={9} md={6}>
                                        <TextField
                                            className={classes.licenseId}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="licenseId"
                                            label={<div className={classes.LabelValue}>License Id</div>}
                                            value={this.state.licenseId ? this.state.licenseId : ' '}
                                            onChange={this.handleChange('licenseId')}
                                            margin="none"
                                            variant="outlined"
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6}>
                                        <TextField
                                            className={classes.softwaretecdisType}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="tecdisType"
                                            select
                                            label={<div className={classes.LabelValue}>Software type</div>}
                                            value={this.state.tecdisType ? this.state.tecdisType : 'None'}
                                            onChange={this.handleChange('tecdisType')}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            <MenuItem key='1' value={'None'}>None</MenuItem>
                                            <MenuItem key='2' value={'TECDIS'}>TECDIS</MenuItem>
                                            <MenuItem key='3' value={'TELchart ECS'}>TELchart ECS</MenuItem>
                                            <MenuItem key='4' value={'TELchart V3'}>TELchart V3</MenuItem>
                                            <MenuItem key='5' value={'TELchart Older'}>TELchart Older</MenuItem>
                                            <MenuItem key='6' value={'TelScope'}>TelScope</MenuItem>
                                            <MenuItem key='7' value={'TelCare'}>TelCare</MenuItem>
                                            <MenuItem key='8' value={'TELchart V'}>TELchart V</MenuItem>
                                            <MenuItem key='9' value={'TELchart V3 Lite'}>TELchart V3 Lite</MenuItem>
                                            <MenuItem key='10' value={'TELchart ECS Lite'}>TELchart ECS Lite</MenuItem>
                                            <MenuItem key='11' value={'TELchart Dos Lite'}>TELchart Dos Lite</MenuItem>
                                            <MenuItem key='12' value={'TELchart Windows Raster Lite'}>TELchart Windows Raster Lite</MenuItem>
                                            <MenuItem key='13' value={'TELchart Windows Vektor Lite'}>TELchart Windows Vektor Lite</MenuItem>
                                            <MenuItem key='14' value={'TELchart Dos Sjark'}>TELchart Dos Sjark</MenuItem>
                                            <MenuItem key='15' value={'TELchart Sjark V'}>TELchart Sjark V</MenuItem>
                                            <MenuItem key='16' value={'TELchart Sjark V'}>TELchart Sjark V</MenuItem>
                                            <MenuItem key='17' value={'COASTAL'}>COASTAL</MenuItem>
                                            <MenuItem key='18' value={'Conning'}>Conning</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9} md={6}>
                                        {!this.state.editable &&
                                            <TextField
                                                className={classes.DateText}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                    }
                                                }}
                                                disabled={true}
                                                id="purchaseDate"
                                                label={<div className={classes.LabelValue}>Delivery Date</div>}
                                                value={this.state.purchaseDate ? this.state.purchaseDate : ' '}
                                                margin="none"
                                                variant="outlined"
                                            />
                                        }
                                        {this.state.editable &&
                                            <div className={classes.DateContainer}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.Date
                                                            }
                                                        }}
                                                fullWidth
                                                keyboard
                                                        disabled={!this.state.editable}
                                                        id="purchaseDate"
                                                        label={<div className={classes.LabelValue}>Delivery Date</div>}
                                                        value={this.state.purchaseDate ? new Date(this.state.purchaseDate) : null}
                                                        onChange={this.handleDateChange('purchaseDate')}
                                                        margin="none"
                                                        variant="outlined"
                                                        format='yyyy-MM-dd'
                                                        allowKeyboardControl={true}
                                                        invalidDateMessage=''
                                                        clearable={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={9} md={6}>
                                        {!this.state.editable &&
                                            <TextField
                                                className={classes.DateText}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                    }
                                                }}
                                                disabled={true}
                                                id="installDate"
                                                label={<div className={classes.LabelValue}>Installation Date</div>}
                                                value={this.state.installDate ? this.state.installDate : ' '}
                                                margin="none"
                                                variant="outlined"
                                            />
                                        }
                                        {this.state.editable &&
                                            <div className={classes.DateContainer}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.Date
                                                            }
                                                }}
                                                fullWidth
                                                        keyboard
                                                        disabled={!this.state.editable}
                                                        id="installDate"
                                                        label={<div className={classes.LabelValue}>Installation Date</div>}
                                                        value={this.state.installDate ? new Date(this.state.installDate) : null}
                                                        onChange={this.handleDateChange('installDate')}
                                                        margin="none"
                                                        variant="outlined"
                                                        format='yyyy-MM-dd'
                                                        allowKeyboardControl={true}
                                                        invalidDateMessage=''
                                                        clearable={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <div className="softwareDistributorFieldContainer" disabled={!this.state.editable}>
                                            <label className='DistributorFieldLabel' disabled={!this.state.editable} >Distributor</label>
                                            <div className='DistributorFieldDiv'>
                                                <input
                                                    className='DistributorField'
                                                    type="text"
                                                    list="data"
                                                    id="distributor"
                                                    value={this.state.distributor ? this.state.distributor : ' '}
                                                    disabled={!this.state.editable}
                                                    onChange={this.handleChange('distributor')} />

                                                <datalist id="data">
                                                    <option value='Furuno Norge' />
                                                    <option value='Telko International' />
                                                </datalist>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.softwareDealer}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="dealer"
                                            label={<div className={classes.LabelValue}>Dealer</div>}
                                            value={this.state.dealer ? this.state.dealer : ' '}
                                            onChange={this.handleChange('dealer')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            className={classes.description}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="description"
                                            label={<div className={classes.LabelValue}>Notes</div>}
                                            multiline
                                            rowsMax="2"
                                            value={this.state.description ? this.state.description : ' '}
                                            onChange={this.handleChange('description')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={4} >
                                        <TextField
                                            className={classes.versionNumber}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="versionNumber"
                                            label={<div className={classes.LabelValue}>Version</div>}
                                            value={this.state.versionNumber ? this.state.versionNumber : ' '}
                                            onChange={this.handleChange('versionNumber')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    }
                    {this.state.editable &&
                        <CardActions className={classes.InfoCardAction}
                            style={{ height: ((window.innerHeight - 55) / 100) * 3.5, paddingLeft: '41%' }}
                        >
                            <Tooltip title="Save" aria-label="Save" >
                                <IconButton onClick={this.props.newItem ? this.handleNewItemSave : this.handleSave}>
                                    <SaveIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" aria-label="Cancel">
                                <IconButton onClick={this.props.newItem ? this.props.closeNewItem : this.handleEdit}>
                                    <CancelIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    }
                </Card>
            </div>);
    }
}

class VesselCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.list,
            confirmDeleteMessage: false,
            confirmCutMessage: false,
            editable: props.newItem,
            commentShown: false
        };
    }

    handleOpenComments = event => {
        this.setState({ commentShown: true });
    };

    handleChange = name => event => {
        if (event.target.value.length) {
            this.setState({
                [name]: event.target.value.trimStart()
            });
        }
        else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    handleEdit = () => {
        this.setState((prevState) => ({
            editable: !prevState.editable,
            ...this.props.list
        }));
    }

    handleNewItemSave = () => {
        const item = this.state;
        const list = this.props.relatedList;
        if (this.props.listType === 'Customer') {
            list.softwares = [];
            list.hardwares = [];
        }

        const data = { 'data': { 'item': item, 'list': list } };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/addvessel`;
        axios.post(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.closeNewItem();
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });

    }

    handleSave = () => {
        const data = { data: this.state };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/vesselinfo/` + this.props.list.item_id;
        axios.put(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleDelete = () => {
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/vesselinfo/` + this.props.list.item_id;
        axios.delete(url,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState({
                    confirmDeleteMessage: false,
                    confirmCutMessage: false
                });
                this.props.openDeleteMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleCut = () => {
        //if (this.props.list && this.props.list.status)
        //    this.handleDelete();
        this.setState({
            confirmCutMessage: false
        });
        this.props.copyDetails('vesselDetails', this.props.list);
    }

    handlePaste = () => {
        let data = this.props.copiedList['vesselDetails'];
        this.setState((prevState) => {
            for (let key in data)
                prevState[key] = data[key];
            return (
                {
                    prevState
                }
            );
        });
        this.props.copyDetails('vesselDetails', null);
    }

    handleHeaderClick = (event) => {
        event.preventDefault();
        if (this.props.onSelection)
            this.props.onSelection(this.props.list);
    };

    closeConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: false });
    }

    openConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: true });
    }

    closeconfirmCutMessage = () => {
        this.setState({ confirmCutMessage: false });
    }

    openconfirmCutMessage = () => {
        this.setState({ confirmCutMessage: true });
    }

    render() {
        const { classes } = this.props;
        let list = this.props.list;

        let addButton = ((!list || (this.props && this.props.listType === 'Customer')) &&
            <Tooltip title="Add New" aria-label="Add New">
                <IconButton onClick={this.props.addNewItem}>
                    <AddIcon color='action' />
                </IconButton>
            </Tooltip>
        );

        let actionItem1 = (list && list.status &&
            <div>
                <Tooltip title="Edit" aria-label="Edit">
                    <IconButton onClick={this.handleEdit}>
                        <EditIcon color={this.state.editable ? 'primary' : 'action'} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete" aria-label="Delete">
                    <IconButton onClick={this.openConfirmDeleteMessage}>
                        <DeleteIcon color='action' />
                    </IconButton>
                </Tooltip>
                {addButton}
                <Tooltip title="Move" aria-label="Move">
                    <IconButton onClick={this.openconfirmCutMessage}>
                        <ForwardIcon color='action' />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Comment" aria-label="Comment">
                    <IconButton
                        onClick={this.handleOpenComments}
                        color="primary">
                        <Badge badgeContent={list.comments ? list.comments.length : 0} color="secondary">
                            <CommentIcon style={{ color: '#0071BC' }}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>);

        let actionItem2 = ((this.props.newItem || (this.props.relatedList && this.props.relatedList.type === 'Customer')) &&
            <IconButton onClick={this.handlePaste}>
                <img src={PasteIcon} alt="Paste" />
            </IconButton>);

        let actionItem3 = (!list &&
            <Tooltip title="Add New" aria-label="Add New">
                <IconButton onClick={this.props.addNewItem}>
                    <AddIcon color='action' />
                </IconButton>
            </Tooltip>
        );

        return (
            <div>
                <ConfirmMessage message='Deleting Item' fullMessage='Press Continue to confirm deletion or press Cancel for aborting.' open={this.state.confirmDeleteMessage} handleClose={this.closeConfirmDeleteMessage} handleConfirmed={this.handleDelete} />
                <ConfirmMessage message='Cutting Item' fullMessage='Press Continue to confirm cut from here. You can paste these details in other similar item.' open={this.state.confirmCutMessage} handleClose={this.closeconfirmCutMessage} handleConfirmed={this.handleCut} />

                <Dialog
                    open={this.state.commentShown}
                    onClose={() => { this.setState({ commentShown: false }) }}
                >
                    <DialogContent>
                        <CommentModule type='Vessel' list={list ? list.comments : null} itemId={list ? list.item_id : null} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={() => { this.setState({ commentShown: false }) }} openSaveMessage={this.props.openSaveMessage} />
                    </DialogContent>
                </Dialog>

                <Card className={this.props.listType === 'Vessel' ? classes.selectedCard : ''}>
                    <CardHeader
                        className={classes.InfoCardHeader}
                        avatar={
                            <VesselIcon />
                        }
                        title={list ? <div className={classes.clickableHeader} onClick={this.handleHeaderClick}>{list.vesselName ? list.vesselName : 'No vessel name '}{(!this.state.status && !this.props.newItem) ? ' (Deleted)' : ''}</div> : 'No vessel registered.'}
                        subheader={<div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Vessel</div>}
                        action={actionItem1 ||
                            actionItem2 ||
                            actionItem3
                        }
                        style={{ height: ((window.innerHeight - 55) / 100) * 5.5 }}
                    />
                    {(list || this.props.newItem) &&
                        <CardContent className={!this.state.status && !this.props.newItem ? 'deletedItem' : ''}>
                            <div className={this.state.editable ? classes.InfoCardContentEditable : classes.InfoCardContent}
                            style={this.state.editable ? { height: ((window.innerHeight - 55) / 100) * 35.4, paddingTop: 5 } : { height: ((window.innerHeight - 55) / 100) * 38, paddingTop: 5 }}
                            >
                            <Grid container spacing={8}>
                                <Grid item xs={9} md={6} >
                                    <TextField
                                        className={classes.vesselName}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="vesselName"
                                    label={<div className={classes.LabelValue}>Name</div>}
                                    value={this.state.vesselName ? this.state.vesselName : ' '}
                                    onChange={this.handleChange('vesselName')}
                                    margin="none"
                                    variant="outlined"
                                    autoFocus={true}
                                    />
                                    </Grid>
                                <Grid item xs={9} md={6} >
                                        <TextField
                                    className={classes.vesselSerialNumber}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="vesselSerialNumber"
                                    label={<div className={classes.LabelValue}>IMO Number</div>}
                                    value={this.state.vesselSerialNumber ? this.state.vesselSerialNumber : ' '}
                                    onChange={this.handleChange('vesselSerialNumber')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} lg={4}>
                                <TextField
                                    className={classes.vesselType}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="vesselType"
                                    label={<div className={classes.LabelValue}>Type of Vessel</div>}
                                    value={this.state.vesselType ? this.state.vesselType : ' '}
                                    onChange={this.handleChange('vesselType')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} lg={4}>
                                <TextField
                                    className={classes.mmsiNo}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="mmsiNo"
                                    label={<div className={classes.LabelValue}>MMSI</div>}
                                    value={this.state.mmsiNo ? this.state.mmsiNo : ' '}
                                    onChange={this.handleChange('mmsiNo')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} lg={4}>
                                <TextField
                                    className={classes.callSign}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="callSign"
                                    label={<div className={classes.LabelValue}>Call Sign</div>}
                                    value={this.state.callSign ? this.state.callSign : ' '}
                                    onChange={this.handleChange('callSign')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} >
                                <TextField
                                    className={classes.port}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="port"
                                    label={<div className={classes.LabelValue}>Port</div>}
                                    value={this.state.port ? this.state.port : ' '}
                                    onChange={this.handleChange('port')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} >
                                <TextField
                                    className={classes.flag}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="flag"
                                    label={<div className={classes.LabelValue}>Flag</div>}
                                    value={this.state.flag ? this.state.flag : ' '}
                                    onChange={this.handleChange('flag')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} >
                                <TextField
                                    className={classes.yard}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="yard"
                                    label={<div className={classes.LabelValue}>Yard</div>}
                                    value={this.state.yard ? this.state.yard : ' '}
                                    onChange={this.handleChange('yard')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} >
                                <TextField
                                    className={classes.buildNo}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="buildNo"
                                    label={<div className={classes.LabelValue}>Yard Number</div>}
                                    value={this.state.buildNo ? this.state.buildNo : ' '}
                                    onChange={this.handleChange('buildNo')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} >
                                <TextField
                                    className={classes.invoiceAddress}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="invoiceAddress"
                                    label={<div className={classes.LabelValue}>Invoice Address</div>}
                                    value={this.state.invoiceAddress ? this.state.invoiceAddress : ' '}
                                    onChange={this.handleChange('invoiceAddress')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} >
                                <TextField
                                    className={classes.invoiceEmail}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="invoiceEmail"
                                    label={<div className={classes.LabelValue}>Invoice Email</div>}
                                    value={this.state.invoiceEmail ? this.state.invoiceEmail : ' '}
                                    onChange={this.handleChange('invoiceEmail')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} >
                                <TextField
                                    className={classes.licenseAddress}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="licenseAddress"
                                    label={<div className={classes.LabelValue}>License Address</div>}
                                    value={this.state.licenseAddress ? this.state.licenseAddress : ' '}
                                    onChange={this.handleChange('licenseAddress')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={9} md={6} >
                                <TextField
                                    className={classes.licenseEmail}
                                        fullWidth
                                    InputProps={{
                                        classes: {
                                            input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                        }
                                    }}
                                    disabled={!this.state.editable}
                                    id="licenseEmail"
                                    label={<div className={classes.LabelValue}>License Email</div>}
                                    value={this.state.licenseEmail ? this.state.licenseEmail : ' '}
                                    onChange={this.handleChange('licenseEmail')}
                                    margin="none"
                                    variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            </div>
                        </CardContent>
                    }
                    {this.state.editable &&
                        <CardActions className={classes.InfoCardAction}
                            style={{ height: ((window.innerHeight - 55) / 100) * 3.5, paddingLeft: '41%' }}
                        >
                            <Tooltip title="Save" aria-label="Save" >
                                <IconButton onClick={this.props.newItem ? this.handleNewItemSave : this.handleSave}>
                                    <SaveIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" aria-label="Cancel">
                                <IconButton onClick={this.props.newItem ? this.props.closeNewItem : this.handleEdit}>
                                    <CancelIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    }
                </Card>
            </div>);
    }
}

class CustomerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.list,
            confirmDeleteMessage: false,
            confirmCutMessage: false,
            editable: props.newItem,
            commentShown: false
        };
    }

    handleOpenComments = event => {
        this.setState({ commentShown: true });
    };

    handleChange = name => event => {
        if (event.target.value.length) {
            this.setState({
                [name]: event.target.value.trimStart()
            });
        }
        else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    handleEdit = () => {
        this.setState((prevState) => ({
            editable: !prevState.editable,
            ...this.props.list
        }));
    }

    handleNewItemSave = () => {
        const item = this.state;
        const list = this.props.relatedList;
        const data = { 'data': { 'item': item, 'list': list } };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/addcustomer`;
        axios.post(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.closeNewItem();
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });

    }

    handleSave = () => {
        const data = { data: this.state };
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/customerinfo/` + this.props.list.item_id;
        axios.put(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        editable: false
                    }));
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleDelete = () => {
        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/customerinfo/` + this.props.list.item_id;
        axios.delete(url,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState({
                    confirmDeleteMessage: false,
                    confirmCutMessage: false
                });
                this.props.openDeleteMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.props.openUnAuthMessage();
            });
    }

    handleCut = () => {
        //if (this.props.list && this.props.list.status)
        //    this.handleDelete();
        this.setState({
            confirmCutMessage: false
        });
        this.props.copyDetails('customerDetails', this.props.list);
    }

    handlePaste = () => {
        let data = this.props.copiedList['customerDetails'];
        this.setState((prevState) => {
            for (let key in data)
                prevState[key] = data[key];
            return (
                {
                    prevState
                }
            );
        });
        this.props.copyDetails('customerDetails', null);
    }

    handleHeaderClick = (event) => {
        event.preventDefault();
        if (this.props.onSelection)
            this.props.onSelection(this.props.list);
    };

    closeConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: false });
    }

    openConfirmDeleteMessage = () => {
        this.setState({ confirmDeleteMessage: true });
    }

    closeconfirmCutMessage = () => {
        this.setState({ confirmCutMessage: false });
    }

    openconfirmCutMessage = () => {
        this.setState({ confirmCutMessage: true });
    }

    render() {
        const { classes } = this.props;
        let list = this.props.list;

        let addButton = (this.props &&
            this.props.listType !== 'Customer' &&
            !list &&
            <Tooltip title="Add New" aria-label="Add New">
                <IconButton onClick={this.props.addNewItem}>
                    <AddIcon color='action' />
                </IconButton>
            </Tooltip>
        );

        let actionItem1 = (list && list.status &&
            <div>
                <Tooltip title="Edit" aria-label="Edit">
                    <IconButton onClick={this.handleEdit}>
                        <EditIcon color={this.state.editable ? 'primary' : 'action'} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete" aria-label="Delete">
                    <IconButton onClick={this.openConfirmDeleteMessage}>
                        <DeleteIcon color='action' />
                    </IconButton>
                </Tooltip>
                {addButton}
                <Tooltip title="Comment" aria-label="Comment">
                    <IconButton
                        onClick={this.handleOpenComments}
                        color="primary">
                        <Badge badgeContent={list.comments ? list.comments.length : 0} color="secondary">
                            <CommentIcon style={{ color: '#0071BC' }}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>);

        let actionItem3 = (!list && !this.props.newItem &&
            <Tooltip title="Add New" aria-label="Add New">
                <IconButton onClick={this.props.addNewItem}>
                    <AddIcon color='action' />
                </IconButton>
            </Tooltip>
        );

        return (
            <div>
                <ConfirmMessage message='Deleting Item' fullMessage='Press Continue to confirm deletion or press Cancel for aborting.' open={this.state.confirmDeleteMessage} handleClose={this.closeConfirmDeleteMessage} handleConfirmed={this.handleDelete} />
                <ConfirmMessage message='Cutting Item' fullMessage='Press Continue to confirm cut from here. You can paste these details in other similar item.' open={this.state.confirmCutMessage} handleClose={this.closeconfirmCutMessage} handleConfirmed={this.handleCut} />

                <Dialog
                    open={this.state.commentShown}
                    onClose={() => { this.setState({ commentShown: false }) }}
                >
                    <DialogContent>
                        <CommentModule type='Customer' list={list ? list.comments : null} itemId={list ? list.item_id : null} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={() => { this.setState({ commentShown: false }) }} openSaveMessage={this.props.openSaveMessage} />
                    </DialogContent>
                </Dialog>

                <Card className={this.props.listType === 'Customer' ? classes.selectedCard : ''}>
                    <CardHeader
                        className={classes.InfoCardHeader}
                        avatar={
                            <CustomerIcon />
                        }
                        title={list ? <div className={classes.clickableHeader} onClick={this.handleHeaderClick}>{list.name ? list.name : 'No customer name '} {(!this.state.status && !this.props.newItem) ? ' (Deleted)' : ''}</div> : 'No customer registered.'}
                        subheader={<div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Customer</div>}
                        action={actionItem1 ||
                            actionItem3
                        }
                        style={{ height: ((window.innerHeight - 55) / 100) * 5.5 }}
                    />
                    {(list || this.props.newItem) &&
                        <CardContent className={!this.state.status && !this.props.newItem ? 'deletedItem' : ''}>
                            <div className={this.state.editable ? classes.InfoCardContentEditable : classes.InfoCardContent}
                                style={this.state.editable ? this.props.popUp ? { height: ((window.innerHeight - 55) / 100) * 37.4, paddingTop: 5 } : { height: ((window.innerHeight - 55) / 100) * 35.4, paddingTop: 5 } : { height: ((window.innerHeight - 55) / 100) * 38, paddingTop: 5 }}
                            >
                                <Grid container spacing={8}>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.name}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="name"
                                            label={<div className={classes.LabelValue}>Name</div>}
                                            value={this.state.name ? this.state.name : ' '}
                                            onChange={this.handleChange('name')}
                                            margin="none"
                                            variant="outlined"
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.address}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="address"
                                            label={<div className={classes.LabelValue}>Address</div>}
                                            value={this.state.address ? this.state.address : ' '}
                                            onChange={this.handleChange('address')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.postalCode}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="postalCode"
                                            label={<div className={classes.LabelValue}>Postal Code</div>}
                                            value={this.state.postalCode ? this.state.postalCode : ' '}
                                            onChange={this.handleChange('postalCode')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.city}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="city"
                                            label={<div className={classes.LabelValue}>City</div>}
                                            value={this.state.city ? this.state.city : ' '}
                                            onChange={this.handleChange('city')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.state}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="state"
                                            label={<div className={classes.LabelValue}>State</div>}
                                            value={this.state.state ? this.state.state : ' '}
                                            onChange={this.handleChange('state')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} lg={3}>
                                        <TextField
                                            className={classes.country}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="country"
                                            label={<div className={classes.LabelValue}>Country</div>}
                                            value={this.state.country ? this.state.country : ' '}
                                            onChange={this.handleChange('country')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.contactName}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="contactName"
                                            label={<div className={classes.LabelValue}>Contact first name</div>}
                                            value={this.state.contactName ? this.state.contactName : ' '}
                                            onChange={this.handleChange('contactName')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.contactLastName}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="contactLastName"
                                            label={<div className={classes.LabelValue}>Contact last name</div>}
                                            value={this.state.contactLastName ? this.state.contactLastName : ' '}
                                            onChange={this.handleChange('contactLastName')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.contactPhone}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="contactPhone"
                                            label={<div className={classes.LabelValue}>Contact phone</div>}
                                            value={this.state.contactPhone ? this.state.contactPhone : ' '}
                                            onChange={this.handleChange('contactPhone')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.contactEmail}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="contactEmail"
                                            label={<div className={classes.LabelValue}>Contact email</div>}
                                            value={this.state.contactEmail ? this.state.contactEmail : ' '}
                                            onChange={this.handleChange('contactEmail')}
                                            type="email"
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.invoiceAddress}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="invoiceAddress"
                                            label={<div className={classes.LabelValue}>Invoice address</div>}
                                            value={this.state.invoiceAddress ? this.state.invoiceAddress : ' '}
                                            onChange={this.handleChange('invoiceAddress')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.invoiceEmail}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="invoiceEmail"
                                            label={<div className={classes.LabelValue}>Invoice email</div>}
                                            value={this.state.invoiceEmail ? this.state.invoiceEmail : ' '}
                                            onChange={this.handleChange('invoiceEmail')}
                                            type="email"
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.licenseAddress}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="licenseAddress"
                                            label={<div className={classes.LabelValue}>License address</div>}
                                            value={this.state.licenseAddress ? this.state.licenseAddress : ' '}
                                            onChange={this.handleChange('licenseAddress')}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={6} >
                                        <TextField
                                            className={classes.licenseEmail}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: this.state.editable ? classes.EnabledInputValue : classes.DisabledInputValue
                                                }
                                            }}
                                            disabled={!this.state.editable}
                                            id="licenseEmail"
                                            label={<div className={classes.LabelValue}>License email</div>}
                                            value={this.state.licenseEmail ? this.state.licenseEmail : ' '}
                                            onChange={this.handleChange('licenseEmail')}
                                            type="email"
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    }
                    {this.state.editable &&
                        <CardActions className={classes.InfoCardAction}
                            style={{ height: ((window.innerHeight - 55) / 100) * 3.5, paddingLeft: '41%' }}
                        >
                            <Tooltip title="Save" aria-label="Save" >
                                <IconButton onClick={this.props.newItem ? this.handleNewItemSave : this.handleSave}>
                                    <SaveIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" aria-label="Cancel">
                                <IconButton onClick={this.props.newItem ? this.props.closeNewItem : this.handleEdit}>
                                    <CancelIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    }
                </Card>
            </div>);
    }
}

class InformationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        saveMessage: false,
        deleteMessage: false,
        unAuthMessage: false
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let saveMessage = this.state.saveMessage;
        this.setState({ saveMessage });
    }

    closeUnAuthMessage = () => {
        this.setState({ unAuthMessage: false });
    }

    openUnAuthMessage = () => {
        this.setState({ unAuthMessage: true });
    }

    closeSaveMessage = () => {
        this.setState({ saveMessage: false });
    }

    openSaveMessage = () => {
        this.setState({ saveMessage: true });
    }

    closeDeleteMessage = () => {
        this.setState({ deleteMessage: false });
    }

    openDeleteMessage = () => {
        this.setState({ deleteMessage: true });
    }

    
    
    render() {
        let item = this.props.list;
        let type = item && item.type;
        let item_id = item && item.item_id;

        return (
            <div style={{ height: ((window.innerHeight - 55) / 100) * 48.5}}>
                {this.state.saveMessage && <InfoMessage message='Item Saved' open={this.state.saveMessage} handleClose={this.closeSaveMessage} />}
                {this.state.deleteMessage && <InfoMessage message='Item Deleted' open={this.state.deleteMessage} handleClose={this.closeDeleteMessage} />}
                {this.state.unAuthMessage && <InfoMessage message='Not Authorised!' open={this.state.unAuthMessage} handleClose={this.closeUnAuthMessage} />}
                {this.props.type === 'Hardware' &&
                    <HardwareCard key={type + "_" + item_id} list={this.props.list} classes={this.props.classes} listType={this.props.listType} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} openSaveMessage={this.openSaveMessage} openDeleteMessage={this.openDeleteMessage} openUnAuthMessage={this.openUnAuthMessage} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.props.addNewItem} newItem={this.props.newItem} closeNewItem={this.props.closeNewItem} relatedList={this.props.relatedList} copiedList={this.props.copiedList} />}

                {this.props.type === 'Software' &&
                    <SoftwareCard key={type + "_" + item_id} list={this.props.list} classes={this.props.classes} listType={this.props.listType} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} multipleHardware={this.props.multipleHardware} openSaveMessage={this.openSaveMessage} openDeleteMessage={this.openDeleteMessage} openUnAuthMessage={this.openUnAuthMessage} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.props.addNewItem} newItem={this.props.newItem} closeNewItem={this.props.closeNewItem} relatedList={this.props.relatedList} copiedList={this.props.copiedList} />}

                {this.props.type === 'Vessel' &&
                    <VesselCard key={type + "_" + item_id} list={this.props.list} classes={this.props.classes} listType={this.props.listType} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} openSaveMessage={this.openSaveMessage} openDeleteMessage={this.openDeleteMessage} openUnAuthMessage={this.openUnAuthMessage} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.props.addNewItem} newItem={this.props.newItem} closeNewItem={this.props.closeNewItem} relatedList={this.props.relatedList} copiedList={this.props.copiedList} />}

                {this.props.type === 'Customer' &&
                    <CustomerCard key={type + "_" + item_id} list={this.props.list} classes={this.props.classes} popUp={this.props.popUp} listType={this.props.listType} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} openSaveMessage={this.openSaveMessage} openDeleteMessage={this.openDeleteMessage} openUnAuthMessage={this.openUnAuthMessage} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.props.addNewItem} newItem={this.props.newItem} closeNewItem={this.props.closeNewItem} relatedList={this.props.relatedList} copiedList={this.props.copiedList} />}
            </div>);
    }
}

export default withStyles(styles, { withTheme: true })(InformationCard);

