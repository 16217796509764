import React from 'react';

export default (props) => (
<svg version="1.1"
    viewBox="0 0 100 100"
	width="50px" height="50px">
  <g
     id="layer1">
            <path d="m 88.664604,78.233861 c -8.49564,-3.47026 -17.63411,-5.73529 -25.73674,-10.22812 -0.14015,-3.3663 -0.10244,-9.03337 2.03007,-10.91678 4.61975,0.17592 4.50935,-6.8025 6.55849,-9.9155 0.75378,-3.55351 -4.25785,-2.84499 -1.658,-6.46047 0.56702,-4.78337 4.27983,-9.1641 2.00762,-14.16387 -2.77084,-7.78186 -10.20405,-14.69644 -18.76137,-14.77103 -4.40758,0.78095 -9.121055,-1.00939 -13.252005,1.42074 -7.06995,3.40744 -13.383098,10.70429 -12.741886,18.96484 1.006691,3.69039 3.588946,8.64216 2.798626,11.75114 -4.89004,2.26233 1.07239,7.86624 1.36692,11.38659 0.95957,3.42576 5.33695,0.006 4.63727,4.44183 0.0768,2.55936 2.18488,6.84781 -0.0464,8.69758 -7.856078,4.25527 -16.693179,6.20955 -24.783287,9.88893 a 48,48 0 0 0 77.580692,-0.09588"
                fill={props.color} strokeWidth="0.08466667" />
    <circle
       fill="none" stroke="#000000" strokeWidth="1.0" strokeOpacity="1"
       r="48"
       cy="50"
       cx="50"/>
  </g>
</svg>);

