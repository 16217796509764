import React, { Component } from 'react';
import $ from 'jquery';
import SettingList from '../../components/settinglist/settinglist'
import TopNavBar from '../../components/top_nav_bar/top_nav_bar'


export default class UpdateUserPermission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
            cookieToken: null,
            rolePermissionList: null,
            userPermissionList: null,
            updatedPermission: null
        };
        
    }

    componentDidMount() {
	this.fetchRolePermissionList();
    }


    fetchRolePermissionList = () => {
	$.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/rolepermissions`,
            data: {
		token: this.props.loginstate.token,
		role: this.props.roleName,
		organization: 'telko',
		location: 'telko',
		allavailable: true
            },
            crossDomain: true
	}).done( (response) => {
	    console.log("response: " + response);
	    this.setState({ rolePermissionList: response });
	}).fail( (XMLHttpRequest, textStatus, errorThrown) => {
	    alert("Status: " + textStatus); alert("Error: " + errorThrown);
	});
    }

    render() {
	if (this.state.rolePermissionList)
	{
	    const rolePermissions = this.state.rolePermissionList.map( item => ( {
		resource : item.resource,
		option1 : item.from_role,
		option2 : item.read,
		option3 : item.write}) );
        return (
            <div>
                <TopNavBar loginstate={this.props.loginstate} heading='Role Management' />
                <div className="user-role-container">
                    <div>
                        <div style={{ "textAlign": "flex-end" }}></div>
                        {this.state.rolePermissionList &&
                            <SettingList tableTitle='Role Permissions'
                                objectTitle='Permissions'
                                optionTitle1='From Roles'
                                optionTitle2='Read'
                                optionTitle3='Modify'
                                option2Enabled={false}
                                option3Enabled={false}
                                showListElements={rolePermissions} />}
                    </div>
                </div>
            </div>
        );
	}

	return <div>No roles to show</div>
    }
}