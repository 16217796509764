import React, { Component, Fragment } from 'react';
import {Alert} from 'react-bootstrap';
import $ from 'jquery';

import RoleList from '../../components/rolelist/rolelist'
import UserList from '../../components/userlist/userlist'
import TopNavBar from '../../components/top_nav_bar/top_nav_bar'


import './adminmainpage.css';

export default class AdminPageComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
	    userListErrorMessage : null,
	    roleListErrorMessage : null,
            userListData: null,
            roleListData: null
        };
    }

    componentDidMount() {
	this.fetchUsers();
	this.fetchRoles();
    }

    fetchRoles = () => {
	$.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/rolelist`,
            data: {
		token: this.props.loginstate.token,
		organization: 'telko',
		location: 'telko'
            },
            crossDomain: true
	}).done( (response) => {
            this.setState( {roleListData : response});
	}).fail( (XMLHttpRequest, textStatus, errorThrown)  => {
	    if (XMLHttpRequest.status && XMLHttpRequest.status === 403)
                this.setState({ roleListErrorMessage : "Couldn't get role data. Not authorized" });
	    else if (XMLHttpRequest.status && XMLHttpRequest.status === 401)
                this.setState({ roleListErrorMessage : "Couldn't get role data. Not auhenticated." });
	    else
		this.setState({ roleListErrorMessage : "Couldn't get role data. Unknonwn fail (" + XMLHttpRequest.status + ")"});
        });
    }
    fetchUsers = () => {
	$.ajax({
	    type: "POST",
	    url: `${process.env.REACT_APP_BACKEND_URL}/userlist`,
	    data: {
		token: this.props.loginstate.token,
		organization: 'telko',
		location: 'telko'
	    },
	    crossDomain: true
	}).done( (response) => {
	    this.setState({ userListData: response });
	}).fail( (XMLHttpRequest, textStatus, errorThrown) => {
	    if (XMLHttpRequest.status && XMLHttpRequest.status === 403)
                this.setState({ userListErrorMessage : "Couldn't get user data. Not authorized" });
	    else if (XMLHttpRequest.status && XMLHttpRequest.status === 401)
                this.setState({ userListErrorMessage : "Couldn't get user data. Not authenticated." });
	    else
		this.setState({ userListErrorMessage : "Couldn't get user data. Unknonwn fail (" + XMLHttpRequest.status + ")"});
	});
    }

    render() {
        return (
            <div>
                <TopNavBar loginstate={this.props.loginstate} heading='User Management' />

                <Fragment>
                    <div>
                        {this.state.userListErrorMessage && <Alert bsStyle="danger"><p>{this.state.userListErrorMessage}</p></Alert>}
                        {this.state.userListData && <UserList showListElements={this.state.userListData} elementsShownCount="5" pagetorefer="userinfo" tableTitle="Users" />}
                    </div>
                    <div>
                        {this.state.roleListErrorMessage && <Alert bsStyle="danger"><p>{this.state.roleListErrorMessage}</p></Alert>}
                        {this.state.roleListData && <RoleList showListElements={this.state.roleListData} elementsShownCount="5" tableTitle="Roles" pagetorefer="roleinfo" />}

                    </div>
                </Fragment>
            </div>
        );
    }
}
