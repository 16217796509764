import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import InfoMessage from '../infomessage/infomessage'

const styles = theme => ({
    snackbar: {
        margin: 5,
        backgroundColor: '#F3F3F3',
        color: 'black' 
    },
    newComment: {
        marginTop: 10,
    }
});

class CommentModule extends Component {
    state = {
        newComment: null,
        unAuthMessage: false
    };

    closeUnAuthMessage = () => {
        this.setState({ unAuthMessage: false });
    }

    openUnAuthMessage = () => {
        this.setState({ unAuthMessage: true });
    }

    handleChange = name => event => {
        if (event.target.value.length) {
            this.setState({
                [name]: event.target.value.trimStart()
            });
        }
        else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    handleNewCommentSave = () => {
        const item = { 'commentString': this.state.newComment };
        const addedTo = {
            'id': this.props.itemId + '',
            'type': this.props.type
        };
        const data = { 'data': { 'item': item, 'addedTo': addedTo } };

        const headers = { Authorization: this.props.loginstate.token };
        const url = `${process.env.REACT_APP_BACKEND_URL}/addcomment`;
        axios.post(url,
            data,
            { headers }
        ).then(response => {
            if (+response.status === 200) {
                this.props.fetchItineraryList();
                this.setState((prevState) => (
                    {
                        newComment: null
                    }));
                this.props.openSaveMessage();
            }
        })
            .catch(error => {
                if (error.response && error.response.status === 401)
                    this.openUnAuthMessage();
            });

    }

    formaDateTime = (list) => {
        for (let key in list) {
            let date = list[key].creationTime.split('-');
            let dateTime = date[0] + '/' + date[1] + '/' + date[2] + ' ' + date[3] + ':' + date[4] + ':' + date[5] + '';
            list[key]['formattedDate'] = dateTime;
        }
        return list;
    }

    sortDate = (a, b) => {
        let nameA = a.creationTime.toUpperCase();
        let nameB = b.creationTime.toUpperCase();
        if (nameA < nameB) {
            return 1;
        }
        if (nameA > nameB) {
            return -1;
        }
        return 0;
    }

    render() {
        let list = this.props.list;
        const { classes } = this.props;

        list = list.sort(this.sortDate);
        list = this.formaDateTime(list);
        return (
            <div>
                <div className='CommentCardHeader'>
                    Comments
                </div>
                <div className='CommentCardContent'>
                <InfoMessage message='Not Authorised!' open={this.state.unAuthMessage} handleClose={this.closeUnAuthMessage} />
                {list && list.map(item => (
                    <SnackbarContent
                        key={item.item_id}
                        className={classes.snackbar}
                        message={item.commentString.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                        action={item.nameOfUser ? (item.nameOfUser + ' (' + item.formattedDate + ')') : ''}
                    />
                ))}
                    </div>
                <Grid container spacing={24}
                    className={classes.newComment}
                >
                    <Grid item xs={10}>
                        <TextField
                            id="newComment"
                            label="New Comment"
                            value={this.state.newComment ? this.state.newComment : ' '}
                            onChange={this.handleChange('newComment')}
                            margin="none"
                            variant="outlined"
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Save" aria-label="Save">
                            <IconButton onClick={this.handleNewCommentSave}>
                                <SaveIcon color='primary' />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CommentModule);
