import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Routes from "./src/router/router";
import Cookies from 'universal-cookie';
import 'react-bootstrap'

import './App.css';

class App extends Component {
    constructor(props) {
	super(props);

        const cookies = new Cookies();
        const token = cookies.get('token');
        const username = cookies.get('username');
        let lastVisitedPage = cookies.get('lastVisitedPage');

        this.state = {
            username: username,
            token: token,
            contextMenu: null,
            mainMenuItem: null,
            name_of_user: null,
            user_color: null,
            lastVisitedPage: lastVisitedPage ? lastVisitedPage : "/"
        };
    }

    updateLastViewedPage = (newPath) => {
        const cookies = new Cookies();
        cookies.set('lastVisitedPage', newPath);
        this.setState({ lastVisitedPage: newPath });
    }

    updateUserDetails = (username, token, isAuthenticated, name_of_user, user_color) => {
        this.setState({
            username: isAuthenticated ? username : null,
            token: isAuthenticated ? token : null,
            name_of_user: name_of_user,
            user_color: user_color
        });
    }

    setContextMenu = (menu) => {
	this.setState({ contextMenu : menu });
    }


    setMainMenuItem = (mainMenuItem) => {
	this.setState({ mainMenuItem });
    }

    logout = () => {
        const cookies = new Cookies();
        cookies.remove('token');
        cookies.remove('username');
        cookies.remove('name_of_user');
        cookies.remove('user_color');

        this.setState({
            username: null,
            token: null,
        });
    }

    componentDidMount() {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const name_of_user = cookies.get('name_of_user');
        const user_color = cookies.get('user_color');
        const username = cookies.get('username');
        let lastVisitedPage = cookies.get('lastVisitedPage');

        if (!lastVisitedPage || lastVisitedPage.length === 0)
            lastVisitedPage = this.state.lastVisitedPage;

        if (token && token.length > 0 && username && username.length > 0  ) {
            this.setState({
                username: username,
                token: token,
                isAuthenticated: true,
                lastVisitedPage: lastVisitedPage,
                name_of_user: name_of_user,
                user_color: user_color
            });
        }
    }

    render() {
     const isAuthenticated = this.state.username && this.state.token;
     const loginstate = {
         username: this.state.username,
         token: this.state.token,
         name_of_user: this.state.name_of_user,
         user_color: this.state.user_color,
         isAuthenticated,
         doUpdateUserDetails: this.updateUserDetails,
         setContextMenu: this.setContextMenu,
         setMainMenuItem: this.setMainMenuItem,
         updateLastViewedPage: this.updateLastViewedPage,
         doLogout: this.logout
     };

     return (
         <div className="container col-md-12" style={{ height: window.innerHeight, width: window.innerWidth}}>
             <Routes loginstate={loginstate} />
         </div>
     );
  }
}

export default withRouter(App);
