import React, { Component, Fragment } from 'react';
import { FormGroup, FormControl, ControlLabel, Modal, Button } from 'react-bootstrap';
import ConfirmationPopUp from '../../components/confirmation_popup/confirmation_popup'

import './userdetailsform.css';

class UserDetailsForm extends Component
{
    constructor(props) {
        super(props);

        const emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
        const passwordPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}/;
        const pinPattern = /^[0-9]{6,6}$/;

        this.state = {
            updatePin: false,
            updatePwd: false,
            ActivateConfirmation: false
        };

        this.validationPatterns = {
            username: emailPattern,
            email: emailPattern,
            mobilenumber: new RegExp("^[0-9]*$"),
            password: passwordPattern,
            repassword: passwordPattern,
            pin: pinPattern,
            repin: pinPattern
        }

        this.mustBeEqual = {
            repassword: "password"
        }

        this.requiredFields = {
            "username": null,
            "name": null,
            "password": null,
            "repassword": null
        }
    }

    submitActivateConfirmation = (event) => {
        event.preventDefault();
        this.setState({
            ActivateConfirmation: true
        });
    }

    cancelActivateConfirmation = () => {
        this.setState({
            ActivateConfirmation: false
        });
    }

    toggleActivation = event => {
        event.preventDefault();

        let data = {};
        Object.assign(data, this.props.initialValues);
        data['status'] = data['status'] ? 0 : 1;

        // Call back to parent
        this.props.onChange(data);
        this.setState({
            ActivateConfirmation: false
        });
    }


    handleChange = event => {
        // Clone the props
        let data = {};
        Object.assign(data, this.props.initialValues);
        data[event.target.id] = event.target.value;
        // Call back to parent
        this.props.onChange(data);
    }

    updatePwd = (event) => {
        event.preventDefault();
        this.setState({
            updatePwd: true
        });
    }

    updatePin = (event) => {
        event.preventDefault();
        this.setState({
            updatePin: true
        });
    }

    cancelUpdatePwd = (event) => {
        event.preventDefault();
        this.setState({ updatePwd: false });
    }

    cancelUpdatePin = (event) => {
        event.preventDefault();
        this.setState({ updatePin: false });
    }

    saveChanges = (event) => {
        event.preventDefault();
        console.log('hi');
        this.props.updateUserDatabase();
        this.setState({
            updatePwd: false,
            updatePin: false
        });
    }

    validateForm() {
        return (this.props.initialValues.password === this.props.initialValues.repassword && 
            this.props.initialValues.pin === this.props.initialValues.repin);
    }

    getValidationState(element) {
        const value = this.props.initialValues[element];

        if (element in this.validationPatterns) {
            // hasValidation = true;
            if (value.length > 0 && !this.validationPatterns[element].test(value))
                return "error";
        }

        if (element in this.mustBeEqual) {
            // hasValidation = true;
            if (value !== this.props.initialValues[this.mustBeEqual[element]])
                return "error";
        }

        if (element in this.requiredFields) {
            // hasValidation = true;
            if (!value)
                return "error";
        }

        return null;
        //return hasValidation ? "success" : null;
    }

    render() {

        let updatePinModal = this.state.updatePin &&
            (<div className="static-modal" id='logTableModal' draggable={false}>
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>Update Pin</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <Fragment>
                        <FormGroup controlId="pin" validationState={this.getValidationState("pin")}>
                            <ControlLabel>New Pin</ControlLabel>
                            <FormControl
                                autoFocus
                                type="password"
                                value={this.props.initialValues.pin}
                                onChange={this.handleChange}
                                />

                        </FormGroup>
                        <FormGroup
                            controlId="repin"
                            validationState={this.getValidationState("repin")} >
                            <ControlLabel>Repeat new pin</ControlLabel>
                            <FormControl
                                type="password"
                                value={this.props.initialValues.repin}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                    </Fragment>
                    </Modal.Body>

                    <Modal.Footer>
                    <div className='text-center'>
                        <Button id='savePin' onClick={((this.getValidationState("pin") ===null) &&
                            (this.getValidationState("repin") === null) &&
                            (this.props.initialValues.pin === this.props.initialValues.repin))?
                            this.saveChanges : null} >Save</Button>
                        <Button onClick={this.cancelUpdatePin} >Cancel</Button>
                        </div>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
            );

        let updatePwdModal = this.state.updatePwd &&
            (<div className="static-modal" id='logTableModal' draggable={false}>
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>Update Password</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Fragment>
                            <FormGroup controlId="password" validationState={this.getValidationState("password")}>
                                <ControlLabel>New password</ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="password"
                                    value={this.props.initialValues.password}
                                    onChange={this.handleChange}
                                />

                            </FormGroup>
                            <FormGroup
                                controlId="repassword"
                                validationState={this.getValidationState("repassword")} >
                                <ControlLabel>Repeat new password</ControlLabel>
                                <FormControl
                                    type="password"
                                    value={this.props.initialValues.repassword}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Fragment>
                    </Modal.Body>

                    <Modal.Footer>
                    <Button id='savePassword' onClick={((this.getValidationState("password")===null) &&
                        (this.getValidationState("repassword") === null) &&
                        (this.props.initialValues.password === this.props.initialValues.repassword))?
                        this.saveChanges : null} >Save</Button>
                    <Button onClick={this.cancelUpdatePwd} >Cancel</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>

            );

      return (
        <div className="userdetails">
	   <form onSubmit={this.handleSubmit} noValidate>

              <FormGroup controlId="username"
                 validationState={this.getValidationState("username")}>
	      <ControlLabel>Email</ControlLabel>
	      <FormControl
                disabled={this.props.disabled?true:true}
	        autoFocus
	        type="email"
	        value={this.props.initialValues.username}
	        onChange={this.handleChange}
	        autoComplete="email" />
	      </FormGroup>

              <FormGroup
	        controlId="name"
	        validationState={this.getValidationState("name")}>
	      <ControlLabel>Full name</ControlLabel>
	      <FormControl disabled={this.props.disabled} autoFocus type="text" value={this.props.initialValues.name} onChange={this.handleChange} autoComplete="name" />
	      </FormGroup>

              <FormGroup
	        controlId="email"
	        validationState={this.getValidationState("email")}>
	      <ControlLabel>Alternate email</ControlLabel>
	      <FormControl  disabled={this.props.disabled} autoFocus type="email" value={this.props.initialValues.email} onChange={this.handleChange} autoComplete="email" />
	      </FormGroup>

              <FormGroup controlId="mobilenumber" validationState={this.getValidationState("mobilenumber")} >
	      <ControlLabel>Mobile number</ControlLabel>
	      <FormControl  disabled={this.props.disabled} autoFocus type="text" value={this.props.initialValues.mobilenumber} onChange={this.handleChange} autoComplete="tel" />
	      </FormGroup>

              <FormGroup controlId="address" validationState={this.getValidationState("address")} >
	      <ControlLabel>Address</ControlLabel>
	      <FormControl  disabled={this.props.disabled} autoFocus type="text" value={this.props.initialValues.address} onChange={this.handleChange} autoComplete="address-line1" />
	      </FormGroup>

              <FormGroup controlId="country" validationState={this.getValidationState("country")} >
	      <ControlLabel>Country</ControlLabel>
	      <FormControl  disabled={this.props.disabled} autoFocus type="text" value={this.props.initialValues.country} onChange={this.handleChange} autoComplete="country-name" />
              </FormGroup>

                  <div className='text-center'>
                      <button className='btn btn-default' disabled={this.props.disabled} name="active" onClick={this.submitActivateConfirmation} >
                      {this.props.disabled ? (this.props.initialValues.status ? 'Activated' : 'Deactivated') : (this.props.initialValues.status ? 'Deactivate User' : 'Activate User')}
                      </button><span> </span>
                      {!this.props.disabled &&
                          <button className='btn btn-default' disabled={this.props.disabled} name="updatePwd" onClick={this.updatePwd} >
                              Update Password
                      </button>
                      }
                          <span> </span>
                          {!this.props.disabled &&
                          <button className='btn btn-default' disabled={this.props.disabled} name="updatePwd" onClick={this.updatePin} >
                              Update Pin
                      </button>
                          }
                  </div>

       </form>
       {updatePinModal}
       {updatePwdModal}
       {this.state.ActivateConfirmation &&
                  <ConfirmationPopUp heading='Confirmation'
                  message={(this.props.initialValues.status ? 'User will be deactivated. Click Save to save changes.' : 'User will be activated. Click Save to save changes.')}
                  confirmAction={this.toggleActivation}
                  cancelAction={this.cancelActivateConfirmation} />}
        </div>
      );
    }
}

export default UserDetailsForm;
