import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CustomerIcon from '@material-ui/icons/Person';
import VesselIcon from '@material-ui/icons/DirectionsBoat';
import SoftwareIcon from '@material-ui/icons/Memory';
import HardwareIcon from '@material-ui/icons/Computer';
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InformationCard from '../information_card/information_card'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmMessage from '../information_card/confirm_message'
import './information_list.css'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        minWidth: 120,
        height:45
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    menuSelect: {
        padding: 5,
        minWidth: 150,
        maxHeight: 50
    },
    InfoCardHeader: {
        height: '3em',
    },
    ListCardContent: {
        height: '23.5em',
        overflowY: 'auto',
    }
});

class ItemList extends Component {
    state = {
        selectedItem: null,
    };

    closeInformationCard = () => {
        this.setState({ selectedItem: null });
    }

    rmaListToString = (rmas) => {
        let result = '';
        for (let it in rmas) {
            result += (rmas[it].serialNumber) + ' ';
        }
        return result;
    }

    DesDate = (a, b) => {
        let nameA = a.updateDate.toUpperCase();
        let nameB = b.updateDate.toUpperCase();
        if (nameA < nameB) {
            return 1;
        }
        if (nameA > nameB) {
            return -1;
        }
        return 0;
    }

    AscDate = (a, b) => {
        let nameA = a.updateDate.toUpperCase();
        let nameB = b.updateDate.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    dateForamtting = (date) => {
        if (date === '')
            return '';

        let newDate = new Date(date);
        if (isNaN(newDate.getTime()))
            return '';

        let dd = newDate.getDate();
        let mm = newDate.getMonth() + 1;
        let yyyy = newDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        let result = yyyy + '-' + mm + '-' + dd;
        return result;
    }

    getStringToCompare = (item) => {
        if (item.type === 'Software')
            return item.licenseId;
        else if (item.type === 'Hardware')
            return item.hardwareSerialNumber;
        else if (item.type === 'Vessel')
            return item.vesselName;
        else if (item.type === 'Customer')
            return item.name;
        return '';
    }

    DesAlpahbet = (a, b) => {
        let nameA = this.getStringToCompare(a).toUpperCase();
        let nameB = this.getStringToCompare(b).toUpperCase();
        if (nameA < nameB) {
            return 1;
        }
        if (nameA > nameB) {
            return -1;
        }
        return 0;
    }

    AscAlpahbet = (a, b) => {
        let nameA = this.getStringToCompare(a).toUpperCase();
        let nameB = this.getStringToCompare(b).toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    SortList = (listItems, sortBy) => {
        if (sortBy === 'AscDate')
            listItems = listItems.sort(this.AscDate);
        else if (sortBy === 'DesDate') {
            listItems = listItems.sort(this.DesDate);
        }
        else if (sortBy === 'AscAlpahbet') {
            listItems = listItems.sort(this.AscAlpahbet);
        }
        else if (sortBy === 'DesAlpahbet') {
            listItems = listItems.sort(this.DesAlpahbet);
        }
        return listItems;
    }

    handleListItemClick = (event, item) => {
        event.preventDefault();
        this.setState({ selectedItem: item });
       // this.props.onSelection(item);
    };

    todayDate = () => {
        let today = new Date();
        let dd = today.getUTCDate();
        let mm = today.getUTCMonth() + 1; //January is 0!
        let yyyy = today.getUTCFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    SortDate = (a, b) => {
        let nameA = a.endDate.toUpperCase();
        let nameB = b.endDate.toUpperCase();
        if (nameA < nameB) {
            return 1;
        }
        if (nameA > nameB) {
            return -1;
        }
        return 0;
    }

    subscriptionStatusColor = (subscription) => {
        if (!subscription)
            return false;
        if (!subscription.activeStatus ||
            !subscription.invoicedStatus ||
            subscription.paidDate.length === 0 ||
            subscription.endDate.length === 0 ||
            (subscription.endDate.toUpperCase() < this.todayDate().toUpperCase()))
            return false;
        return true;
    }

    subscriptionStatusString = (subscription) => {
        if (!subscription)
            return '';

        if (this.subscriptionStatusColor(subscription))
            return 'Paid';

        if ((subscription.endDate.toUpperCase() < this.todayDate().toUpperCase())) {
            return 'Expired'
        }

        if (!subscription.activeStatus)
            return 'Inactive';

        if (!subscription.invoicedStatus)
            return 'Active';

        if (subscription.paidDate.length === 0)
            return 'Invoiced';
    }

    render() {
        let listItems = this.props.list;
        const sortBy = this.props.sortBy;
        let popData = null;
        if (sortBy.length > 0)
            listItems = this.SortList(listItems, sortBy);
        for (let it in listItems) {
            if (this.state.selectedItem && (this.state.selectedItem.item_id === listItems[it].item_id)) {
                popData = listItems[it];
            }

            let subscriptionList = listItems[it] && listItems[it].subscriptions && listItems[it].subscriptions.sort(this.SortDate);
            let latestSubscription = subscriptionList && subscriptionList[0];
            let subscriptionStatusString = this.subscriptionStatusString(latestSubscription);
            listItems[it].subscriptionStatusString = subscriptionStatusString;
        }

        return (
            <div>
                {this.state.selectedItem &&
                    <Dialog
                        open={this.state.selectedItem != null}
                        onClose={this.closeInformationCard}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                            <InformationCard type={this.state.selectedItem.type} listType={this.props.listType} list={popData} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} />
                        </DialogContent>
                    </Dialog>
                }
                <div >
                    <List>
                        {listItems.map(item => (
                            <ListItem button key={item.type + "_" + item.item_id}
                                selected={this.state.selectedItem === item}
                                onClick={event => this.handleListItemClick(event, item)}
                            >
                                {
                                    (item.type === 'Software' &&
                                        <ListItemText className={!item.status ? 'deletedItem' : ''}
                                        primary={(item.licenseId ? item.licenseId : 'No license number ') + (item.subscriptionStatusString ? (' (' + item.subscriptionStatusString + ')') : '') + ' ' + (item.description ? item.description : '')}
                                            secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                (((item.tecdisType ? ('Type ' + item.tecdisType + ', ') : '') +
                                                    (item.licenseId ? ('License Id ' + item.licenseId + ', ') : '') +
                                                    (item.description ? ('Notes ' + item.description + '.') : '') +
                                                    (item.version ? ('Version ' + item.version + ', ') : '') +
                                                    (item.orderNo ? ('Order number ' + item.orderNo + ', ') : '') +
                                                    (item.orderType ? ('Order type ' + item.orderType + ', ') : '') +
                                                    (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                    (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                    (item.purchaseDate ? ('Delivery Date ' + this.dateForamtting(item.purchaseDate) + ', ') : '') +
                                                    (item.installDate ? ('Installation Date ' + this.dateForamtting(item.installDate) + ', ') : '')).substring(0, 120) +
                                                    ((((item.licenseId ? ('License Id ' + item.licenseId + ', ') : '') +
                                                        (item.tecdisType ? ('Type ' + item.tecdisType + ', ') : '') +
                                                        (item.description ? ('Notes ' + item.description + '.') : '') +
                                                        (item.orderNo ? ('Order number ' + item.orderNo + ', ') : '') +
                                                        (item.purchaseDate ? ('Delivery Date ' + this.dateForamtting(item.purchaseDate) + ', ') : '') +
                                                        (item.installDate ? ('Installation Date ' + this.dateForamtting(item.installDate) + ', ') : '') +
                                                        (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                        (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                        (item.version ? ('Version ' + item.version + ', ') : '') +
                                                        (item.orderType ? ('Order type ' + item.orderType + ', ') : '')).substring(0, 120)).length >= 119 ? '...' : ''))}
                                            </span>}
                                        />)
                                }
                                {
                                    (item.type === 'Hardware' &&
                                        <ListItemText className={!item.status ? 'deletedItem' : ''}
                                            primary={((item.tecdisType ? (item.tecdisType + ' ') : ' ') + (item.hardwareSerialNumber ? item.hardwareSerialNumber : 'No serial number'))}
                                            secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                (((item.hardwareSerialNumber ? ('Telko Serial Number ' + item.hardwareSerialNumber + ', ') : '') +
                                                    (item.tecdisType ? ('Product ' + item.tecdisType + ', ') : '') +
                                                    (item.oemSerialNo ? ('OEM ' + item.oemSerialNo + ', ') : '') +
                                                    (item.osType ? ('OS ' + item.osType + ', ') : '') +
                                                    (item.osId ? ('OS ID ' + item.osId + ', ') : '') +
                                                    (item.diskType ? ('Disk Size ' + item.diskType + ', ') : '') +
                                                    (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                    (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                    (item.supplier ? ('Supplier ' + item.supplier + ', ') : '') +
                                                    (item.productionDate ? ('Date of production ' + this.dateForamtting(item.productionDate) + ', ') : '') +
                                                    (item.installDate ? ('Installation Date ' + this.dateForamtting(item.installDate) + ', ') : '') +
                                                    (item.conning ? ('Conning ' + item.conning + ', ') : '') +
                                                    ('Conning ' + (item.conning ? 'True' : 'False')) + ', ' +
                                                    (item.notes ? ('Notes ' + item.notes + '.') : '') +
                                                    (((this.rmaListToString(item.rma)).length > 0) ? ('RMAs ' + this.rmaListToString(item.rma) + ', ') : '')).substring(0, 120) +
                                                    ((((item.hardwareSerialNumber ? ('Telko Serial Number ' + item.hardwareSerialNumber + ', ') : '') +
                                                        (item.tecdisType ? ('Product ' + item.tecdisType + ', ') : '') +
                                                        (item.conning ? ('Conning ' + item.conning + ', ') : '') +
                                                        ('Conning ' + (item.conning ? 'True' : 'False')) + ', ' +
                                                        (item.productionDate ? ('Date of production ' + this.dateForamtting(item.productionDate) + ', ') : '') +
                                                        (item.installDate ? ('Installation Date ' + this.dateForamtting(item.installDate) + ', ') : '') +
                                                        (item.osType ? ('OS ' + item.osType + ', ') : '') +
                                                        (item.osId ? ('OS id ' + item.osId + ', ') : '') +
                                                        (item.diskType ? ('Disk Size ' + item.diskType + ', ') : '') +
                                                        (item.oemSerialNo ? ('OEM ' + item.oemSerialNo + ', ') : '') +
                                                        (item.dealer ? ('Dealer ' + item.dealer + ', ') : '') +
                                                        (item.distributor ? ('Distributor ' + item.distributor + ', ') : '') +
                                                        (item.supplier ? ('Supplier ' + item.supplier + ', ') : '') +
                                                        (((this.rmaListToString(item.rma)).length > 0) ? ('RMAs ' + this.rmaListToString(item.rma) + ', ') : '') +
                                                        (item.notes ? ('Notes ' + item.notes + '.') : '')).substring(0, 120)).length >= 119 ? '...' : ''))}
                                            </span>}
                                        />)
                                }
                                {
                                    (item.type === 'Vessel' &&
                                        <ListItemText className={!item.status ? 'deletedItem' : ''}
                                            primary={item.vesselName ? item.vesselName : 'No vessel name'}
                                            secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                (((item.vesselName ? ('Name ' + item.vesselName + ', ') : '') +
                                                    (item.vesselSerialNumber ? ('IMO number ' + item.vesselSerialNumber + ', ') : '') +
                                                    (item.buildNo ? ('Yard number ' + item.buildNo + ', ') : '') +
                                                    (item.mmsiNo ? ('MMSI ' + item.mmsiNo + ', ') : '') +
                                                    (item.vesselType ? ('Type ' + item.vesselType + ', ') : '') +
                                                    (item.callSign ? ('Call sign ' + item.callSign + ', ') : '') +
                                                    (item.port ? ('Port ' + item.port + ', ') : '') +
                                                    (item.flag ? ('Flag ' + item.flag + ', ') : '') +
                                                    (item.yard ? ('Yard ' + item.yard + ', ') : '') +
                                                    (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                    (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                    (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                    (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : '')).substring(0, 120) +
                                                    ((((item.vesselSerialNumber ? ('IMO number ' + item.vesselSerialNumber + ', ') : '') +
                                                        (item.vesselName ? ('Name ' + item.vesselName + ', ') : '') +
                                                        (item.vesselType ? ('Type ' + item.vesselType + ', ') : '') +
                                                        (item.callSign ? ('Call sign ' + item.callSign + ', ') : '') +
                                                        (item.port ? ('Port ' + item.port + ', ') : '') +
                                                        (item.flag ? ('Flag ' + item.flag + ', ') : '') +
                                                        (item.yard ? ('Yard ' + item.yard + ', ') : '') +
                                                        (item.buildNo ? ('Yard number ' + item.buildNo + ', ') : '') +
                                                        (item.mmsiNo ? ('MMSI ' + item.mmsiNo + ', ') : '') +
                                                        (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                        (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                        (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                        (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : '')).substring(0, 120)).length >= 119 ? '...' : ''))}
                                            </span>}
                                        />)
                                }
                                {
                                    (item.type === 'Customer' &&
                                        <ListItemText className={!item.status ? 'deletedItem' : ''}
                                            primary={item.name ? item.name : 'No customer name'}
                                            secondary={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{
                                                (((item.name ? ('Name ' + item.name + ', ') : '') +
                                                    (item.address ? ('Address ' + item.address + ', ') : '') +
                                                    (item.city ? ('City ' + item.city + ', ') : '') +
                                                    (item.country ? ('Country ' + item.country + ', ') : '') +
                                                    (item.contactName ? ('Contact first name ' + item.contactName + ', ') : '') +
                                                    (item.contactLastName ? ('Contact last name ' + item.contactLastName + ', ') : '') +
                                                    (item.contactPhone ? ('Contact phone ' + item.contactPhone + ', ') : '') +
                                                    (item.contactEmail ? ('Contact email ' + item.contactEmail + ', ') : '') +
                                                    (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                    (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                    (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                    (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : '')).substring(0, 120) +
                                                    ((((item.name ? ('Name ' + item.name + ', ') : '') +
                                                        (item.address ? ('Address ' + item.address + ', ') : '') +
                                                        (item.city ? ('City ' + item.city + ', ') : '') +
                                                        (item.country ? ('Country ' + item.country + ', ') : '') +
                                                        (item.contactName ? ('Contact first name ' + item.contactName + ', ') : '') +
                                                        (item.contactLastName ? ('Contact last name ' + item.contactLastName + ', ') : '') +
                                                        (item.contactPhone ? ('Contact phone ' + item.contactPhone + ', ') : '') +
                                                        (item.contactEmail ? ('Contact email ' + item.contactEmail + ', ') : '') +
                                                        (item.invoiceEmail ? ('Invoice email ' + item.invoiceEmail + ', ') : '') +
                                                        (item.licenseEmail ? ('License email ' + item.licenseEmail + ', ') : '') +
                                                        (item.invoiceAddress ? ('Invoice address ' + item.invoiceAddress + ', ') : '') +
                                                        (item.licenseAddress ? ('License address ' + item.licenseAddress + '.') : '')).substring(0, 120)).length >= 119 ? '...' : ''))}
                                            </span>}
                                        />)
                                }
                            </ListItem>
                        )
                        )}
                    </List>
                </div>
            </div>);
    }
}

class InformationList extends Component {
    state = {
        sortBy: 'DesDate',
        confirmAddMessage: false
    };

    closeConfirmAddMessage = () => {
        this.setState({ confirmAddMessage: false });
    }

    openConfirmAddMessage = () => {
        this.setState({ confirmAddMessage: true });
    }

    handleAddNewItem = () => {
        if (this.props.multipleHardware && (this.props.listType === 'Vessel' || this.props.listType === 'Customer')) {
            this.openConfirmAddMessage();
            return;
        }

        this.props.addNewItem();
    }

    handleConfirmAddNewItem = () => {
        this.closeConfirmAddMessage();
        this.props.addNewItem();
    }

    handleSortBy = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { classes } = this.props;
        let list = this.props.list;
        return (
            <div>
                <ConfirmMessage message='Adding New Item' fullMessage={<div><p>Hardware not selected, so the software entry will attached to vessel only.</p><p>Select a hardware entry before adding new software to attach to hardware as well. </p></div>} open={this.state.confirmAddMessage} handleClose={this.closeConfirmAddMessage} handleConfirmed={this.handleConfirmAddNewItem} />

                {this.props.type === 'Hardware' &&
                    <Card className='hardwareList'>
                        <CardHeader
                            className={classes.InfoCardHeader}
                            avatar={
                                <HardwareIcon />
                            }
                            title="Hardware"
                            action={
                                <div>
                                    <Grid container spacing={24} >
                                        <Grid item xs={8}>
                                            <form className={classes.root} autoComplete="off">
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <InputLabel
                                                        htmlFor="outlined-age-simple"
                                                    >
                                                        Sort By
                     </InputLabel>
                                                    <Select
                                                        value={this.state.sortBy}
                                                        onChange={this.handleSortBy}
                                                        input={
                                                            <OutlinedInput
                                                                labelWidth={55}
                                                                name="sortBy"
                                                                id="sortBy"
                                                            />
                                                        }
                                                        className={classes.menuSelect}
                                                    >
                                                        <MenuItem value={'DesDate'} >Last Updated</MenuItem>
                                                        <MenuItem value={'AscDate'} >First Updated </MenuItem>
                                                        <MenuItem value={'AscAlpahbet'} >Alphabetical</MenuItem>
                                                        <MenuItem value={'DesAlpahbet'} >Alphabetical (reversed)</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </form>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title="Add New" aria-label="Add New">
                                                <IconButton onClick={this.props.addNewItem}>
                                                    <AddIcon color='action' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        />
                        <CardContent className={classes.ListCardContent}>
                            {list && <ItemList list={this.props.list} listType={this.props.listType} sortBy={this.state.sortBy} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} />}
                        </CardContent>
                    </Card>
                }

                {this.props.type === 'Software' &&
                    <Card className='softwareList'>
                        <CardHeader
                            className={classes.InfoCardHeader}
                            avatar={
                                <SoftwareIcon />
                            }
                            title='Software'
                            action={
                                <div>
                                    <Grid container spacing={24} >
                                        <Grid item xs={8}>
                                            <form className={classes.root} autoComplete="off">
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <InputLabel
                                                        htmlFor="outlined-age-simple"
                                                    >
                                                        Sort By
                     </InputLabel>
                                                    <Select
                                                        value={this.state.sortBy}
                                                        onChange={this.handleSortBy}
                                                        input={
                                                            <OutlinedInput
                                                                labelWidth={55}
                                                                name="sortBy"
                                                                id="sortBy"
                                                            />
                                                        }
                                                        className={classes.menuSelect}
                                                    >
                                                        <MenuItem value={'DesDate'} >Last Updated</MenuItem>
                                                        <MenuItem value={'AscDate'} >First Updated </MenuItem>
                                                        <MenuItem value={'AscAlpahbet'} >Alphabetical</MenuItem>
                                                        <MenuItem value={'DesAlpahbet'} >Alphabetical (reversed)</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </form>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title="Add New" aria-label="Add New">
                                                <IconButton onClick={this.handleAddNewItem}>
                                                    <AddIcon color='action' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        />
                        <CardContent className={classes.ListCardContent}>
                            {list && <ItemList list={this.props.list} listType={this.props.listType} sortBy={this.state.sortBy} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} />}
                        </CardContent>
                    </Card>
                }

                {this.props.type === 'Vessel' &&
                    <Card className='vesselList'>
                        <CardHeader
                            className={classes.InfoCardHeader}
                            avatar={
                                <VesselIcon />
                            }
                            title='Vessel'
                            action={
                                <div>
                                    <Grid container spacing={24} >
                                        <Grid item xs={8}>
                                            <form className={classes.root} autoComplete="off">
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <InputLabel
                                                        htmlFor="outlined-age-simple"
                                                    >
                                                        Sort By
                     </InputLabel>
                                                    <Select
                                                        value={this.state.sortBy}
                                                        onChange={this.handleSortBy}
                                                        input={
                                                            <OutlinedInput
                                                                labelWidth={55}
                                                                name="sortBy"
                                                                id="sortBy"
                                                            />
                                                        }
                                                        className={classes.menuSelect}
                                                    >
                                                        <MenuItem value={'DesDate'} >Last Updated</MenuItem>
                                                        <MenuItem value={'AscDate'} >First Updated </MenuItem>
                                                        <MenuItem value={'AscAlpahbet'} >Alphabetical</MenuItem>
                                                        <MenuItem value={'DesAlpahbet'} >Alphabetical (reversed)</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </form>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title="Add New" aria-label="Add New">
                                                <IconButton onClick={this.props.addNewItem}>
                                                    <AddIcon color='action' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        />
                        <CardContent className={classes.ListCardContent}>
                            {list && <ItemList list={this.props.list} listType={this.props.listType} sortBy={this.state.sortBy} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} />}
                        </CardContent>
                    </Card>
                }

                {this.props.type === 'Customer' &&
                    <Card className='customerList'>
                        <CardHeader
                            className={classes.InfoCardHeader}
                            avatar={
                                <CustomerIcon />
                            }
                            title='Customer'
                            action={
                                <div>
                                    <Grid container spacing={24} >
                                        <Grid item xs={8}>

                                            <form className={classes.root} autoComplete="off">
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <InputLabel
                                                        htmlFor="outlined-age-simple"
                                                    >
                                                        Sort By
                     </InputLabel>
                                                    <Select
                                                        value={this.state.sortBy}
                                                        onChange={this.handleSortBy}
                                                        input={
                                                            <OutlinedInput
                                                                labelWidth={55}
                                                                name="sortBy"
                                                                id="sortBy"
                                                            />
                                                        }
                                                        className={classes.menuSelect}
                                                    >
                                                        <MenuItem value={'DesDate'} >Last Updated</MenuItem>
                                                        <MenuItem value={'AscDate'} >First Updated </MenuItem>
                                                        <MenuItem value={'AscAlpahbet'} >Alphabetical</MenuItem>
                                                        <MenuItem value={'DesAlpahbet'} >Alphabetical (reversed)</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </form>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title="Add New" aria-label="Add New">
                                                <IconButton onClick={this.props.addNewItem}>
                                                    <AddIcon color='action' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        />
                        <CardContent className={classes.ListCardContent}>
                            {list && <ItemList list={this.props.list} listType={this.props.listType} sortBy={this.state.sortBy} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} />}
                        </CardContent>
                    </Card>
                }


            </div>);
    }
}

export default withStyles(styles, { withTheme: true })(InformationList);