import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

class ResponsiveDialog extends React.Component {

    render() {
        const { fullScreen } = this.props;

        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{this.props.message}</DialogTitle>
                    {this.props.fullMessage &&
                        <DialogContent>
                            <DialogContentText>
                                {this.props.fullMessage}
                            </DialogContentText>
                        </DialogContent>
                    }
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
            </Button>
                        <Button onClick={this.props.handleConfirmed} color="primary" autoFocus>
                            Continue
            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ResponsiveDialog);