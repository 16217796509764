import React, { Component } from 'react';
import TopNavBar from '../../components/top_nav_bar/top_nav_bar'
import InfoMessage from '../../components/infomessage/infomessage'
import $ from 'jquery';

class ErrorMessage extends Component {
    constructor() {
        super();
        this.state = { message: 'Incorrect Username or Password!!!' }
    }
    render() {
        return (
            <div id="error" className="alert alert-danger fade in">
                {this.state.message}
            </div>
        );
    }
}


export default class Login extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            userName: "",
            email: "",
            nameOfUser: "",
            mobileNumber: "",
            address: "",
            country: "",
            password: "",
            repassword: "",
            pin: "",
            rePin: "",
            status: false,
            showError: false,
            infoMessage: false
        };
    }

    closeInfoMessage = () => {
        this.setState({ infoMessage: false });
    }

    openInfoMessage = () => {
        this.setState({ infoMessage: true });
    }

    updateUserName(event) {
        this.setState({ userName: event.target.value });
    }

    updateEmail(event) {
        this.setState({ email: event.target.value });
    }

    updateNameOfUser(event) {
        this.setState({ nameOfUser: event.target.value });
    }

    updateMobile(event) {
        this.setState({ mobileNumber: event.target.value });
    }

    updateAddress(event) {
        this.setState({ address: event.target.value });
    }

    updateCountry(event) {
        this.setState({ country: event.target.value });
    }

    updatePassword(event) {
        this.setState({ password: event.target.value });
    }

    updateRePassword(event) {
        this.setState({ repassword: event.target.value });
    }

    updatePin(event) {
        this.setState({ pin: event.target.value });
    }

    updateRePin(event) {
        this.setState({ rePin: event.target.value });
    }

    togglestatus = () => {
        this.setState(function (prevState, props) {
            return {
                status: !(prevState.status)
            };
        });
    }

    componentDidMount() {
        if (!this.props.newregistration) {
            //userInfo(this.props.token, this.props.userName, this.displayUserInfo);
            let recievedData = this.props.userInfo;
            if (recievedData && recievedData.length > 0) {
                let userData = JSON.parse(recievedData);

                this.setState({
                    userName: userData.userName,
                    email: userData.email,
                    nameOfUser: userData.nameOfUser,
                    mobileNumber: userData.mobileNumber,
                    address: userData.address,
                    country: userData.country
                });
            }
        }
    }

    newRegisterationCall = (func) => {
        $.ajax({
            type: "POST",
	    url: `${process.env.REACT_APP_BACKEND_URL}/newregister`,
            data: {
                token: this.props.loginstate.token,
                userName: this.state.userName.toLowerCase(),
                email: this.state.email,
                nameOfUser: this.state.nameOfUser,
                mobileNumber: this.state.mobileNumber,
                address: this.state.address,
                country: this.state.country,
                password: this.state.password,
                pin: this.state.pin
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                alert("Status: " + textStatus); alert("Error: " + errorThrown);
            }
        }).done(function (response) {
            if (response && response.length > 0)
                func();
         });
    }

    updateUserInfoCall = (func) => {
        $.ajax({
            type: "POST",
	    url: `${process.env.REACT_APP_BACKEND_URL}/updateuserinfo`,
            data: {
                username: this.state.userName.toLowerCase(),
                email: this.state.email,
                nameOfUser: this.state.nameOfUser,
                mobileNumber: this.state.mobileNumber,
                address: this.state.address,
                country: this.state.country,
                password: this.state.password,
                pin: this.state.pin,
                status: this.state.status,
                token: this.props.loginstate.token,
                organization: 'telko',
                location: 'telko'
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                alert("Status: " + textStatus); alert("Error: " + errorThrown);
            }
        }).done(function (response) {
            func();
        });
    }


    displayUserInfo = (recievedData) => {
        let userData = null;
        if (recievedData && recievedData.length > 0) {
            userData = JSON.parse(recievedData);

            this.setState({
                userName: userData.userName,
                email: userData.email,
                nameOfuser: userData.nameOfUser,
                mobileNumber: userData.mobileNumber,
                address: userData.address,
                country: userData.country
            });
        }
    }

    handleChange(event) {
    }

    handleSubmit(event) {
        event.preventDefault();
        let data = {
            userName: this.state.userName.toLowerCase(),
            email: this.state.email,
            nameOfUser: this.state.nameOfUser,
            mobileNumber: this.state.mobileNumber,
            address: this.state.address,
            country: this.state.country,
            password: this.state.password,
            rePassword: this.state.rePassword,
            status: this.state.status,
            token: this.props.loginstate.token,
            pin: this.state.pin,
            rePin: this.state.rePin
        };

        if (this.props.newregistration) {
            if (this.state.userName.length === 0 ||
                    this.state.password === "" ||
                    this.state.password !== this.state.repassword) {
                    this.setState({ showError: true });
                    return;
                }
            this.newRegisterationCall(this.openInfoMessage);
            }
            else {
                this.updateUserInfoCall(data);
            }
        }


        render() {
            const showError = this.state.showError;
            let errMessage = null;
            if (showError) {
                errMessage = <ErrorMessage />;
            }

            let formElements = null;
            if (this.props.newregistration) {
                formElements = (
                    <div>
                        <div className="form-group">
                            <label>UserName (Please enter your mail id, which can be verified.):</label>
                            <input className="form-control" type="email" name="username" value={this.state.userName} onChange={this.updateUserName.bind(this)} placeholder="Enter your email addess" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Must be a valid Mail id." />
                        </div>
                        <div className="form-group">
                            <label>Full Name:</label>
                            <input className="form-control" type="text" name="nameOfUser" value={this.state.nameOfUser} onChange={this.updateNameOfUser.bind(this)} placeholder="Enter your full name."/>
                        </div>
                        <div className="form-group">
                            <label>Alternate Email</label>
                            <input className="form-control" type="email" name="email" value={this.state.email} onChange={this.updateEmail.bind(this)} placeholder="Enter your alternate email addess" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Must be a valid Mail id." />
                        </div>
                        <div className="form-group">
                            <label>Mobile</label>
                            <input className="form-control" type="number" name="mobileNumber" value={this.state.mobileNumber} onChange={this.updateMobile.bind(this)} placeholder="Enter your mobile number." pattern="^[0-9]*$" title="Must contain only numbers." />
                        </div>
                        <div className="form-group">
                            <label>Address</label>
                            <input className="form-control" type="text" name="address" value={this.state.address} onChange={this.updateAddress.bind(this)} placeholder="Address" />
                        </div>
                        <div className="form-group">
                            <label>Country</label>
                            <input className="form-control" type="text" name="country" value={this.state.country} onChange={this.updateCountry.bind(this)} placeholder="Country" />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input className="form-control" type="password" name="password" value={this.state.password} onChange={this.updatePassword.bind(this)} placeholder="Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" />
                        </div>
                        <div className="form-group">
                            <label>Re-enter Password</label>
                            <input className="form-control" type="password" name="rePassword" value={this.state.repassword} onChange={this.updateRePassword.bind(this)} placeholder="Re-enter Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" />
                        </div>
                        <div className="form-group">
                            <label>Pin(For fast access to some of the functionality)</label>
                            <input className="form-control" type="password" name="pin" value={this.state.pin} onChange={this.updatePin.bind(this)} placeholder="Pin" pattern="^[0-9]{6,6}$" title="Must be at least 6 digit number" />
                        </div>
                        <div className="form-group">
                            <label>Re-enter Pin</label>
                            <input className="form-control" type="password" name="rePin" value={this.state.rePin} onChange={this.updateRePin.bind(this)} placeholder="rePin" pattern="^[0-9]{6,6}$" title="Must be at least 6 digit number" />
                        </div>
                    </div>
                );
            }
            else {
                formElements = (
                    <div>
                        <div className="form-group">
                            <label>UserName (Please enter your mail id, which can be verified.):</label>
                            <input className="form-control" type="email" name="username" placeholder="Enter your email addess" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Must be a valid Mail id." value={this.state.userName} disabled={true} />
                        </div>
                        <div className="form-group">
                            <label>Full Name:</label>
                            <input className="form-control" type="text" name="nameOfUser" placeholder="Enter your full name." value={this.state.nameOfUser} onChange={this.updateNameOfUser.bind(this)} />
                        </div>
                        <div className="form-group">
                            <label>Alternate Email</label>
                            <input className="form-control" type="email" name="email" placeholder="Enter your alternate email addess" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Must be a valid Mail id." value={this.state.email} onChange={this.updateEmail.bind(this)} />
                        </div>
                        <div className="form-group">
                            <label>Mobile</label>
                            <input className="form-control" type="number" name="mobileNumber" placeholder="Enter your mobile number." pattern="^[0-9]*$" title="Must contain only numbers." value={this.state.mobileNumber} onChange={this.updateMobile.bind(this)} />
                        </div>
                        <div className="form-group">
                            <label>Address</label>
                            <input className="form-control" type="text" name="address" placeholder="Address" value={this.state.address} onChange={this.updateAddress.bind(this)} />
                        </div>
                        <div className="form-group">
                            <label>Country</label>
                            <input className="form-control" type="text" name="country" placeholder="Country" value={this.state.country} onChange={this.updateCountry.bind(this)} />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input className="form-control" type="password" name="password" value={this.state.password} onChange={this.updatePassword.bind(this)} placeholder="Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" />
                        </div>
                        <div className="form-group">
                            <label>Re-enter Password</label>
                            <input className="form-control" type="password" name="rePassword" value={this.state.repassword} onChange={this.updateRePassword.bind(this)} placeholder="Re-enter Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" />
                        </div>
                        <div className="form-group">
                            <label>Pin(For fast access to some of the functionality)</label>
                            <input className="form-control" type="password" name="pin" value={this.state.pin} onChange={this.updatePin.bind(this)} placeholder="Pin" pattern="\d{6}" title="Must be at least 6 digit number" />
                        </div>
                        <div className="form-group">
                            <label>Re-enter Pin</label>
                            <input className="form-control" type="password" name="rePin" value={this.state.rePin} onChange={this.updateRePin.bind(this)} placeholder="rePin" pattern="\d{6}" title="Must be at least 6 digit number" />
                        </div>
                        <div className="checkbox">
                            <label>
                                <input type="checkbox" checked={!!this.state.status} onChange={this.togglestatus} />Active</label>
                        </div>
                    </div>
                );
            }

            let submitButton = null;

            if (this.props.showSubmit) {
                submitButton = (
                    <div className="text-center">
                        <button type="button" className="btn btn-primary btn-lg" onClick={this.handleSubmit}>Submit</button>
                    </div>
                   );
            }
            return (
                <div className="container-contact100">
                    <TopNavBar loginstate={this.props.loginstate} heading='New User Registeration' />
                    <InfoMessage message='New User Registered' open={this.state.infoMessage} handleClose={this.closeInfoMessage} />
                    <div className="wrap-contact100">
                        <form className="contact100-form validate-form" onSubmit={this.handleSubmit}>
                            {errMessage}
                            {formElements}
                            {submitButton}

                        </form>
                    </div>
                </div>

            );
        }
    }

    Login.defaultProps = {
        newregistration: true,
        userName: "",
        token: ""
    };
