import React, { Component } from 'react';
import SearchList from '../../components/list_with_search/list_with_search'
import InformationModule from '../../components/information_module/information_module'
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';
import TopNavBar from '../../components/top_nav_bar/top_nav_bar'
import InformationCard from '../../components/information_card/information_card'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InfoMessage from '../../components/infomessage/infomessage'

const styles = theme => ({
    dashboard: {
        backgroundColor: '#F1F1F1',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            overflowY:'auto'
        },
    },
});

class DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        iternaryList: [],
        informationFetched: null,
        selectedItem: null,
        errorMessage: "",
        newSoftware: false,
        newHardware: false,
        newVessel: false,
        newCustomer: false,
        copiedList: [],
        sortBy: 'Smart',
        softwareShown: true,
        hardwareShown: true,
        vesselShown: true,
        customerShown: true,
        unAuthMessage: false
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.fetchItineraryList('atStart');
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let iternaryList = this.state.iternaryList;
        this.setState({ iternaryList });
    }

    closeUnAuthMessage = () => {
        this.setState({ unAuthMessage: false });
    }

    openUnAuthMessage = () => {
        this.setState({ unAuthMessage: true });
    }

    getNameForItem = (item) => {
        if (!item)
            return '';

        if (item.type === 'Software')
            return item.licenseId;
        else if (item.type === 'Hardware')
            return item.hardwareSerialNumber;
        else if (item.type === 'Vessel')
            return item.vesselName;
        else if (item.type === 'Customer')
            return item.name;
        return '';
    }

    getListMaxDate(list) {
        let maxDate = '0';
        if (!list)
            return maxDate;

        for (let it in list) {
            if (list[it].updateDate && list[it].updateDate > maxDate) {
                maxDate = list[it].updateDate;
            }
        }
        return maxDate;
    }

    lazyLoadingList = (sortBy) => {
        let iternaryList = this.state.iternaryList.slice();
        if (iternaryList.length === 0)
            return;

        let lastItem = iternaryList.pop();
        let lastItemId = lastItem && lastItem.item_id;
        let cachedListCount = this.state.iternaryList.length;
        let maxDate = this.getListMaxDate(this.state.iternaryList)

        let softwareShown = this.state.softwareShown;
        let hardwareShown = this.state.hardwareShown;
        let vesselShown = this.state.vesselShown;
        let customerShown = this.state.customerShown;
        const headers = { Authorization: this.props.loginstate.token };

        const url = `${process.env.REACT_APP_BACKEND_URL}/itineraryList` + '?sort=' + sortBy + '&lastid=' + lastItemId + '&lastitemtype=' + lastItem.type + '&cachedlistcount=' + cachedListCount + '&cachedmaxdate=' + maxDate + '&software=' + softwareShown + '&hardware=' + hardwareShown + '&vessel=' + vesselShown + '&customer=' + customerShown;
        axios.get(url,
            { headers }
        ).then(response => {
            let fetchedData = response.data;
            let iternaryList = this.state.iternaryList;
            iternaryList = iternaryList.filter(
                (item) => {
                    let flag = true;
                    for (let it in fetchedData) {
                        if (item.type === fetchedData[it].type && item.item_id === fetchedData[it].item_id)
                            flag = false;
                    }
                    return flag;
                }
            );
            iternaryList = iternaryList.concat(fetchedData);

            this.setState({
                iternaryList,
                errorMessage: ""
            });

            if (this.state.selectedItem)
                this.fetchItemInfo(this.state.selectedItem);

        })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401)
                        this.openUnAuthMessage();
                    else if (error.response.status === 409) {
                        let tempList = this.state.iternaryList.slice();
                        let listLength = tempList.length;
                        this.fetchItineraryList(null, null, null, listLength);
                    }
                    else
                        this.setState({ errorMessage: "Couldn't fetch user list from server. Unknown failure occured." });
                }
                else if (error.request) {
                    this.setState({ errorMessage: "Server unreachable" });
                }
                else {
                    this.setState({ errorMessage: "Couldn't fetch user list from server. Unknown failure occured." });
                }
            });
    }

    fetchItineraryList = (sortBy, filterName, FilterValue, listLength) => {
        let atStart = false;
        if (sortBy === 'atStart') {
            atStart = true;
            sortBy = null;
        }

        if (filterName && (FilterValue === true || FilterValue === false)) {
            this.setState({ [filterName]: FilterValue });
        }

        this.setState({ iternaryList: [] });

        const headers = { Authorization: this.props.loginstate.token };
        let sortingString = sortBy ? sortBy : this.state.sortBy;

        let softwareShown = (filterName === 'softwareShown') ? FilterValue : this.state.softwareShown;
        let hardwareShown = (filterName === 'hardwareShown') ? FilterValue : this.state.hardwareShown;
        let vesselShown = (filterName === 'vesselShown') ? FilterValue : this.state.vesselShown;
        let customerShown = (filterName === 'customerShown') ? FilterValue : this.state.customerShown;

        let maxItem = 50;
        if (listLength) {
            maxItem = listLength;
        }

        const url = `${process.env.REACT_APP_BACKEND_URL}/itineraryList` + '?sort=' + sortingString + '&maxlistlength=' + maxItem + '&software=' + softwareShown + '&hardware=' + hardwareShown + '&vessel=' + vesselShown + '&customer=' + customerShown;
        axios.get(url,
            { headers }
        ).then(response => {
            let iternaryList = response.data;
            let firstItem = iternaryList.shift();
            iternaryList.unshift(firstItem);
            this.setState((prevState) => {
                return (
                    {
                        iternaryList,
                        sortBy: sortingString,
                        errorMessage: "",
                        selectedItem: prevState.selectedItem ? prevState.selectedItem : firstItem
                    }
                );
            });

            if (this.state.selectedItem)
                this.fetchItemInfo(this.state.selectedItem);

        })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        if (atStart) {
                            this.props.loginstate.doUpdateUserDetails('',
                                '',
                                false,
                                '',
                                '');
                        }
                        else
                            this.openUnAuthMessage();
                    }
                    else
                        this.setState({ errorMessage: "Couldn't fetch user list from server. Unknown failure occured." });
                }
                else if (error.request) {
                    this.setState({ errorMessage: "Server unreachable" });
                }
                else {
                    this.setState({ errorMessage: "Couldn't fetch user list from server. Unknown failure occured." });
                }
            });
    }

    
    fetchItemInfo = (item) => {
        let url = '';
        if (item.type === 'Software')
            url = `${process.env.REACT_APP_BACKEND_URL}/softwareinfo/`+ item.item_id;
        else if (item.type === 'Hardware')
            url = `${process.env.REACT_APP_BACKEND_URL}/hardwareinfo/` + item.item_id;
        else if (item.type === 'Vessel')
            url = `${process.env.REACT_APP_BACKEND_URL}/vesselinfo/` + item.item_id;
        else if (item.type === 'Customer')
            url = `${process.env.REACT_APP_BACKEND_URL}/customerinfo/` + item.item_id;
        else
            return;

        const headers = { Authorization: this.props.loginstate.token };
        axios.get(url,
            { headers }
        ).then(response => {
            this.setState({
                informationFetched: response.data,
                errorMessage: ""
            });
        })
            .catch(error => {
                this.setState({ informationFetched: null });
                if (error.response) {
                    if (error.response.status === 401)
                        this.openUnAuthMessage();
                    else
                        this.setState({ errorMessage: "Couldn't fetch user list from server. Unknown failure occured." });
                }
                else if (error.request) {
                    this.setState({ errorMessage: "Server unreachable" });
                }
                else {
                    this.setState({ errorMessage: "Couldn't fetch user list from server. Unknown failure occured." });
                }
            });
    }

    onSelection = (item) => {
        if (item === null) {
            this.setState({
                selectedItem: null,
                informationFetched: null
            });
            return;
        }
        this.fetchItemInfo(item);
        this.setState({
            selectedItem: item,
        });
    }

    copyDetails = (name, list) => {
        if (list)
            list.status = true;

        let copiedList = this.state.copiedList;
        copiedList[name] = list;
        this.setState({ copiedList });
    }

    addNewSoftware = () => {
        this.setState({ newSoftware: true });
    }

    closeNewSoftware = () => {
        this.setState({ newSoftware: false });
    }

    addNewHardware = () => {
        this.setState({ newHardware: true });
    }

    closeNewHardware = () => {
        this.setState({ newHardware: false });
    }

    addNewVessel = () => {
        this.setState({ newVessel: true });
    }

    closeNewVessel = () => {
        this.setState({ newVessel: false });
    }

    addNewCustomer = () => {
        this.setState({ newCustomer: true });
    }

    closeNewCustomer = () => {
        this.setState({ newCustomer: false });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.dashboard} style={{ height: window.innerHeight, width: window.innerWidth-25 }}>
                {this.state.unAuthMessage && <InfoMessage message='Not Authorised!' open={this.state.unAuthMessage} handleClose={this.closeUnAuthMessage} />}
                {this.state.newSoftware &&
                    <Dialog
                        open={this.state.newSoftware}
                        onClose={this.closeNewSoftware}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                        <InformationCard type='Software' newItem={true} loginstate={this.props.loginstate} fetchItineraryList={this.fetchItineraryList} closeNewItem={this.closeNewSoftware} copiedList={this.state.copiedList} copyDetails={this.copyDetails}/>
                        </DialogContent>
                    </Dialog>
                }

                {this.state.newHardware &&
                    <Dialog
                        open={this.state.newHardware}
                        onClose={this.closeNewHardware}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                        <InformationCard type='Hardware' newItem={true} loginstate={this.props.loginstate} fetchItineraryList={this.fetchItineraryList} closeNewItem={this.closeNewHardware} copiedList={this.state.copiedList} copyDetails={this.copyDetails}/>
                        </DialogContent>
                    </Dialog>
                }

                {this.state.newVessel &&
                    <Dialog
                        open={this.state.newVessel}
                        onClose={this.closeNewVessel}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                        <InformationCard type='Vessel' newItem={true} loginstate={this.props.loginstate} fetchItineraryList={this.fetchItineraryList} closeNewItem={this.closeNewVessel} copiedList={this.state.copiedList} copyDetails={this.copyDetails}/>
                        </DialogContent>
                    </Dialog>
                }

                {this.state.newCustomer &&
                    <Dialog
                        open={this.state.newCustomer}
                        onClose={this.closeNewCustomer}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                        <InformationCard type='Customer' newItem={true} popUp={true} loginstate={this.props.loginstate} fetchItineraryList={this.fetchItineraryList} closeNewItem={this.closeNewCustomer} copiedList={this.state.copiedList} copyDetails={this.copyDetails}/>
                        </DialogContent>
                    </Dialog>
                }

                <TopNavBar loginstate={this.props.loginstate} heading={<div onClick={()=> {this.onSelection(null)}}>TELCARE</div>} />
                <Slide direction="down" in={this.state.errorMessage.length > 0} mountOnEnter unmountOnExit>
                    <div className='alert alert-danger col-xs-12'>
                        {this.state.errorMessage}
                    </div>
                </Slide>
                <Grid container spacing={24}>
                    <Hidden xsDown>
                        <Grid item sm={this.state.informationFetched ? 2 : 12} className='searchListHeight'>
                            <div>
                                {this.state.iternaryList &&
                                    <SearchList loginstate={this.props.loginstate} list={this.state.iternaryList} fetchItineraryList={this.fetchItineraryList} onSelection={this.onSelection} selectedItem={this.state.selectedItem} addNewSoftware={this.addNewSoftware} addNewHardware={this.addNewHardware} addNewVessel={this.addNewVessel} addNewCustomer={this.addNewCustomer} lazyLoadingList={this.lazyLoadingList} />
                                }
                            </div>
                        </Grid>
                        {this.state.informationFetched &&
                            <Grid item sm={10}>
                                <div>
                                    {this.state.iternaryList &&
                                        <Slide direction="left" in={this.state.iternaryList !== null} mountOnEnter unmountOnExit>
                                            <InformationModule list={this.state.informationFetched} onSelection={this.onSelection} copyDetails={this.copyDetails} copiedList={this.state.copiedList} loginstate={this.props.loginstate} fetchItineraryList={this.fetchItineraryList} />
                                        </Slide>
                                    }
                                </div>
                            </Grid>
                        }
                    </Hidden>
                    <Hidden smUp>
                        {!this.state.informationFetched &&
                            <Grid item xs={12} className='searchListHeight'>
                                <div>
                                    {this.state.iternaryList &&
                                        <SearchList loginstate={this.props.loginstate} list={this.state.iternaryList} fetchItineraryList={this.fetchItineraryList} onSelection={this.onSelection} selectedItem={this.state.selectedItem} addNewSoftware={this.addNewSoftware} addNewHardware={this.addNewHardware} addNewVessel={this.addNewVessel} addNewCustomer={this.addNewCustomer} lazyLoadingList={this.lazyLoadingList} />
                                    }
                                </div>
                            </Grid>
                        }
                        {this.state.informationFetched &&
                            <Grid item xs={12}>
                                <div>
                                    {this.state.iternaryList &&
                                        <Slide direction="left" in={this.state.iternaryList !== null} mountOnEnter unmountOnExit>
                                            <InformationModule list={this.state.informationFetched} onSelection={this.onSelection} copyDetails={this.copyDetails} copiedList={this.state.copiedList} loginstate={this.props.loginstate} fetchItineraryList={this.fetchItineraryList} />
                                        </Slide>
                                    }
                                </div>
                            </Grid>
                        }
                    </Hidden>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(DashBoard));
