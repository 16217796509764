import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import './loginpage.css';
import queryString from 'query-string';
import { Paper, withStyles, Grid, TextField, Button} from '@material-ui/core';
import { Person, Fingerprint } from '@material-ui/icons'

const styles = theme => ({
    loginPanel: {
        margin: 30,
        padding: 30
    },
    button: {
        borderRadius: 0,
        border: 0,
        color: 'white',
        minHeight: 30,
        minWidth: 27,
        marginRight: 5
    }
});



class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            username: "",
            password: "",
            errorMessage : "",
            redirectToReferrer : false
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }


    validateForm() {
        // TODO: Update this to actually reflect the password
        // requirements on the server side
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let loginData = {
            userName: this.state.username.toLowerCase().trim(),
            userPassword: this.state.password.trim()
        };
        this.setState({ isLoading: true,
                        password: "" });

        const cookies = new Cookies();
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/login`,
            data: loginData,
        }).always( () => {
            // Note: In the success case, this must be done before
            // calling doUpdateUserDetails. Otherwise we are calling
            // setState after unmounting which causes a warning.
            this.setState({ isLoading: false })
        }).done( (response, textStatus, jqXHR) => {
            if (jqXHR.status === 200) {
                const token = response["token"];
                const name_of_user = response["name_of_user"];
                const user_color = response["user_color"];
                cookies.set('token', token);
                cookies.set('username', this.state.username);
                cookies.set('name_of_user', name_of_user);
                cookies.set('user_color', user_color);
                //this.props.updateUserDetails(this.state.username, token, true);
                // Communicate back to app object that the user logged in
                this.props.loginstate.doUpdateUserDetails(this.state.username,
                                                          token,
                                                          true,
                                                          name_of_user,
                                                          user_color);
                const parsed = queryString.parse(this.props.location);
                this.setState({ redirectToReferrer: parsed.redirect ? parsed.redirect : "/" });
                this.props.history.push('/');
            }
        }).fail( (jqXHR, textStatus, errorThrown) => {
            // TODO: Handle the case where the user navigates away
            // from the page before the response is
            // received. Apparently his gives the same error. See
            // https://stackoverflow.com/questions/6977870/in-a-ajax-callback-how-do-i-distinguish-an-unreachable-server-from-a-user-nav
            if (!jqXHR.status && !jqXHR.readyState) {
                this.setState({ errorMessage : "Couldn't log in. Server unreachable"});
            }
            else if (jqXHR.status === 401) {
                this.setState({ errorMessage : "Couldn't log in. Incorrect username or password" });
            }
            else {
                this.setState({ errorMessage: "Couldn't log in. Unknown failure occured"});
            }
        });
    }

    handleSubmitOnEnter = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit(event);
        }
    }

    render() {
        const { classes } = this.props;
        if (this.state.redirectToReferrer) {
            return <Redirect to={this.state.redirectToReferrer} />;
        }

        if (this.props.loginstate.isAuthenticated) {
            return <Redirect to="/" />;
        }

        //<Grid container alignItems="center" justify="space-between">

        //    <Grid item>
        //        <Link to='/signup'>New User ?</Link>
        //    </Grid>
        //</Grid>

    return (
        <div className="Login col-xs-12 loginPageContainer">
            <Paper className='loginPage'>
                {this.state.errorMessage && <Alert bsStyle="danger"><p>{this.state.errorMessage}</p></Alert>}
                <div className={classes.loginPanel}>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <Person />
                        </Grid>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="username" label="Username" type="email" fullWidth autoFocus required
                                value={this.state.username}
                                onChange={this.handleChange}
                                autoComplete="email"/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <Fingerprint />
                        </Grid>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="password" label="Password" type="password" fullWidth required
                                value={this.state.password}
                                onChange={this.handleChange}
                                onKeyPress={this.handleSubmitOnEnter}
                                autoComplete="current-password"/>
                        </Grid>
                    </Grid>
                    
                    <Grid container justify="center" style={{ marginTop: '10px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ textTransform: "none" }}
                            disabled={!this.validateForm()}
                            onSubmit={this.handleSubmit}
                            onClick={this.handleSubmit}
                        >
                            Login
                            </Button>
                    </Grid>
                </div>
            </Paper>

            
        </div>
    );
    }
}

export default withRouter(withStyles(styles)(Login));
