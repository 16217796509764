import React, { Component } from 'react';

class ShowList extends Component {
    render() {
        let row = null;
        if (this.props.option1) {
            row = (
                <tr className="info">
                    <td>{this.props.resource}</td>
                    <td><input type="checkbox"
		               checked={this.props.option1}
		               onChange={(e) => this.props.toggleOption1(this.props.resource, !this.props.option1)}
		               disabled={true} /></td>
                    <td><input type="checkbox"
		               checked={!!this.props.option2}
		               onChange={(e) => this.props.toggleOption2(this.props.resource, !this.props.option2)}
		               disabled={!this.props.option2Enabled} /></td>
                    <td><input type="checkbox"
		               checked={!!this.props.option3}
		               onChange={(e) => this.props.toggleOption3(this.props.resource, !this.props.option3)}
		               disabled={!this.props.option3Enabled} /></td>
                </tr>
            );
        }
        else if (!this.props.option1 && !this.props.option2 && !this.props.option3) {
            row = (
                <tr className="warning">
                    <td>{this.props.resource}</td>
                    <td><input type="checkbox"
		               checked={!!this.props.option1}
		               onChange={(e) => this.props.toggleOption1(this.props.resource, !this.props.option1)}
		               disabled={true} /></td>
                    <td><input type="checkbox"
		               checked={!!this.props.option2}
		               onChange={(e) => this.props.toggleOption2(this.props.resource, !this.props.opton2)}
		               disabled={!this.props.option2Enabled} /></td>
                    <td><input type="checkbox"
		               checked={!!this.props.option3}
		               onChange={(e) => this.props.toggleOption3(this.props.resource, !this.props.option3)}
		               disabled={!this.props.option3Enabled} /></td>
                </tr>
            );
        }
        else {
            row = (
                <tr>
                    <td>{this.props.resource}</td>
                    <td><input type="checkbox"
		               checked={!!this.props.option1}
		               onChange={(e) => this.props.toggleOption1(this.props.resource, !this.props.option1)}
		               disabled={true} /></td>
                    <td><input type="checkbox"
		               checked={!!this.props.option2}
		               onChange={(e) => this.props.toggleOption2(this.props.resource, !this.props.option2)}
		               disabled={!this.props.option2Enabled} /></td>
                    <td><input type="checkbox"
		               checked={!!this.props.option3}
		               onChange={(e) => this.props.toggleOption3(this.props.resource, !this.props.option3)}
		               disabled={!this.props.option3Enabled} /></td>
                </tr>
            );
        }
        return row;
    }
}

ShowList.defaultProps = {
    option2Enabled: false,
    option3Enabled: false,
};

export default class SettingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
        };
    }

    toggleOption1 = (item, value) => {
        // Nothing to do for option 1.
    }

    toggleOption2 = (item, value) => {
	this.props.onChange(item, 2, value); 
    }

    toggleOption3 = (item, value) => {
	this.props.onChange(item, 3, value);
    }

    goToStartButtonClick = () => {
        this.setState({
            startIndex: 0
        });
    }

    prevButtonClick = () => {
        this.setState(function (prevState, props) {
            if (prevState.startIndex === 0)
                return;

            return {
                startIndex: +prevState.startIndex - +this.props.elementsShownCount
            };
        });
    }

    nextButtonClick = () => {
        let temp = (this.props.showListElement.length / this.props.elementsShownCount) - 1;
        let tempstartIndex = this.props.elementsShownCount * temp;
        this.setState(function (prevState, props) {
            if (prevState.startIndex !== tempstartIndex) {
                    return {
                        startIndex: +prevState.startIndex + +this.props.elementsShownCount
                    };
                }
            });
    }

    goToEndButtonClick = () => {
        const numpages = (this.state.itemLength / this.props.elementsShownCount) - 1;
        const offset = this.props.elementsShownCount * numpages;
        this.setState({
            startIndex: offset
        });
    }

    render() {
        if (this.props.updatedList)
            this.props.updatedList(this.state.showListElements);
        let arrowButtons = null;
        let listShown = this.props.showListElements;
        if (this.state.itemLength > this.props.elementsShownCount) {
            arrowButtons = (<div><div className="round" onClick={this.goToStartButtonClick}>&#8249;&#8249;</div>
                <div className="round" onClick={this.prevButtonClick}>&#8249;</div>
                <div className="round" onClick={this.nextButtonClick}>&#8250;</div>
                <div className="round" onClick={this.goToEndButtonClick}>&#8250;&#8250;</div></div>);

            let index = 0;
            let counter = 0;
            listShown = [];
            for (var key in this.props.showListElements) {
                if (index >= this.state.startIndex && index < (+this.props.elementsShownCount + +this.state.startIndex)) {
                    listShown[counter] = this.props.showListElements[key];
                    counter++;
                }
                index = index + 1;
            }
        }

        let pageNo = Math.ceil(this.state.startIndex / this.props.elementsShownCount) + 1;
        let totalPages = Math.ceil(this.state.itemLength / this.props.elementsShownCount);

        return (
            <div>
                <div className="text-center">
                               <h3>{this.props.tableTitle} ({pageNo}/{totalPages})</h3>
                               <table className="table table-striped table-bordered table-hover" >
                                   <tbody>
                                       <tr>
                                        <td>{this.props.objectTitle}</td>
                                        <td>{this.props.optionTitle1}</td>
                                        <td>{this.props.optionTitle2}</td>
                                        <td>{this.props.optionTitle3}</td>
                                    </tr>
                {listShown.map( (showListElement) => { return <ShowList key={showListElement.resource}
						                        {...showListElement}
						                        toggleOption1={this.toggleOption1}
						                        toggleOption2={this.toggleOption2}
						                        toggleOption3={this.toggleOption3} /> })}
                        </tbody>
                </table>
                </div>
                            {arrowButtons}
            </div>

        );
    }
}

SettingList.defaultProps = {
    tableTitle: 'Table title',
    objectTitle: 'Permissions',
    optionTitle1: 'From Rules',
    optionTitle2: 'Read',
    optionTitle3: 'Modify',
    elementsShownCount: 10
};
