import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    message: {
        color: '#0071BC'
    },
});

class ResponsiveDialog extends React.Component {
    state = {
        closeCall : false
    }

    componentDidMount() {
        this.interval = setInterval(this.closeDialogBox, 1000);
    }

    closeDialogBox = () => {
        if (this.state.closeCall) {
            this.setState({ closeCall: false });
            clearInterval(this.interval);
            this.props.handleClose();
        }
        else {
            this.setState({ closeCall: true });
        }
    }

    render() {
        const { fullScreen } = this.props;
        const { classes } = this.props;

        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title"><div className={classes.message}>{this.props.message}</div></DialogTitle>
                    {this.props.fullMessage && <DialogContent>
                        <DialogContentText >
                            <div className={classes.message}>{this.props.fullMessage}</div>
                        </DialogContentText>
                    </DialogContent>

                    }
                </Dialog>
            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(withStyles(styles, { withTheme: true })(ResponsiveDialog));