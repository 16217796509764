import React, { Component } from 'react';
import $ from 'jquery';
import { withRouter } from 'react-router';
import { Alert } from 'react-bootstrap';
import EditUserRole from '../../components/updateuserrole/updateuserrole'
import EditUserPermission from '../../components/updateuserpermissions/updateuserpermissions'
import UserDetailsForm from '../../components/userdetailsform/userdetailsform';
import LoaderButton from '../../components/loaderbutton/LoaderButton';
import AnonymousAvatar from '../../components/anonymous-avatar/anonymous-avatar'
import ConfirmationPopUp from '../../components/confirmation_popup/confirmation_popup'
import TopNavBar from '../../components/top_nav_bar/top_nav_bar'


class EditUserInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: null,
            userRoles: null,
            allRoles: null,
            userPermissions: null,
            allPermissions: null,
            userPermissionList: null,
            errorMessage: null,
            submitConfirmation: false,
        };
    }
    componentDidMount() {
        this.fetchUserInfo();
        this.fetchUserRoleList();
        this.fetchAllRoles();
        this.fetchUserPermissions();
        this.fetchAllPermissions();
    }

    fetchUserInfo = () => {
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/userinfo`,
            data: {
                token: this.props.loginstate.token,
                username: this.props.userName,
                organization: 'telko',
                location: 'telko'
            },
            crossDomain: true
        }).done((response) => {
            response['password'] = '';
            response['repassword'] = '';
            response['pin'] = '';
            response['repin'] = '';
            this.setState({ userInfo: response });
        }).fail((jqXHR, textStatus, errorThrown) => {
            // TODO: Handle the case where the user navigates away
            // from the page before the response is
            // received. Apparently this gives the same error. See
            // https://stackoverflow.com/questions/6977870/in-a-ajax-callback-how-do-i-distinguish-an-unreachable-server-from-a-user-nav
            if (!jqXHR.status && !jqXHR.readyState) {
                this.setState({ errorMessage: "Couldn't fetch user profile. Server unreachable" });
            }
            else if (jqXHR.status === 401) {
                this.setState({ errorMessage: "Couldn't fetch user profile. Access denied." });
            }
            else {
                this.setState({ errorMessage: "Couldn't fetch user profile from server. Unknown failure occured" });
            }
        });
    }

    fetchUserRoleList = () => {
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/userrolelist`,
            data: {
                token: this.props.loginstate.token,
                username: this.props.userName,
                organization: 'telko',
                location: 'telko'
            },
            crossDomain: true,
        }).done((response) => {
            this.setState({ userRoles: response });
        }).fail((jqXHR, textStatus, errorThrown) => {
            // TODO: Handle the case where the user navigates away
            // from the page before the response is
            // received. Apparently his gives the same error. See
            // https://stackoverflow.com/questions/6977870/in-a-ajax-callback-how-do-i-distinguish-an-unreachable-server-from-a-user-nav
            if (!jqXHR.status && !jqXHR.readyState) {
                this.setState({ errorMessage: "Couldn't fetch user roles. Server unreachable" });
            }
            else if (jqXHR.status === 401) {
                this.setState({ errorMessage: "Couldn't fetch user roles. Access denied." });
            }
            else {
                this.setState({ errorMessage: "Couldn't fetch user roles from server. Unknown failure occured" });
            }
        });
    }

    fetchAllRoles = () => {
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/rolelist`,
            data: {
                token: this.props.loginstate.token,
                organization: 'telko',
                location: 'telko'
            },
            crossDomain: true
        }).done((response) => {
            this.setState({ allRoles: response });
        }).fail((XMLHttpRequest, textStatus, errorThrown) => {
            alert("Status: " + textStatus); alert("Error: " + errorThrown);
        });
    }

    fetchAllPermissions = () => {
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/permissionlist`,
            data: {
                token: this.props.loginstate.token,
                organization: 'telko',
                location: 'telko'
            },
            crossDomain: true,
        }).done((response) => {
            this.setState({ allPermissions: response });
        }).fail((XMLHttpRequest, textStatus, errorThrown) => {
            alert("Status: " + textStatus); alert("Error: " + errorThrown);
        });
    }

    fetchUserPermissions = () => {
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/userpermissionlist`,
            data: {
                token: this.props.loginstate.token,
                username: this.props.userName,
                organization: 'telko',
                location: 'telko',
                allavailable: 'false'
            },
            crossDomain: true,
        }).done((response) => {
            this.setState({ userPermissions: response });
        }).fail((XMLHttpRequest, textStatus, errorThrown) => {
            alert("Status: " + textStatus); alert("Error: " + errorThrown);
        });
    }

    userInfoChanged = (updatedUserInfo) => {
        this.setState({ userInfo: updatedUserInfo });
    }


    userRolesChanged = updatedUserRoles => {
        //this.currentUserRoles = updatedUserRoles;
        this.setState({ userRoles: updatedUserRoles });
    }

    userPermissionChanged = (resource, column, value) => {
        let found = false;
        const newUserPermissions =
            this.state.userPermissions.map(item => {
                if (resource === item.resource) {
                    found = true;
                    if (column === 2) {
                        return {
                            resource,
                            from_role: false,
                            read: value,
                            write: item.write
                        };
                    }
                    else {
                        return {
                            resource,
                            read: item.read,
                            write: value
                        };
                    }
                }
                else
                    return Object.assign({}, item);
            });

        if (!found) {
            for (let i = 0; i < this.state.allPermissions.length; i++) {
                const item = this.state.allPermissions[i];
                if (item.resource === resource) {
                    found = true;
                    newUserPermissions.push({
                        resource: resource,
                        from_role: false,
                        read: (column === 2 ? value : false),
                        write: (column === 3 ? value : false)
                    });
                }
            }

            if (!found)
                console.log("Error! Item not found");
        }

        this.setState({ userPermissions: newUserPermissions });
    }

    submitConfirmation = () => {
        this.setState({
            submitConfirmation: true
        });
    }

    cancelConfirmation = () => {
        this.setState({
            submitConfirmation: false
        });
    }

    handleSubmit = () => {
        this.updateUserDatabase();
        //this.updateUserRoles();
        //this.updateUserPermissions();
        this.setState({
            submitConfirmation: false
        });
    }

    updateUserDatabase = () => {
        const data = {
            username: this.state.userInfo.username.toLowerCase(),
            user_color: this.state.userInfo.user_color,
            email: this.state.userInfo.email,
            nameOfUser: this.state.userInfo.name,
            crewId: this.state.userInfo.crewid,
            mobileNumber: this.state.userInfo.mobilenumber,
            address: this.state.userInfo.address,
            country: this.state.userInfo.country,
            status: this.state.userInfo.status,
            password: this.state.userInfo.password,
            pin: this.state.userInfo.pin,
            token: this.props.loginstate.token,
            organization: 'telko',
            location: 'telko',
        };

        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/updateuserinfo`,
            data: data,
        }).fail((jqXHR, textStatus, errorThrown) => {

            if (!jqXHR.status && !jqXHR.readyState) {
                this.setState({ errorMessage: "Couldn't update user details. Server unreachable." });
            }
            else if (jqXHR.status === 401) {
                this.setState({ errorMessage: "Couldn't update user details. Not authenticated." });
            }
            else {
                this.setState({ errorMessage: "Couldn't update user details. Unknown failure occured." });
            }
        }).done((response) => {
            this.props.loginstate.doUpdateUserDetails(this.props.loginstate.username,
                this.props.loginstate.token,
                true,
                this.props.loginstate.name_of_user,
                data.user_color);
            this.updateUserRoles();
            alert("User Information updated");

            // TODO: Give cofirmation message to user
        });
    }

    updateUserRoles = () => {
        const updatedRoles = this.state.userRoles;
        const data = {
            token: this.props.loginstate.token,
            username: this.state.userInfo.username.toLowerCase(),
            organization: 'telko',
            location: 'telko',
            updatedroles: JSON.stringify(updatedRoles)
        };

        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/updateuserroles`,
            data,
            crossDomain: true,
        }).fail((jqXHR, textStatus, errorThrown) => {
            if (!jqXHR.status && !jqXHR.readyState) {
                this.setState({ errorMessage: "Couldn't update user roles. Server unreachable." });
            }
            else if (jqXHR.status === 401) {
                this.setState({ errorMessage: "Couldn't update user roles. Not authenticated." });
            }
            else {
                this.setState({ errorMessage: "Couldn't update user roles. Unknown failure occured." });
            }
        }).done((response) => {
            this.updateUserPermissions();
            alert("Roles updated");
        });
    }

    updateUserPermissions = () => {
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/updateuserpermissions`,
            data: {
                token: this.props.loginstate.token,
                username: this.state.userInfo.username.toLowerCase(),
                organization: 'telko',
                location: 'telko',
                updatedpermissions: JSON.stringify(this.state.userPermissions)
            },
            crossDomain: true,
        }).fail((jqXHR, textStatus, errorThrown) => {
            if (!jqXHR.status && !jqXHR.readyState) {
                this.setState({ errorMessage: "Couldn't update user permissions. Server unreachable." });
            }
            else if (jqXHR.status === 401) {
                this.setState({ errorMessage: "Couldn't update user permissions. Not authenticated." });
            }
            else {
                this.setState({ errorMessage: "Couldn't update user permissions. Unknown failure occured. " + jqXHR.responseText });
            }
        }).done(function (response) {
            alert("permissions updated");
        });
    }

    userColorChanged = (event) => {
        let userInfo = this.state.userInfo;
        userInfo.user_color = event.target.value;
        this.setState({
            userInfo: userInfo
        });


    }

    render() {
        let userDetailsEditComponent = null;
        let roleDataComponent = null;
        let permissionListComponent = null;
        if (this.state.userInfo)
            userDetailsEditComponent = (
                <div>
                    <div className='col-xs-12'>
                        <div className='col-xs-2' style={{ 'float': 'left' }}>
                            <AnonymousAvatar color={this.state.userInfo.user_color} />
                            <div style={{ 'float': 'right' }}>
                                <input type='color' value={'' + this.state.userInfo.user_color} onChange={this.userColorChanged} style={{ 'display': 'block' }} />
                                <div >Select Color</div>
                            </div>
                        </div>
                    </div>
                    <UserDetailsForm
                        disabled={false}
                        initialValues={this.state.userInfo}
                        onChange={this.userInfoChanged}
                        updateUserDatabase={this.updateUserDatabase}
                    />
                </div>
            );

        if (this.state.userRoles && this.state.allRoles)
            roleDataComponent = (<EditUserRole adminRoleList={this.state.allRoles} userRoleList={this.state.userRoles} onChange={this.userRolesChanged} showSubmit={false} />);

        if (this.state.allPermissions && this.state.userPermissions) {
            permissionListComponent = (<EditUserPermission adminPermissionList={this.state.allPermissions} userPermissionList={this.state.userPermissions} onChange={this.userPermissionChanged} />);
        }


        return (
            <div>
                <TopNavBar loginstate={this.props.loginstate} heading='Edit User Information' />
                <div className="user-role-container">
                    <div>
                        {userDetailsEditComponent}
                    </div>
                    <div>
                        {roleDataComponent}
                    </div>
                    <div>
                        {permissionListComponent}
                    </div>
                    {this.state.errorMessage && <Alert bsStyle="danger"><p>{this.state.errorMessage}</p></Alert>}
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={false}
                        type="button"
                        isLoading={this.state.isLoading}
                        text="Save!"
                        loadingText="Saving…"
                        onClick={this.submitConfirmation}
                    />
                    {this.state.submitConfirmation && <ConfirmationPopUp heading='Confirmation' message='Confirm to save chnages.' confirmAction={this.handleSubmit} cancelAction={this.cancelConfirmation} />}
                </div>
            </div>
        );
    }
}

export default withRouter(EditUserInfoPage);
