import React from "react";
import { Route, Redirect } from "react-router-dom";

// Inspired by
// https://github.com/AnomalyInnovations/serverless-stack-demo-client
// (LICENSE: MIT)
// and
// https://tylermcginnis.com/react-router-protected-routes-authentication/

// TODO: Perhaps make the authentication global so we don't have to
// pass it explicitly (or even better, use redux so the application
// state becomes global and managed)

export default ({ component: C, loginstate, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={ props => {
          if (!loginstate.isAuthenticated)
	      return <Redirect to={`/login?redirect=${location.pathname}${location.search}`} />;

	  const params = props.match ? props.match.params : null;
              return <C loginstate={loginstate} {...rest}  {...params} />
      }}
    />);
}
