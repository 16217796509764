import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from "react-router-dom";

class ShowList extends Component {

    render() {
        if (this.props.url.length > 0) {
            let link = "/" + this.props.pagetorefer + "/" + this.props.url;
            return (
                <tr>
                    <td className="links-text" ><Link to={link}>{this.props.resource}</Link></td>
                    <td>{this.props.permissionCount}</td>
                </tr>
            );
        }
        else {
            return (
                <tr>
                    <td className="links-text" >{this.props.resource}</td>
                    <td>{this.props.permissionCount}</td>
                </tr>
            );
        }
    }
}


export default class RoleList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0
        };
    }


    goToStartButtonClick = () => {
        this.setState({
            startIndex: 0
        });
    }

    prevButtonClick = () => {
        this.setState(function (prevState, props) {
            if (prevState.startIndex < 1)
                return;

            return {
                startIndex: +prevState.startIndex - +this.props.elementsShownCount
            };
        });
    }

    nextButtonClick = () => {
        let temp = (Object.keys(this.props.showListElements).length / this.props.elementsShownCount) - 1;
        let tempstartIndex = this.props.elementsShownCount * temp;
        this.setState(function (prevState, props) {
            if (prevState.startIndex >= tempstartIndex)
                return;

            return {
                startIndex: +prevState.startIndex + +this.props.elementsShownCount
            };
        });
    }

    goToEndButtonClick = () => {
        let temp = (Object.keys(this.props.showListElements).length / this.props.elementsShownCount) - 1;
        let tempstartIndex = this.props.elementsShownCount * temp;
        let index = 0;
        for (; index < tempstartIndex; index += +this.props.elementsShownCount);
        this.setState({
            startIndex: index
        });
    }

    render() {
        let arrowButtons = null;
        let listShown = this.props.showListElements;
        let tempList = {};
        if (Object.keys(listShown).length > this.props.elementsShownCount) {
            arrowButtons = (
                <div className="btn-group btn-group-sm">
                    <button type="button" className="btn btn-primary" onClick={this.goToStartButtonClick}>&#8249;&#8249;</button>
                    <button type="button" className="btn btn-primary" onClick={this.prevButtonClick}>&#8249;</button>
                    <button type="button" className="btn btn-primary" onClick={this.nextButtonClick}>&#8250;</button>
                    <button type="button" className="btn btn-primary" onClick={this.goToEndButtonClick}>&#8250;&#8250;</button>
                </div>);
            let i = 0;
            let newi = 0;

            for (var key in listShown) {
                if (i >= this.state.startIndex && i < (+this.props.elementsShownCount + +this.state.startIndex)) {
                    tempList[newi] = listShown[key];
                    newi++;
                }
                i++;
            }
            listShown = tempList;
        }

        let pageNo = Math.ceil(this.state.startIndex / this.props.elementsShownCount) + 1;
        let totalPages = Math.ceil(Object.keys(this.props.showListElements).length / this.props.elementsShownCount);
        return (
            <div>
                <div className="text-center">
                    <h3>{this.props.tableTitle} ({pageNo}/{totalPages})</h3>
                <table className="table table-striped table-bordered table-hover" >
                                    <tbody>
                                        <tr>
                                            <td>Role Name</td>
                                            <td> No of Permissions </td>
                                        </tr>
                                        {$.map(listShown, (showListElement) => { return <ShowList key={showListElement.resource} {...showListElement} pagetorefer={this.props.pagetorefer} /> })}
                                    </tbody>
                </table>
                    </div>
                            {arrowButtons}
                        </div>
        );
    }
}

RoleList.defaultProps = {
    tableTitle: 'Table title',
    optionTitle1: 'Element',
    elementsShownCount: 10,
    pagetorefer: "",
    showListElement: {}
};
