import React, { Component } from 'react';

import SettingList from '../settinglist/settinglist'

export default class UpdateUserPermission extends Component {

    render() {
        let settingList = this.props.userPermissionList.map(
	    item => ({ resource: item.resource,
		       option1: item.from_role,
		       option2: item.read,
		       option3: item.write,
		       option2Enabled: true,
		       option3Enabled: true }));

	// Add the permissions that was not in userpermissions
	this.props.adminPermissionList.forEach( (item, index)  => {
	    let found = false;
	    settingList.forEach( (s_item, s_index) => {
		if (s_item.resource === item.resource)
		    found = true;
	    });

	    if (!found)
		settingList.push( { resource: item.resource,
				    option1: false,
				    option2: false,
				    option3: false,
				    option2Enabled: true,
				    option3Enabled: true });
	});

        return (
          <div className="user-role-container">
            <div>
              <div style={{ "textAlign": "flex-end" }}></div>
		<SettingList tableTitle='User Permissions'
	                     objectTitle='Permissions'
	                     optionTitle1='From Roles'
	                     optionTitle2='Read'
	                     optionTitle3='Modify'
	                     option2Enabled={true}
	                     option3Enabled={true}
	                     showListElements={settingList}
	                     onChange={this.props.onChange} />
            </div>
          </div>
        );
}
}
