import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import InformationCard from '../information_card/information_card'
import InformationList from '../information_list/information_list'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    InformationBox: {
        paddingTop: 5
    }
});

class InformationModule extends Component {
    state = {
        newSoftware: false,
        newHardware: false,
        newVessel: false,
        newCustomer: false,
    };

    addNewSoftware = () => {
        this.setState({ newSoftware: true });
    }

    closeNewSoftware = () => {
        this.setState({ newSoftware: false });
    }

    addNewHardware = () => {
        this.setState({ newHardware: true });
    }

    closeNewHardware = () => {
        this.setState({ newHardware: false });
    }

    addNewVessel = () => {
        this.setState({ newVessel: true });
    }

    closeNewVessel = () => {
        this.setState({ newVessel: false });
    }

    addNewCustomer = () => {
        this.setState({ newCustomer: true });
    }

    closeNewCustomer = () => {
        this.setState({ newCustomer: false });
    }
    
    render() {
        let list = this.props.list;
        let multipleHardware = list && list.hardwares && list.hardwares.length > 0;

        return (
            <div className={this.props.classes.InformationBox}>
                {this.state.newSoftware &&
                    <Dialog
                        open={this.state.newSoftware}
                        onClose={this.closeNewSoftware}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                        <InformationCard type='Software' relatedList={list} listType={list.type} newItem={true} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={this.closeNewSoftware} copiedList={this.props.copiedList} copyDetails={this.props.copyDetails}/>
                        </DialogContent>
                    </Dialog>
                }

                {this.state.newHardware &&
                    <Dialog
                        open={this.state.newHardware}
                        onClose={this.closeNewHardware}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                        <InformationCard type='Hardware' relatedList={list} listType={list.type} newItem={true} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={this.closeNewHardware} copiedList={this.props.copiedList} copyDetails={this.props.copyDetails}/>
                        </DialogContent>
                    </Dialog>
                }

                {this.state.newVessel &&
                    <Dialog
                        open={this.state.newVessel}
                        onClose={this.closeNewVessel}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                        <InformationCard type='Vessel' relatedList={list} listType={list.type} newItem={true} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={this.closeNewVessel} copiedList={this.props.copiedList} copyDetails={this.props.copyDetails}/>
                        </DialogContent>
                    </Dialog>
                }

                {this.state.newCustomer &&
                    <Dialog
                        open={this.state.newCustomer}
                        onClose={this.closeNewCustomer}
                        aria-labelledby="form-dialog-title"
                        maxWidth='xs, sm, md, lg, xl'
                        style={{
                            width: '58em',
                            position: 'fixed',
                            top: '-20%',
                            left: '20%'
                        }}
                    >
                        <DialogContent>
                        <InformationCard type='Customer' relatedList={list} listType={list.type} newItem={true} popUp={true} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} closeNewItem={this.closeNewCustomer} copiedList={this.props.copiedList} copyDetails={this.props.copyDetails}/>
                        </DialogContent>
                    </Dialog>
                }

                <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        {list.type === 'Vessel' && <InformationCard type='Vessel' list={list} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewVessel} />}
                        {list.type !== 'Vessel' && list.vessels && list.vessels.length > 1 && <InformationList type='Vessel' list={list.vessels} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewVessel} />}
                        {list.type !== 'Vessel' && list.vessels && list.vessels.length === 1 && <InformationCard type='Vessel' list={list.vessels[0]} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewVessel} />}
                        {list.type !== 'Vessel' && (!list.vessels || list.vessels.length === 0) && <InformationCard type='Vessel' list={null} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewVessel} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {list.type === 'Customer' && <InformationCard type='Customer' list={list} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewCustomer} />}
                        {list.type !== 'Customer' && list.customers && list.customers.length > 1 && <InformationList type='Customer' list={list.customers} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewCustomer} />}
                        {list.type !== 'Customer' && list.customers && list.customers.length === 1 && <InformationCard type='Customer' list={list.customers[0]} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewCustomer} />}
                        {list.type !== 'Customer' && (!list.customers || list.customers.length === 0) && <InformationCard type='Customer' list={null} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewCustomer} />}
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        {list.type === 'Hardware' && <InformationCard type='Hardware' list={list} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewHardware} />}
                        {list.type !== 'Hardware' && list.hardwares && list.hardwares.length > 1 && <InformationList type='Hardware' list={list.hardwares} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewHardware} />}
                        {list.type !== 'Hardware' && list.hardwares && list.hardwares.length === 1 && <InformationCard type='Hardware' list={list.hardwares[0]} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewHardware} />}
                        {list.type !== 'Hardware' && (!list.hardwares || list.hardwares.length === 0) && <InformationCard type='Hardware' list={null} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewHardware} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {list.type === 'Software' && <InformationCard type='Software' list={list} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} multipleHardware={multipleHardware} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewSoftware} />}
                        {list.type !== 'Software' && list.softwares && list.softwares.length > 1 && <InformationList type='Software' list={list.softwares} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} multipleHardware={multipleHardware} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewSoftware} />}
                        {list.type !== 'Software' && list.softwares && list.softwares.length === 1 && <InformationCard type='Software' list={list.softwares[0]} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} multipleHardware={multipleHardware} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewSoftware} />}
                        {list.type !== 'Software' && (!list.softwares || list.softwares.length === 0) && <InformationCard type='Software' list={null} listType={list.type} loginstate={this.props.loginstate} fetchItineraryList={this.props.fetchItineraryList} multipleHardware={multipleHardware} onSelection={this.props.onSelection} copyDetails={this.props.copyDetails} addNewItem={this.addNewSoftware} />}
                    </Grid>
                </Grid>

            </div>);
    }
}

export default withStyles(styles, { withTheme: true })(InformationModule);
