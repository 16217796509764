import React, { Component  } from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import $ from 'jquery';
import UserDetailsForm from '../../components/userdetailsform/userdetailsform.js';
import RoleList from '../../components/rolelist/rolelist'
import SettingList from '../../components/settinglist/settinglist'
import editImage from './edit.png';
import AnonymousAvatar from '../../components/anonymous-avatar/anonymous-avatar'
import TopNavBar from '../../components/top_nav_bar/top_nav_bar'

import './userinfopage.css';

class UserInfoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
            token: this.props.loginstate.token,
            userInfoData: null,
            userPermissionsList: null,
            userRoleList: null
        };
    }

    componentDidMount() {
        this.fetchUserInfo();
	this.fetchRoleList();
	this.fetchPermissionList();
    }

    fetchUserInfo = () => {
	$.ajax({
            type: "POST",
	    url: `${process.env.REACT_APP_BACKEND_URL}/userinfo`,
            data: {
		token: this.props.loginstate.token,
		username: this.props.userName,
		organization: 'telko',
		location: 'telko'
            },
            crossDomain: true,
    }).done((response) => {
        response['password'] = '';
        response['repassword'] = '';
        response['pin'] = '';
        response['repin'] = '';
        this.setState({ userInfoData: response });
    }).fail( (jqXHR, textStatus, errorThrown) => {
	    if (!jqXHR.status && !jqXHR.readyState) {
		this.setState({ errorMessage : "Couldn't get user info. Server unreachable"});
	    }
	    else if (jqXHR.status === 401) {
		this.setState({ errorMessage : "Couldn't get user info. Not autheticated." });
	    }
	    else {
		this.setState({ errorMessage: "Couldn't get user info. Unknown failure occured"});
	    }
        });
    }

    fetchRoleList  = () => {
	$.ajax({
            type: "POST",
	    url: `${process.env.REACT_APP_BACKEND_URL}/userrolelist`,
            data: {
		token: this.props.loginstate.token,
		username: this.props.userName,
		organization: 'telko',
		location: 'telko'
            },
            crossDomain: true
	}).done( (response) => {
	    this.setState({userRoleList : response});
	}).fail( (jqXHR, textStatus, errorThrown) => {

	    if (!jqXHR.status && !jqXHR.readyState) {
		this.setState({ errorMessage : "Couldn't get role list. Server unreachable"});
	    }
	    else if (jqXHR.status === 401) {
		this.setState({ errorMessage : "Couldn't get role list. Incorrect username or password" });
	    }
	    else {
		this.setState({ errorMessage: "Couldn't get role list. Unknown failure occured"});
	    }

	});
    }

    fetchPermissionList() {
	$.ajax({
            type: "POST",
	    url: `${process.env.REACT_APP_BACKEND_URL}/userpermissionlist`,
            data: {
		token: this.props.loginstate.token,
		username: this.props.userName,
		allavailable: false,
		organization: 'telko',
		location: 'telko'
            },
            crossDomain: true
	}).done( (response) => {
	    this.setState({ userPermissionsList: response});
	}).fail( (jqXHR, textStatus, errorThrown) => {

	    if (!jqXHR.status && !jqXHR.readyState) {
		this.setState({ errorMessage : "Couldn't get permission list. Server unreachable"});
	    }
	    else if (jqXHR.status === 401) {
		this.setState({ errorMessage : "Couldn't get permission list. Incorrect username or password" });
	    }
	    else {
		this.setState({ errorMessage: "Couldn't get permission list. Unknown failure occured"});
	    }

        });
    }

    render() {
        let userDataInfoComponent;
        if (this.state.userInfoData) {
            userDataInfoComponent = (
                <div>
                    <div className='text-center col-xs-12'>
                        <AnonymousAvatar color={this.state.userInfoData.user_color} />
                        
                    </div>
                    <UserDetailsForm disabled={true} showSubmitButton={false} initialValues={this.state.userInfoData} />
                </div>
            );
        }
        else {
            userDataInfoComponent = (
                <div className="alert alert-warning">
                    <strong>No Information!</strong> User information not available.
                </div>);
        }

        let roleDataListComponent = null;
        if (this.state.userRoleList) {
            roleDataListComponent = (<RoleList showListElements={this.state.userRoleList} elementsShownCount="3" tableTitle="Roles" pagetorefer="roleinfo" />);
        }
        else {
            roleDataListComponent = (
                <div className="alert alert-warning">
                    <strong>No Roles!</strong> User does not have any roles.
                </div>);
        }

        let permissionListComponent = null;
        if (this.state.userPermissionsList) {
	    const options = this.state.userPermissionsList.map( item => ({ resource : item.resource,
									  option1 : item.from_role,
									  option2 : item.read,
									  option3 : item.write }));
            permissionListComponent = (<SettingList tableTitle='User Permissions'
				                    objectTitle='Permissions'
				                    optionTitle1='From Rules'
				                    optionTitle2='Read'
				                    optionTitle3='Modify'
				                    option2Enabled={false}
				                    option3Enabled={false}
				                    showListElements={options} />);
        }
        else {
            permissionListComponent = (
                <div className="alert alert-warning">
                    <strong>No Permissions!</strong> User does not have any permissions.
                </div>);
        }

        let editPageURI = "/edituser/" + this.props.userName;
        return (
            <div>
                <TopNavBar loginstate={this.props.loginstate} heading='User Information' />
                <div className="user-role-container">
                <div>
                    <div style={{ "textAlign": "flex-end" }}><Link to={editPageURI}><input type="image" src={editImage} width="20" height="20" alt="Edit" /></Link></div>
                    
                    {userDataInfoComponent}
                </div>
                <div>
                    {roleDataListComponent}
                </div>
                <div>
                    {permissionListComponent}
                </div>
            </div>
                </div>
        );
    }
}
export default withRouter(UserInfoPage);